

import {
  Grid,
  Typography,
  Box,
} from "@mui/material";

export const AboutInfo = ({ img, children, paddingTop }) => {
  return (
    <Grid item sx={{ paddingRight: "20px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          paddingTop: paddingTop,
        }}
      >
        <img src={img} alt="ruler" />
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Typography
            color={"#164A1A"}
            sx={{
              color: "#164A1A",
              fontFamily: "MADE TOMMY",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "19.84px",
              letterSpacing: "0px",
              textAlign: "left",
            }}
          >
            &nbsp;&nbsp;{children}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};