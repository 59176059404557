import * as React from "react";
import { MapContainer, GeoJSON, useMap } from "react-leaflet";
import List from "@mui/material/List";
import { Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { getSiteById } from "api";
import { useState } from "react";
import L from "leaflet";

const ProjectSiteGeom = ({ geom }) => {

  const map = useMap();
  const bounds = L.geoJSON(geom).getBounds();
  map.fitBounds(bounds);

  return (
    <GeoJSON 
      data={geom} 
      style={{ 
        fillColor: 'transparent',
        color: '#D2FFAB',
        weight: 1,
      }} />
  )
}


const ProjectSiteItem = ({ siteId }) => {

  const theme = useTheme();
  const token = localStorage.getItem("access_token");
  const [siteData, setSiteData] = useState(null);
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const { logout } = useAuth0();

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    Cookies.remove("access_token");
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const fetchSiteData = () => {
    getSiteById(siteId, token) // Use the projectId obtained from the URL
      .then((response) => {
        setSiteData(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        if (error.response && error.response.status === 401) {
          handleLogout();
        }
      });
  };

  useEffect(() => {
    fetchSiteData();
  }, []);

  if(!siteData) {
    return null
  }

  return (
    <>
    {siteData && <ListItem
      alignItems="flex-start"
      sx={{
        "&.MuiListItem-root": {
          padding: 0,
          borderRadius: "4px",
          margin: isMedium ? '4px' : '',
          marginBottom: "8px",
          background: "#FCF9F3",
          boxShadow: "2px 12px 32px 2px rgba(29, 29, 27, 0.05)",
          width: isMedium ? "352px" : '100%'
        },
      }}
    >
      <ListItemAvatar sx={{ marginTop: "0px !important" }}>
        <div
          style={{
            width: "80px",
            height: "80px",
            overflow: "hidden",
            backgroundColor: "#164A1A",
            display: "flex",
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
            borderTopRightRadius: "0",
            borderBottomRightRadius: "0",
            justifyContent: "center",
          }}
        >
          <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <MapContainer
              center={[51.512, -0.075]}
              zoom={11}
              zoomControl={false}
              attributionControl={false}
              doubleClickZoom={false}
              scrollWheelZoom={false}
              dragging={false}
              style={{
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "#164A1A",
              }}
            >
              <ProjectSiteGeom geom={siteData.geometry} />
            </MapContainer>
          </div>
        </div>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            sx={{
              marginTop: ".5rem",
              fontSize: "18px",
              fontWeight: 500,
              color: "#2B2B2B",
            }}
            marginBottom={1}
          >
            {siteData.name}
          </Typography>
        }
        sx={{ marginLeft: "1rem" }}
        disableTypography={true}
        secondary={
          <React.Fragment>
            <Typography
              sx={{
                display: "inline",
                marginTop: "2rem",
                fontSize: "14px",
                fontWeight: 400,
                color: "#626364",
              }}
            >
              {siteData.site_area_formated_str} &nbsp;&nbsp;&nbsp;&nbsp;{/*Country, Region */}
            </Typography>
          </React.Fragment>
        }
      />
    </ListItem>}
    </>
  );
};

export default function ProjectSiteList({ sites }) {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Grid container display="flex" flexDirection="row">
      <List sx={{ 
        width: "100%", 
        maxWidth: isMedium ? 'unset' : 360, 
        bgcolor: "transparent",
        display: isMedium ? 'flex' : 'block',
        flexWrap: isMedium ? 'wrap' : ''
      }}>
        {sites.map((site, idx) => (
          <ProjectSiteItem key={idx} siteId={site} />
        ))}
      </List>
    </Grid>
  );
}
