import axios from 'axios';

const baseURL = process.env.REACT_APP_BACKEND_URL;
const createApiRequest = (url, method, headers, data = null) => {
  return axios({
    method,
    url: baseURL + url,
    headers,
    data,
  });
};

export const getAuthUser = async (token) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Accept': 'application/json',
  };
  return createApiRequest('auth/user', 'GET', headers);
};

export const getProjectData = async (token) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Accept': 'application/json',
  };
  return createApiRequest('ui/catalog/projects', 'GET', headers);
};

export const getProjectById = async (projectId, token) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Accept': 'application/json',
  };

  const url = `ui/catalog/projects/${projectId}?view=details`;
  return createApiRequest(url, 'GET', headers);
};


export const getSiteById = async (projectId, token) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Accept': 'application/json',
  };

  const url = `api/project/${projectId}`;
  return createApiRequest(url, 'GET', headers);
};


export const getProjectDataByView = async (token, view, searchQuery='*%3A*') => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Accept': 'application/json',
  };
  return createApiRequest(`ui/catalog/projects?view=${view}&q=${searchQuery}`, 'GET', headers);
};

export const getProjectByIdView = async (projectId, token, view) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Accept': 'application/json',
  };


  const url = `ui/catalog/projects/${projectId}?view=${view}`;
  return createApiRequest(url, 'GET', headers);
};

export const getTabsById = async (projectId, token) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Accept': 'application/json',
  };

  const url = `ui/project/tabs/${projectId}`;
  return createApiRequest(url, 'GET', headers);
};

export const getMediaResource = async (identifier, token) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Accept': 'application/json',
  };
  const url = `ui/resources/access/${identifier}`;
  return createApiRequest(url, 'GET', headers);
};


export const searchProjects = async (query, token) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Accept': 'application/json',
  };

  const url = `ui/catalog/search/attributes?q=name:${query}`;
  return createApiRequest(url, 'GET', headers);
};


export const tMapsProject = async (projectId, token) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Accept': 'application/json',
  };

  const url = `ui/project/maps/${projectId}`;
  return createApiRequest(url, 'GET', headers);
};


export const fetchSensorLocations = async (projectId, token) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Accept': 'application/json',
  };

  const url = `ui/project/sensors/${projectId}`;
  return createApiRequest(url, 'GET', headers);
};

export const fetchGBIFRefArea = async (projectId, token) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Accept': 'application/json',
  };

  const url = `ui/project/gbif_areas/geojson/${projectId}`;
  return createApiRequest(url, 'GET', headers);
};

export const getGlossaryData = async (token) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Accept': 'application/json',
  };
  return createApiRequest('api/glossary/', 'GET', headers);
};

export const getCountriesData = async (token) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Accept': 'application/json',
  };
  return createApiRequest('ui/catalog/countries', 'GET', headers);
};

export const getClientsData = async (token) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Accept': 'application/json',
  };
  return createApiRequest('ui/catalog/clients', 'GET', headers);
};

export const getProductsData = async (token) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Accept': 'application/json',
  };
  return createApiRequest('ui/catalog/products', 'GET', headers);
};

export const FilterData = async (product, client, country, view, token) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Accept': 'application/json',
  };
  const countryUrl =  country === '' ? '' : `&q=metadata.countries%3A${encodeURIComponent(country)}`;
  const clientUrl = client === '' ? '' :  `&q=metadata.client%3A${encodeURIComponent(client)}`;
  const productUrl = product === ''  ? '':  `&q=metadata.be_product%3A${encodeURIComponent(product)}`;
  const url = `ui/catalog/projects?view=${view}${countryUrl}${clientUrl}${productUrl}`;
return createApiRequest(url, 'GET', headers);
};

export default createApiRequest;
