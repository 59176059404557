// loginReducer.js

import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE, UPDATE_PROJECT_DATA, UPDATE_USER_DATA } from './actions';

const initialState = {
  loading: false,
  isLoggedIn: false,
  userData: null,
  projectData: null, // Add projectData to store the fetched project data
  error: '',
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: ''
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
        error: ''
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        error: action.payload
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedOut: true,
        userData: action.payload,
        error: ''
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedOut: false,
        error: action.payload
      };
    case UPDATE_USER_DATA:
      return {
        ...state,
        userData: action.payload, // Update user data in the state
      };
    case UPDATE_PROJECT_DATA:
      return {
        ...state,
        projectData: action.payload, // Update project data in the state
      };
    default:
      return state;
  }
};

export default loginReducer;
