import React, { useState, useRef, useEffect } from 'react';
import { Typography, CardMedia, CardContent, Card } from '@mui/material/'
import teaserImageDefault from 'assets/images/defaultImage.png'
import ButtonBookMark from 'assets/images/icons/Button-Bookmark.svg';
import ButtonBookMarkActive from 'assets/images/icons/Button-Bookmark-Active.svg';
import ButtonBookMarkHovered from 'assets/images/icons/Button-Bookmark-Hovered.svg';


function MapProjectCard({
  selectedProject,
  active,
  onCardClick,
  onCardHover,
  onCardLeave,
  index,
  teaserImage,
  projectData,
  isPopoverOpen,
}) {

  // Sample bookmarked data array retrieved from local storage
  const [bookmarkedData, setBookmarkedData] = useState(JSON.parse(localStorage.getItem('bookmarks')) || []);
  const [bookmarkIconHover, setStatusBookmarkHover] = useState(false);
  const cardRef = useRef();
  const {tags = [], client = '', countries = '', name = '', identifier = ''} = projectData || null;

  const handleCardClick = () => {
    onCardClick(identifier);
  };

  const handleCardHover = (event, identifier) => {
    if(!isPopoverOpen) {
      onCardHover(identifier);
    }
  };

  const handleCardOnLeave = (event, identifier) => {
    if(!isPopoverOpen) {
      onCardLeave(identifier);
    }
  };
 
  const handleBookmarkHover = () => {
    setStatusBookmarkHover(true);
  };

  const handleBookMarkOnLeave = () => {
    setStatusBookmarkHover(false);
  };

  // Function to check if a project is bookmarked
  const isBookmarked = (project) => {
    return bookmarkedData.some(item => item.identifier === project.identifier);
  };

  // Function to handle bookmarking/unbookmarking
  const handleBookmark = () => {
    const updatedBookmarks = isBookmarked(projectData)
      ? bookmarkedData.filter(item => JSON.stringify(item) !== JSON.stringify(projectData))
      : [...bookmarkedData, projectData];
    setBookmarkedData(updatedBookmarks);
    localStorage.setItem('bookmarks', JSON.stringify(updatedBookmarks));
    //updateDataAndNotifyParent();
  };

  useEffect(() => {
    if (selectedProject) {
      // scroll to selected project card/row
      const cardElement = document.getElementById(selectedProject.identifier);
      const mainContainer = document.getElementById('main-container');
      mainContainer.style.height = 'auto'
      cardElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

      setTimeout(() => {
        mainContainer.style.height = '100vh'
      },100);
    }
  }, [selectedProject]);

  return (
    <div id={identifier} ref={cardRef}>
      <Card
        onMouseEnter={handleCardHover}
        onMouseLeave={handleCardOnLeave}
        aria-owns={isPopoverOpen ? `${index || identifier}-project-mouse-over-popover` : undefined}
        aria-haspopup="true"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '324px',
          width: '100%',
          border: active ? '1px solid #164A1A' : '',
          backgroundColor: active?  '#FCF9F3' : '#F9F5EB',
          margin: '1rem 0rem',
          borderRadius: 2,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#FCF9F3',
          },
        }}
        onClick={handleCardClick}
      >
        <div style={{ position: 'relative' }}>
          <CardMedia
            sx={{
              height: 120,
              backgroundColor: '#919397',
              '&:after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                transition: 'background-color 0.3s',
              },
            }}
            image={teaserImage ? teaserImage : teaserImageDefault}
            title="Project Image"
            id="search-card" />
            <div style={{
                position: 'absolute',
                top: '8px',
                right: '8px',
                padding: '2px',
                cursor: 'pointer'
              }} 
              onMouseEnter={handleBookmarkHover}
              onMouseLeave={handleBookMarkOnLeave}
              onClick={handleBookmark}>
                <img src={bookmarkIconHover ?
                      ButtonBookMarkHovered :
                      (isBookmarked(projectData) ?  ButtonBookMarkActive : ButtonBookMark)} alt="plus-icon" />
            </div>
          </div>
        <CardContent
          sx={{
            display: 'block',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexGrow: 1,
            height: '100%',
            padding: "10px",
            '&:last-child': {
              paddingBottom: '20px',
              paddingTop: '20px'
            },
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography  variant="h5" component="div">
              {name}
            </Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <Typography gutterBottom variant="h5" color={'#626364'} sx={{fontSize: '14px', fontWeight: 400, marginRight: '15px'}} component="div">
            {countries.length === 1
                    ? ` ${countries[0]}`
                    : countries.map((country, index) => (
                        <span key={index}>
                          {index === 0 ? country : `, ${country}`}
                        </span>
                      ))}
            </Typography>
            <Typography gutterBottom variant="h5" color={'#626364'} sx={{fontSize: '14px', fontWeight: 400}} component="div">
              <span >{client} </span>
            </Typography>
          </div>
          <span style={{ display: 'flex', flexWrap: "wrap", gap: '8px', marginTop: '4px' }}>
            {tags.map((tag, index) => (
              <Typography key={index}
                style={{
                  border: '1.5px solid #1A5C1F',
                  borderRadius: '5px',
                  padding: '6px',
                  gap: '10px',
                  color: '#1A5C1F',
                  fontFamily: "Source Sans 3",
                  fontSize: "13px",
                  fontWeight: 400,
                  lineHeight: "17px",
                  letterSpacing: 0,

                }}>
                {tag}
              </Typography>
            ))}
          </span>
        </CardContent>
      </Card>
    </div>
  );
}

export default MapProjectCard;