import PropTypes from 'prop-types';
import Navigation from 'component/Navigation';
const menuItems = [
  { label: 'About', link:' https://www.biometrio.earth/unser-ansatz' },
  { label: 'Technology' , link:' https://www.biometrio.earth/technologie'},
  { label: 'Glossary', link:'/pages/glossary' },
  { label: 'Help', link:'/pages/help' },
];
const menuItemsMobile = [
  { label: 'Map', link:'/entry/map' },
  { label: 'About', link:' https://www.biometrio.earth/unser-ansatz' },
  { label: 'Technology' , link:' https://www.biometrio.earth/technologie'},
  { label: 'Glossary', link:'/pages/glossary' },
  { label: 'Help', link:'/pages/help' },
];
const Header = ({ triggerTour }) => {
  return (
    <Navigation triggerTour={triggerTour} menuItems={menuItems} menuItemsMobile={menuItemsMobile}/>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
