import React, { useState } from 'react';
import {
  Grid,
  Select,
  FormControl,
  OutlinedInput,
  Typography,
  MenuItem
} from "@mui/material";

import Chart from 'react-apexcharts';
// import "./charts/styles/index.css";

function containsIsoDate(str) {
  // Regular expression for matching ISO 8601 date format (e.g., 2023-07-10 or 2023-07-10T10:00:00Z)
  const isoDateRegex = /\b\d{4}-\d{2}-\d{2}(?:T\d{2}:\d{2}(?::\d{2}(?:\.\d{1,3})?)?(?:Z|[+-]\d{2}:\d{2})?)?\b/;
  return isoDateRegex.test(str);
}

function parseDatesArray(datesArray) {

  return datesArray.map(d => {
    if(containsIsoDate(d)) {
      const parsedDate = new Date(d);
      const year = parsedDate.getFullYear();
      return year.toString();
    } else {
      return d;
    }
  })

}

function isNumberOrFloatString(value) {
  if (typeof value === 'number' && !Number.isInteger(value)) {
      return true;
  }
  if (typeof value === 'string') {
      // Regular expression to match a floating-point number
      const floatRegex = /^[+-]?\d+(\.\d+)?$/;
      return floatRegex.test(value);
  }
  return false;
}

function formatFloat(item) {
  if (isNumberOrFloatString(item)) {
    return parseFloat(item).toFixed(2);
  }
  return item;
}

function formatSeriesData(seriesData) {

  return seriesData && seriesData.map(item => {
    return {
      ...item,
      data: item.data.map(item => {
        if (typeof item === 'number' && !Number.isInteger(item)) {
            return parseFloat(item.toFixed(2));
        }
        return item;
      })
    }
  });
}

function capitalizeString(str) {
  if (typeof str !== 'string' || str.length === 0) {
      return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

const ChartHeight = 480;

const ChartGeneralOptions = {
  dataLabels: {
    enabled: false
  },
  grid: {
    show: true,
    showForSingleSeries: true,
    borderColor: '#D1CEC5', // color of the gridlines
    strokeDashArray: 3, // length of dashes in pixels
    position: 'back', // place the gridlines behind the series
    xaxis: {
      lines: {
        show: false // show/hide gridlines for x-axis
      }
    },
    yaxis: {
      lines: {
        show: true // show/hide gridlines for y-axis
      }
    }
  },
}

const AxisLabels = {
  labels: {
    style: {
      color: "#626364",
      fontFamily: '"MADE TOMMY", sans-serif',
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "15px",
      letterSpacing: "0px",
      textAlign: "left"
    },
  },
}

const plotOptions = (type,label) => {

  if(type === 'bar')
  return {
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%'
      }
    }
  }

  if (type === 'donut')
  return {
    plotOptions: {
      pie: {
        donut: {
          size: '50%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '12px',
              fontFamily: '"MADE TOMMY", sans-serif',
              fontWeight: 400,
              color: '#626364',
              lineHeight: '15px',
              letterSpacing: '0px',
              textAlign: 'center',
            },
            value: {
              show: true,
              fontSize: '24px',
              fontFamily: '"MADE TOMMY", sans-serif',
              fontWeight: 500,
              color: "#626364",
              margin: "0 !important",
              lineheight: "32px",
              letterSpacing: "0px",
              textAlign: "center",
              formatter: function (val) {
                if(label.length > 3)
                  return `${formatFloat(val)}`;
                else
                  return `${formatFloat(val)} ${capitalizeString(label)}`;
              }
            },
          }
        }
      }
    }
  }
}

const CustomChart = ({ chartData }) => {

  const [currentChart, setCurrentChart] = useState(Array.isArray(chartData) ? chartData[0] : chartData);

  let chartType = currentChart.chart_type;

  const customLineChartColors = ["#164A1A","#D36BD7","#83DA38","#EF7709","#C00829","#0A869A","#919397","#062308"];

  const customPieChartColors = ["#0A869A", "#C00829", "#D36BD7", "#EF7709", "#83DA38","#164A1A","#062308", "#919397"];

  const ChartData = {
    series: chartType === 'donut' ? currentChart.series_data[0].data 
    : formatSeriesData(currentChart.series_data),
    type: chartType,
    height: ChartHeight,
    options: {
      ...ChartGeneralOptions,
      grid: {
        ...ChartGeneralOptions.grid,
        xaxis: {
          lines: {
            show: chartType === 'line'
          }
        },
        yaxis: {
          lines: {
            show: chartType !== 'radar'
          }
        },
      },
      fill: {
        colors: chartType === 'radar' || chartType === 'donut' ? customPieChartColors : currentChart.colors,
        ...chartType === 'radar' ? {opacity: 0.3} :{ }
      },
      stroke: {
        curve: "smooth",
        width: chartType === 'line' || chartType === 'radar' || chartType === 'donut' ? 2 : 0,
        colors: chartType === 'radar' ? customPieChartColors : 
          chartType === 'line' ? customLineChartColors : chartType === 'donut' ? ['#FFF'] : currentChart.colors
      },
      markers: {
        ...currentChart.legend.markers, 
        size: chartType === 'radar' ? 3 : [4,4], 
        colors: chartType === 'radar' || chartType === 'donut' ? customPieChartColors : 
          chartType === 'line' ? customLineChartColors : currentChart.colors
      },
      ...plotOptions(chartType,currentChart?.ylabel_text),
      colors: chartType === 'donut' || chartType === 'radar' ? customPieChartColors : 
        chartType === 'line' ? customLineChartColors : currentChart.colors,
      xaxis: {
        ...AxisLabels,
        type: currentChart.xlabel.category_type,
        categories: parseDatesArray(currentChart.xlabel.categories)
      },
      labels: currentChart.xlabel.categories,
      legend: chartType === 'donut' ? {
        position: 'left', 
        onItemClick: {
          toggleDataSeries: false 
        },
        formatter: function (seriesName, opts) {
          const colors = customPieChartColors; 
          const color = colors[opts.seriesIndex] || '#000'; // Use opts.seriesIndex to access the correct color
          return `${seriesName} <br /> <span style='color: ${color};font-family: MADE TOMMY,"sans-serif" !important;font-size: 18px;font-weight: 500;line-height: 32px;letter-spacing: 0;'>${formatFloat(currentChart.series_data[0].data[opts.seriesIndex])} ${capitalizeString(currentChart?.ylabel_text)}</span>`; // Display series name and value with corresponding color
        }
      } : {...currentChart.legend, showForSingleSeries: chartType !== 'radar', onItemClick:{
        toggleDataSeries: false
      }},
      yaxis: { 
        labels: {
          ...AxisLabels,
          formatter: function (value) {
            return `${formatFloat(value)} ${currentChart?.ylabel_text.includes('normalized') ? '' : capitalizeString(currentChart?.ylabel_text)}`;
          }
        }
      },
    }
  }

  return (
    <>
      <Grid item>
        {Array.isArray(chartData) && <Typography variant="h5" sx={{ color: "#2B2B2B"}}>{currentChart.title}</Typography>}
      </Grid>
      <Grid item xs={12} display="flex" flex-direction="row"  alignItems="flex-start">
        <Grid item xs={(Array.isArray(chartData) || chartData.text) ? 9 : 12}><Chart {...ChartData} /></Grid>
        {(Array.isArray(chartData) || chartData.text) && <Grid item xs={3} sx={{ margin: "0rem 2rem" }}>
          {Array.isArray(chartData) && <>
            <FormControl
              sx={{
                marginBottom: "40px",
                marginTop: "30px",
                width: "100%",
                background: "transparent !important",
              }}
            >
              <Select
                id="demo-simple-select-autowidth"
                displayEmpty
                value={currentChart}
                onChange={(event) => setCurrentChart(event.target.value)}
                autoWidth={false}
                input={<OutlinedInput />}
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  '.MuiInputBase-input': {
                    background: '#f9f5eb!important'
                  }
                }}
              >
                {chartData.map((chartObject, index) => (
                    <MenuItem
                      value={chartObject}
                      key={index}
                      onClick={() => setCurrentChart(chartObject)}
                    >
                      {chartObject?.title}
                    </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>}
          {chartData.text && <div style={{ fontFamily: '"MADE TOMMY", sans-serif', fontWeight: 400, color: '#626364', marginRight: '10rem', width: "100%" }}>
            {chartData.text}
          </div>}
        </Grid>}
      </Grid>
    </>
  );
};

export default CustomChart;

