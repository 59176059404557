/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Typography,
    Grid,
    Paper,
    IconButton,
    Button
} from "@mui/material";
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useAuth0 } from '@auth0/auth0-react';

import { styled } from '@mui/material/styles';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import SelectFilter from './selectFilter';
import { getCountriesData, getClientsData, getProductsData, FilterData } from 'api';
import { getViewFromPathname } from '../../layout/MainLayout'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'transparent',
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    marginBottom: '2rem'
}));


const Filters = ({ getAlertMessage, getFilteredData }) => {
    const { logout } = useAuth0();
    const location = useLocation();
    const [selectedItems, setSelectedItems] = useState([]);
    const [countries, setCountries] = useState([]);
    const [products, setProducts] = useState([]);
    const [clients, setClients] = useState([]);
    const [product, setProduct] = useState('');
    const [client, setClient] = useState('');
    const [country, setCountry] = useState('');
    const [loading, setLoading] = useState(false);
    const [showNoProject, setShowNoProject] = useState(null);
    const token = localStorage.getItem('access_token');


    const handleSelect = (item) => {

        setSelectedItems((prevSelected) =>
            prevSelected.includes(item)
                ? prevSelected.filter((i) => i !== item)
                : [...prevSelected, item]
        );
    };

    const isSelected = (item) => selectedItems.includes(item);



    const handleProductDataSelect = (event, value) => setProduct(value?.label || '');
    const handleClientsDataSelect = (event, value) => setClient(value?.label || '');
    const handleCountriesDataSelect = (event, value) => setCountry(value?.label || '');

    const handleLogout = useCallback(() => {
        localStorage.removeItem('access_token');
        Cookies.remove('access_token');
        logout({ logoutParams: { returnTo: window.location.origin } })
    }, [logout]);

    const fetchCountiresData = async () => {
        try {
            const response = await getCountriesData(token);
            const { data } = response;
            setCountries(data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
            }
        }
    };

    const fetchClientsData = async () => {
        try {
            const response = await getClientsData(token);
            const { data } = response;
            setClients(data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
            }
        }
    };

    const fetchProductsData = async () => {
        try {
            const response = await getProductsData(token);
            const { data } = response;
            setProducts(data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
            }
        }
    };
    const view = getViewFromPathname(location.pathname);
    //const view = 'details'
    const ApplyFilters = async () => {
        setLoading(true);
        try {
            const response = await FilterData(product, client, country, view, token);
            const { data } = response;
            setLoading(false);
            if (data?.msg === "No projects found at all") {
                setShowNoProject(data?.msg);
                setTimeout(function () {
                    setShowNoProject(null);
                }, 5000);
            } else {
                getAlertMessage("Filter applied", true);
                if(data?.data && data?.data?.length > 0){
                    getFilteredData(data?.data)
                    //localStorage.setItem('filters', JSON.stringify(data?.data));
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setLoading(false);
                handleLogout();
            }
        }
    };


    const clearFilters = () => {
        setProduct('');
        setClient('');
        setCountry('');
    };

    useEffect(() => {
        fetchClientsData();
        fetchCountiresData();
        fetchProductsData();
    }, []);

    return (
        <Box sx={{ width: '100%', marginTop: '2rem' }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 500, color: '#2B2B2B' }}>Filter</Typography>
            <Grid container rowSpacing={1} mt={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                {
                    showNoProject !== null && (
                        <Grid item xs={12} mb={3}>
                            <Typography style={{ color: '#164A1A', fontSize: '16px', fontWeight: 500 }} >
                                {showNoProject}
                            </Typography>
                        </Grid>
                    )
                }

                <Grid item xs={12}>
                    <Item>
                        <Typography sx={{ color: '#626364', fontSize: '14px', fontWeight: 400, marginBottom: '2rem' }}>Product Type</Typography>
                        <div>
                            <SelectFilter selectData={products} label={'Select Product Type'} value={product} onSelect={handleProductDataSelect} />
                        </div>
                    </Item>
                </Grid>

                <Grid item xs={12}>
                    <Item>
                        <Typography sx={{ color: '#626364', fontSize: '14px', fontWeight: 400, marginBottom: '2rem' }}>Countries</Typography>
                        <div>
                            <SelectFilter selectData={countries} label={'Select Country'} value={country} onSelect={handleCountriesDataSelect} />
                        </div>
                    </Item>
                </Grid>

                <Grid item  xs={12}>
                    <Item>
                        <Typography sx={{ color: '#626364', fontSize: '14px', fontWeight: 400, marginBottom: '2rem' }}>Clients</Typography>
                        <div>
                            <SelectFilter selectData={clients} label={'Select Client'} value={client} onSelect={handleClientsDataSelect} />
                        </div>
                    </Item>
                </Grid>


                <Grid item xs={6}>
                    <Item>
                        <Button
                            onClick={clearFilters}
                            sx={{
                                mx: "auto",
                                my: 2,
                                py: 2,
                                width: "152px",
                                borderRadius: "50px",
                                textTransform: "uppercase",
                                fontWeight: 300,
                                background: "#F9F5EB",
                                border: "1px solid #062308",
                                color: "#164A1A",
                                '&:hover': {
                                    background: "#F2EEE3",
                                    color: "#062308"
                                },
                            }}
                            variant="outlined"
                            color="primary"

                        >
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    lineHeight: "19px",
                                    color: "#164A1A",
                                    letterSpacing: "0.04em",
                                }}
                            >
                                Clear Filters
                            </Typography>
                        </Button>
                    </Item>
                </Grid>

                <Grid item  xs={6}>
                    <Item>
                        <Button
                            onClick={ApplyFilters}
                            sx={{
                                mx: "auto",
                                my: 2,
                                py: 2,
                                width: "152px",
                                borderRadius: "50px",
                                textTransform: "uppercase",
                                fontWeight: 300,
                                background: "#164A1A",
                                '&:hover': {
                                    background: "#062308",
                                },
                            }}
                            variant="contained"
                            color="primary"

                        >
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    lineHeight: "19px",
                                    color: "#F9F5EB",
                                    letterSpacing: "0.04em",
                                }}
                            >
                                {loading ? 'Applying...' : 'Apply Filters'}
                            </Typography>
                        </Button>
                    </Item>
                </Grid>


            </Grid>
        </Box>
    );
};

export default Filters;