import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import Cookies from 'js-cookie';
import { TourGuideSteps } from './Steps';

const JoyrideContext = createContext();

export const JoyrideProvider = ({ children }) => {
  const [stepReady, setStepReady] = useState(false);
  const [action, setAction] = useState('');
  const navigate = useNavigate();
  const location = useLocation(); // Get current route

  const [joyrideState, setJoyrideState] = useState({
    run: false,
    mapPinClicked: false,
    stepIndex: 0,
    steps: TourGuideSteps,
    currentAction: action,
    overlayCleared: false,
    manualStartTour: false,
  });

  // Handle route changes and keep overlay visible
  useEffect(() => {
    if (stepReady && joyrideState.run) {
      setJoyrideState(prevState => ({
        ...prevState,
        run: true,
        overlayCleared: false, // Reset overlay state on route change
      }));
    }
  }, [location.pathname, stepReady]); // Listen for route changes and step readiness

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    const currentStep = joyrideState.steps[index];
    const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
    const nextStep = joyrideState.steps[nextStepIndex];
    const navigationDelay = 400;  // Timeout constant for step transitions
  
    if (!stepReady) return;

    const completeTour = () => {
      Cookies.set("tour_completed", "true", { expires: 60, path: '/' });
      setJoyrideState(prevState => ({
        ...prevState,
        run: false,
        stepIndex: 0,
        overlayCleared: true,
        manualStartTour: false,
      }));
    };
  
    const waitForPageLoad = () => {
      return new Promise((resolve) => {
        const onLoadHandler = () => {
          window.removeEventListener('load', onLoadHandler); // Clean up listener
          resolve();
        };
  
        // In case the page is already loaded (no need to wait)
        if (document.readyState === 'complete') {
          resolve();
        } else {
          // Wait for the load event to ensure page has finished loading
          window.addEventListener('load', onLoadHandler);
        }
      });
    };
  
    // If tour is finished or skipped, mark it as completed
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || action === ACTIONS.SKIP) {
      completeTour();
      setAction(action);
      return;
    }
  
    // Handle step progression or target not found
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Check if it's the last step
      if (nextStepIndex >= joyrideState.steps.length) {
        completeTour();
        return;
      }
  
      const handleNavigation = (path) => {
        setJoyrideState(prevState => ({
          ...prevState,
          run: false,
          stepIndex: nextStepIndex,
          currentAction: action,
          manualStartTour: true,
        }));
  
        setTimeout(async () => {
          navigate(path);  // Perform the navigation
          await waitForPageLoad();  // Wait for the page to fully load
  
          // Restart the tour after the page has finished loading
          setJoyrideState(prevState => ({
            ...prevState,
            run: stepReady,
            stepIndex: nextStepIndex,
            currentAction: action,
            overlayCleared: false,
            manualStartTour: true,
          }));
        }, navigationDelay);
      };
  
      // Handle navigation based on step action
      if (nextStep?.path && action === ACTIONS.NEXT) {
        handleNavigation(nextStep.path);
      } else if (currentStep?.prevpath && action === ACTIONS.PREV) {
        handleNavigation(nextStep.prevpath);
      } else {
        // Just move to the next step without navigating
        setJoyrideState(prevState => ({
          ...prevState,
          stepIndex: nextStepIndex,
          currentAction: action,
        }));
      }
    }
  };

  const startTour = useCallback((steps) => {
    setJoyrideState(prevState => ({
      ...prevState,
      run: stepReady,
      stepIndex: 0,
      steps,
      overlayCleared: false, // Ensure the overlay isn't cleared when the tour starts
      status: 'ready',
    }));
  }, [stepReady]);

  const nextStep = useCallback(() => {
    setJoyrideState(prevState => {
      const nextIndex = prevState.stepIndex + 1;
      if (nextIndex < prevState.steps.length) {
        return {
          ...prevState,
          stepIndex: nextIndex,
        };
      } else {
        return {
          ...prevState,
          run: false,
          stepIndex: 0,
          overlayCleared: true, // Clear overlay at the end of the tour
        };
      }
    });
  }, []);

  const setStepReadyState = useCallback((isReady) => {
    setStepReady(isReady);
    if (isReady && !joyrideState.run) {
      setJoyrideState(prevState => ({
        ...prevState,
        run: true,
        overlayCleared: false, // Ensure the overlay is active when the step is ready
      }));
    }
  }, [joyrideState.run]);


  return (
    <JoyrideContext.Provider value={{ startTour, nextStep, joyrideState, setJoyrideState, setStepReadyState, stepReady }}>
      {joyrideState.manualStartTour && (
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          run={joyrideState.run}
          hideBackButton
          scrollToFirstStep
          showProgress
          showSkipButton
          stepIndex={joyrideState.stepIndex}
          steps={joyrideState.steps}
          disableScrolling
          disableOverlayClose
          disableScrollParentFix
          spotlightPadding={5}
          floaterProps={{
            disableAnimation: true,
            offset: 0,
            placement: 'auto',
          }}
          styles={{
            options: {
              zIndex: 10000,
              arrowColor: '#F9F5EB',
            },
            overlay: joyrideState.overlayCleared
              ? {
                  backgroundColor: 'transparent',
                }
              : {
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  height: '100em',
                  width: '100%',
                },
            tooltip: {
              backgroundColor: '#F9F5EB',
              color: '#545454',
              fontFamily: `'MADE TOMMY', sans-serif`,
              transition: 'none',
            },
            floater: {
              transition: 'none',
            },
            buttonClose: {
              color: '#164A1A',
            },
            buttonNext: {
              backgroundColor: '#164A1A',
              border: '2px solid #164A1A',
              color: '#F9F5EB',
            },
            buttonBack: {
              color: '#164A1A',
            },
            arrow: {
              backgroundColor: '#F9F5EB',
              color: '#545454',
            },
          }}
        />
      )}
      {children}
    </JoyrideContext.Provider>
  );
};

export const useJoyride = () => useContext(JoyrideContext);