// AuthenticationRoutes.js

import { lazy } from 'react';
import Loadable from 'utilities/Loadable';
import MinimalLayout from '../layout/MinimalLayout';
import InfoAnime from '../assets/nature-info.mp4';
import spilledmilk from 'assets/images/Spilled Milk-big.svg'

const Login = Loadable(lazy(() => import('views/pages/Login')));
const NotFound = Loadable(lazy(() => import('views/pages/PageNotFound')));
const InfoPage = Loadable(lazy(() => import('views/pages/InfoBox')));


const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      index: true,
      element: <Login />
    },
    {
      path: '/info',
      index: true,
      element: <InfoPage
      media={InfoAnime}
      altText="privilleges review"
      button={true}
      buttonText={'Logout'}
      logoutAction={true}
      text1={<>Welcome to our Nature Analytics Dashboard!</>}
      text2={
        <>
          Please be informed that your user privileges are currently under review,
          and access will be granted within one working day.
        </>
      }
      text3={
        <>
          If you encounter any issues or if your access is not updated after this
          period, please don’t hesitate to reach out to our support team at  <a href="mailto:contact@biometrio.earth" style={{ color: '#164A1A' }}> contact@biometrio.earth </a>
          . Thank you for your patience.
        </>
      }
      mediaType="video" // Specify that the media is a video
      textAlign="center"
    />
    },
    {
      path: '*',
      element: <InfoPage
      media={spilledmilk}
      altText="404"
      button={true}
      buttonText={'Go back'}
      logoutAction={false}
      text1={<>Oops!!!</>}
      mediaWidth='500px'
      text2={
        <>
         This page you are looking for could not be found.
        </>
      }
      mediaType="image" // Specify that the media is a video
      textAlign="center"
    />,
    },
  ]
};

export default AuthenticationRoutes;


