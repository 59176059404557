import React from 'react';
import {
  useMediaQuery
} from "@mui/material";
import { styled, alpha, useTheme } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import '../SidebarSearchMenu/index.css'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: '10px',
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    // marginLeft: theme.spacing(3),
    width: 'auto',
  },
  display:'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));


const StyledInputBase = styled(InputBase, { shouldForwardProp: (prop) => prop !== 'isMedium' })(({ theme, isMedium }) => ({
  color: '#626364 !important',
  fontFamily: "MADE TOMMY",
  fontWeight: 400,
  backgroundColor: '#F2EEE3 !important',
  '& .MuiInputBase-input': {
    paddingTop: '15px',
    minWidth: isMedium ? 'unset' : '290px',
    paddingBottom: '15px',
    paddingLeft: '20px',
    paddingRight: '20px',
    transition: theme.transitions.create('width'),
    width: '100%',
    fontWeight: 400,
    color: '#626364 !important',
    border: `1.5px solid #D1CEC5`,
    borderRadius: theme.shape.borderRadius,
  },
}));




function SearchBox({ searchTerm, handleSearchChange, handleLeftDrawerToggle, ...props }) {
  const theme = useTheme();
  // Media query for tablet devices
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Search {...props}>
        {/* <SearchIconWrapper>
          <StyledSearchIcon color="primary" size="small" style={{ verticalAlign: 'middle', fontSize: '12px' }} />
        </SearchIconWrapper> */}
        <StyledInputBase
          placeholder={searchTerm ? searchTerm : "Search…"}
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleSearchChange}
          value={searchTerm}
          isMedium={isMedium}
        />
        <span style={{ position: 'absolute', right: '1rem', top: '1.2rem'}}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.46941 10.511C8.56427 11.2091 7.42975 11.6244 6.19824 11.6244C3.23753 11.6244 0.837402 9.22426 0.837402 6.26355C0.837402 3.30284 3.23753 0.90271 6.19824 0.90271C9.15895 0.90271 11.5591 3.30284 11.5591 6.26355C11.5591 7.45148 11.1727 8.54917 10.5187 9.43779L15.1774 14.3294L14.0912 15.3639L9.46941 10.511ZM10.0591 6.26355C10.0591 8.39583 8.33052 10.1244 6.19824 10.1244C4.06596 10.1244 2.3374 8.39583 2.3374 6.26355C2.3374 4.13127 4.06596 2.40271 6.19824 2.40271C8.33052 2.40271 10.0591 4.13127 10.0591 6.26355Z" fill="#919397"/>
        </svg>

        </span>
      </Search>
    </div>
  );
}

export default SearchBox;
