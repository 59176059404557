// ThemeRoutes.js

import { useRoutes, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import { MainRoutes, MobileRoutes } from './MainRoutes';
import { useState, useEffect } from 'react';
import AuthenticationRoutes from './AuthenticationRoutes';
import ProjectDetailRoutes from './ProjectDetailRoutes';
import PageRoutes from './PageRoutes';
import AdminRoutes from './AdminRoutes';
import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'js-cookie'; // Import Cookies
import { jwtDecode } from 'jwt-decode';
import UsersAgreement from 'views/pages/UsersAgreement';
const audience = process.env.REACT_APP_AUTH0AUTHORIZE;

export default function ThemeRoutes() {
  const token = localStorage.getItem('access_token');
  const navigate = useNavigate();
  const location = useLocation();
  let [searchParams] = useSearchParams();
  const { getAccessTokenSilently, isAuthenticated, isLoading, logout } = useAuth0();
  const { pathname } = location;
  const [isToken, setIsToken] = useState(token);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchTokenAndCheckTerms = async () => {
      const fullPath = `${pathname}${location.hash}`;
      try {
        if (isAuthenticated && !isLoading) {
          const fetchedToken = await getAccessTokenSilently({
            audience: audience,
          });

          const decodedToken = jwtDecode(fetchedToken);
          if (isTokenValid(decodedToken)) {
            setIsToken(fetchedToken);
            localStorage.setItem('access_token', fetchedToken);
            Cookies.set('access_token', fetchedToken);
          } else {
            setIsToken(null);
            logout({ 
              logoutParams: {
                returnTo: `${window.location.origin}${
                  pathname !== '/' ? '/?next=' + fullPath : ''
                }`
              }
            }); // Logout if token is expired
            console.error('Token has expired');
          }
        } else if (!isAuthenticated && pathname !== '/') {
          navigate(`/?next=${fullPath}`);
        }

        const termsAccepted = Cookies.get('terms_accepted') === 'true';
        setHasAcceptedTerms(termsAccepted);

        const createNavigationPath = (path, params) => {
          const queryParams = new URLSearchParams();
        
          if (!params.has('next') && pathname !== '/') {
            queryParams.append('next', fullPath);
          } else if (params.has('next')) {
            queryParams.append('next', params.get('next'));
          }
        
          if (!params.has('after') && pathname !== '/') {
            queryParams.append('after', fullPath);
          }
        
          return `${path}?${queryParams.toString()}`;
        };
        
        
        if (isAuthenticated && !termsAccepted) {
          navigate(createNavigationPath('/terms-and-conditions',searchParams));
        }

        if (isAuthenticated && termsAccepted) {
          if(pathname === '/' && searchParams.has("next")) {
            navigate(searchParams.get('next') + location.hash);
          } else if (pathname === '/' && !searchParams.has("next")) {
            navigate('/entry/map');
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.error('Unauthorized: Please login again');
        } else {
          console.error('Error fetching token:', error.message);
        }
        localStorage.removeItem('access_token');

        const fullPath = `${pathname}${location.hash}`;
        let returnToPath = '/';

        if (pathname !== '/') {
          returnToPath = `/?next=${fullPath}`;
        } else if (searchParams.has("next")) {
          returnToPath = searchParams.get("next");
        }
        
        if (isAuthenticated) {
          logout({ 
            logoutParams: {
              returnTo: `${window.location.origin}${returnToPath}`
            }
          }); 
        } else {
          navigate(returnToPath)
        }
      }
    };

    const isTokenValid = (decodedToken) => {
      const currentTime = Date.now() / 1000;
      return decodedToken.exp > currentTime;
    };

    fetchTokenAndCheckTerms();
  }, [
    getAccessTokenSilently,
    navigate,
    isAuthenticated,
    isToken,
    isLoading,
    logout,
    pathname,
    audience,
  ]);

  const TermsRoutes = {
    path: '/terms-and-conditions',
    element: <UsersAgreement />
  }

  const mainRoute = hasAcceptedTerms ? (isSmall ? MobileRoutes : MainRoutes) : {};
  const routes = (isToken || (isToken && isAuthenticated && !isLoading))
    ? [mainRoute, AuthenticationRoutes, PageRoutes, AdminRoutes, ProjectDetailRoutes, TermsRoutes]
    : [AuthenticationRoutes, TermsRoutes];

  return useRoutes(routes);
}
