// MainRoutes.js

/* import { lazy } from 'react'; */
import ProjectDetailsLayout from 'layout/ProjectDetailsLayout';
/* import Loadable from 'ui-component/Loadable'; */
import ProtectedRoute from './ProtectedRoute';

/* const ProjectDetailsView = Loadable(lazy(() => import('views/pages/ProjectDetailsView')));
 */

const ProjectDetailRoutes = {
  path: '/project',
  element: <ProjectDetailsLayout />,
  children: [
    {
      path: 'projectdetail',
      element: <ProtectedRoute />,
      children: [
        {
          path: ':uniqueId',
          element: <ProtectedRoute />,
        },
      ],
    },
  ],

};

export default ProjectDetailRoutes;
