import React, { useState, lazy, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import {
  Box,
  useMediaQuery,
  Grid,
  Skeleton,
} from "@mui/material";
import { GeoJSON, useMap } from "react-leaflet";
import L from "leaflet";
import { styled, useTheme } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import Loadable from 'utilities/Loadable';
import MapProjectCard from 'component/MapProjectCard';
import ProjectPreviewContent from 'component/ProjectPreviewContent';
import FilterComponent from 'component/Filter';
import ProjectsListSidePanel from "../../ui-component/ProjectsListSidePanel";
import { getProjectByIdView } from '../../api/index';
import { getViewFromPathname } from '../../layout/MainLayout';
import defaultTeaserImage from 'assets/images/defaultImage.png'

// dashboard routing
const MapComponent = Loadable(lazy(() => import('component/MapComponent')));

export const GeojsonDataComponent = ({ geoJsonData, darkBackgrounds = false }) => {
  const map = useMap();

  map.invalidateSize();

  // get a ref to the underlying L.geoJSON
  const geoJsonRef = useRef()

  // set the data to new data whenever it changes
  // data in GeoJSON is not mutable, so it needs 
  // to be changed using a ref to the underlying
  // leaflet element
  useEffect(() => {
    if (geoJsonRef.current) {
      geoJsonRef.current.clearLayers()   // remove old data
      geoJsonRef.current.addData(geoJsonData) // might need to be geojson.features
      const bounds = L.geoJSON(geoJsonData).getBounds();
      map.flyToBounds(bounds, {
        duration: 2, // Adjust the duration as needed
        maxZoom: 13, // Set your desired maximum zoom level
      });
    }
  }, [geoJsonRef, geoJsonData])

  return (
    <GeoJSON
      ref={geoJsonRef}
      data={geoJsonData}
      style={{
        fillColor: 'transparent',
        color: darkBackgrounds ? "#EAFFD8" : 'green',
      }}
    />
  );
};

// styles
const MapMain = styled("main")(() => {
  return {
    borderBottomLeftRadius: 0,
    width: "100%",
    backgroundColor: "transparent !important",
    borderBottomRightRadius: 0,
    display: "flex",
    justifyContent: "space-between",
    // marginTop: "56px",
  };
});

const bbox = [
  -91.9634101850077,
  -22.635794064004042,
  110.91723851161684,
  19.76978846658427
];

const maxBounds = [
  [bbox[0], bbox[1]],
  [bbox[2], bbox[3]]
];


const ResetMapLocation = ({ selectedProject }) => {
  const map = useMap();

  useEffect(() => {
    if(selectedProject === null) {
      map.flyToBounds(maxBounds, {
        duration: 1,
        maxZoom: 4,
      });
      map.invalidateSize();
    }
  },[selectedProject])

}

const MapView = () => {
  const [
    selectedProject,
    filteredCardData,
    projectLoading,
    viewFilters,
    filteredData,
    viewBookmarked,
    searchTerm,
    handleSearchChange,
    suggestions,
    highlightMatch,
    handleBackButtonClick,
    handleBookMarkViews,
    handleFilterViews,
    getFilteredData,
    setSelectedProject,
    isSelectedProjectLoading,
    setSelectedProjectLoading,
    showBackButton,
    handleProjectDetails,
    filteredSelectedData,
  ] = useOutletContext();

  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [openFilterAlert, setOpenFilterAlert] = useState(false);
  const [activeBoundaries, setActiveBoundaries] = useState(true);
  const [isDarkBackground, setDarkBackground] = useState(false);
  const [alertMessage, setAlertMessage] = useState(''); // Add state for the alert message
  const [selectedCardIndex, setSelectedCardIndex] = useState(localStorage.getItem("selectedProject"));

  const token = localStorage.getItem('access_token');

  const mapContainerRef = useRef(null);

  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const handleCardHover = (cardIndex) => {
    if (cardIndex !== selectedCardIndex) {
      setSelectedCardIndex(cardIndex);
    }
  };

  const handleCardOnLeave = () => {
    setSelectedCardIndex(null);
  };

  const handleCloseFilterAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenFilterAlert(false);
  };
  const getAlertMessage = (value, showAlert) => {
    setAlertMessage(value);
    setOpenFilterAlert(showAlert);
  };



  const handleProjectClick = async (projectId) => {
    localStorage.setItem("selectedProject",projectId);
    // set a provisional state of selectedProject to
    // quickly handle project click
    setSelectedProject({identifier: projectId});
    const cardElement = document.getElementById(projectId);
    if (cardElement) {
      const container = cardElement.parentElement;
      const cardTopOffset = cardElement.getBoundingClientRect().top + container.scrollTop - 245;
      const duration = 500;
      const start = performance.now();

      // Create an animation function
      function animate(time) {
        const elapsed = time - start;
        const progress = Math.min(elapsed / duration, 1);
        container.scrollTop = cardTopOffset * progress;

        if (progress < 1) {
          requestAnimationFrame(animate);
        }
      }
      requestAnimationFrame(animate);
    }

    try {
      setSelectedProjectLoading(true);
      const view = getViewFromPathname(location.pathname);
      const response = await getProjectByIdView(projectId, token, view);
      if (response.detail === "not validated: Signature has expired" || response.status === 401) {
        console.error('Unauthorized access. Redirecting to login.');
        navigate('/');
        return;
      }

      const { data } = response.data;

      if (response.data.status) {
        setSelectedProject(data);
        setSelectedProjectLoading(false);
      } else {
        setSelectedProject({identifier: projectId, ...response.data});
        setSelectedProjectLoading(false);
      }

    } catch (error) {
      console.error('Error:', error);
      setSelectedProjectLoading(false);
    }
  }


  const handleOnClosePopup = () => {
    setSelectedProject(null);
    localStorage.removeItem("selectedProject");
  };

  return (
    <MapMain  className='Help-entrypage-start'>
        <>
        <ProjectsListSidePanel
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
          suggestions={suggestions}
          highlightMatch={highlightMatch}
          handleBookMarkViews={handleBookMarkViews}
          handleFilterViews={handleFilterViews}
          handleBackButtonClick={handleBackButtonClick}
          showBackButton={showBackButton}
          numberOfProjects={filteredCardData}
          loading={projectLoading}
          filteredData={filteredData}
          viewBookmarked={viewBookmarked}
          viewFilters={viewFilters}
          onSuggestionClick={handleProjectClick}
        >
          {projectLoading ? (
            // Render skeletons when initialLoading is true
            <Skeleton variant="rect" width="100%" height="100vh" />
          ) : (

            !viewFilters ?
              (
                <>
                  <Box style={{
                    width: '100%',
                    overflow: 'auto',
                  }}>
                    {filteredCardData &&
                      filteredCardData?.map((item, index) => (
                        <MapProjectCard
                          key={item.identifier}
                          selectedProject={selectedProject}
                          active={item.identifier === selectedProject?.identifier}
                          uuid={item.identifier}
                          onClosePopup={handleOnClosePopup}
                          onCardClick={handleProjectClick}
                          onCardHover={() => handleCardHover(index)}
                          onCardLeave={() => handleCardOnLeave(index)}
                          index={item.identifier}
                          name={item.name}
                          countries={item.countries}
                          client={item.client}
                          teaserImage={
                            item.teaser_image_resources?.length > 0
                              ? item.teaser_image_resources[0].access_url
                              : defaultTeaserImage
                          }
                          tags={item.tags}
                          projectData={item}
                          isPopoverOpen={selectedProject !== null ? true : false}
                        />
                      ))}
                  </Box>
                </>
              )
              :
              (
                <FilterComponent getAlertMessage={getAlertMessage} getFilteredData={getFilteredData}/>
              )

          )}
        </ProjectsListSidePanel>
        {selectedProject && (
          <div
            style={{
              width: matchDownMd ? "calc(3/5 * 100%)" : "453px",
              height: "calc(100vh - 56px)",
              backgroundColor: "#F2EEE3",
              borderRadius: "0",
              overflowY: "hidden",
              overflowX: "hidden",
            }}
          >
            <ProjectPreviewContent
              loading={isSelectedProjectLoading}
              selectedProject={selectedProject}
              wildlife_visual_resources={selectedProject?.wildlife_visual_resources?.slice(
                0,
                10,
              )}
              onClick={() =>
                handleProjectDetails(
                  filteredSelectedData.identifier || selectedProject.identifier,
                )
              }
              handlePopoverContentClose={handleOnClosePopup}
              onOpen={selectedProject ? true : false}
            />
          </div>
        )}
        {!(selectedProject && matchDownMd) && (
          <Grid
            sx={{
              width: selectedProject != null
                ? `calc(100vw - ${362 + 453}px)`
                : matchDownMd
                  ? "calc(3/5 * 100%)"
                  : "calc(100vw - 362px)",
                  zIndex: 0,
            }}
          >
            {projectLoading ? (
              // Render skeletons when initialLoading is true
              <Skeleton variant="rect" width="100%" height="100vh" />
            ) : (
              <>
                <MapComponent
                  searchResults={
                    filteredCardData.map((item) => {
                      const { coordinates } = item.geometry_centroid;
                      const projectType = item.be_product;  // Assuming cardType is a property in the item object
                    
                      // Check if coordinates is an array and has at least two elements
                      if (Array.isArray(coordinates) && coordinates.length >= 2) {
                        return {
                          coordinates: [coordinates[1], coordinates[0]],  // Swap the coordinates (y, x)
                          projectType,
                          projectColor: item.be_product_pin_colour,
                          projectlegends: item.be_product_pin_legend,
                          projectName: item.name
                        };
                      } else {
                        console.error("Invalid coordinates:", coordinates);
                        return null;  // Handle the case where coordinates are not valid
                      }
                    }).filter(item => item !== null)  // Filter out any null results
                  }
                  mapContainerRef={mapContainerRef}
                  zoom={3}
                  selectedProject={selectedProject}
                  setDarkBackground={setDarkBackground}
                  activeBoundaries={activeBoundaries}
                  setActiveBoundaries={setActiveBoundaries}
                  projectHover={selectedCardIndex}
                  data={filteredCardData}
                  handleCardClick={handleProjectClick}
                  geoJsonData={selectedProject ? selectedProject.geometry : null}
                >
                  {(selectedProject && selectedProject.geometry && activeBoundaries) && (
                    <GeojsonDataComponent 
                      geoJsonData={selectedProject.geometry}
                      darkBackgrounds={isDarkBackground}/>
                  )}
                  <ResetMapLocation selectedProject={selectedProject} />
                </MapComponent>
                {openFilterAlert && (
                    <Snackbar
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                      open={openFilterAlert}
                      autoHideDuration={3000}
                      onClose={handleCloseFilterAlert}
                    >

                      <Alert
                        onClose={handleCloseFilterAlert}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%', backgroundColor: '#EAFFD8', color: '#164A1A' }}
                        action={<></>} // Hides the close icon
                        iconMapping={{
                          success: <CheckCircleOutlineRoundedIcon fontSize="inherit" />,
                        }}
                        
                      >
                        {alertMessage}
                      </Alert>
                    </Snackbar>
                )
                }
              </>
            )}
          </Grid>
        )}
      </>
    </MapMain>
  );
};

export default MapView;
