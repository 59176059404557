import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom'; // Import useNavigate and useLocation

import Toolbar from '@mui/material/Toolbar';
import { Grid, IconButton, useTheme, useMediaQuery } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ButtonBookMark from 'assets/images/icons/Bookmark-stroke.svg';
import ButtonBookMarkActive from 'assets/images/icons/Bookmark-filled.svg';
import ButtonBookMarkHovered from 'assets/images/icons/Bookmark-filled-hovered.svg';

import ViewSwitcher from '../ViewSwitcher'

function ProjectDetailSearchBar({ searchTerms, handleSearchChange, maxWidth, handleLeftDrawerToggle, projectData }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { uniqueId } = useParams();
  const [isBookMarkActive, setIsBookMarkActive] = useState(false);
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  // Sample bookmarked data array retrieved from local storage
  const [bookmarkedData, setBookmarkedData] = useState([]);

  // Function to check if a project is bookmarked
  useEffect(() => {
    // Load bookmarks from localStorage on initial render
    const savedBookmarks = JSON.parse(localStorage.getItem('bookmarks')) || [];
    setBookmarkedData(savedBookmarks);
  }, []);

  const handleBookmark = () => {
    let updatedBookmarks;
    if (bookmarkedData.some(b => b.identifier === uniqueId)) {
      updatedBookmarks = bookmarkedData.filter(b => b.identifier !== uniqueId);
    } else {
      updatedBookmarks = [...bookmarkedData, projectData];
    }

    setBookmarkedData(updatedBookmarks);
    localStorage.setItem('bookmarks', JSON.stringify(updatedBookmarks));
  };

  const isBookmarked = bookmarkedData.some(b => b.identifier === projectData.identifier);


  const handleBookmarkHover = () => {
    setIsBookMarkActive(true)
  };

  const handleBookMarkOnLeave = () => {
    setIsBookMarkActive(false);
  };

  const handleGoBack = () => {
    if (location.key === 'default') {
      navigate('/entry/map');
    } else {
      navigate(-1);
    }
  };

  return (
    <div style={{
       backgroundColor: '#F9F5EB',
       boxShadow: 'none',
       borderBottom: 'none',
       paddingBottom: '1rem',
       paddingTop: isSmall ? '0.8rem' : '2rem',
       width: "100%",
       position: "sticky",
       top: (isSmall || isMedium) ? "56px" : "0",
       zIndex: 9998
    }}>
      <Toolbar disableGutters sx={{ padding: 0, paddingRight: '20px', paddingLeft: '20px' }}>
        <Grid style={{
          display: 'flex',
          flexDirection: "row",
          justifyContent: 'space-between',
          alignItems: 'baseline',
          width: '100%',
          placeItems: "center"
        }}>
          <div style={{
            marginLeft: isSmall ? "0" : "0.5rem" }}>
            <IconButton onClick={handleGoBack}>
              <ArrowBackIosIcon sx={{ color: '#1D1D1B', width: '16px', height: '16px', }} />
            </IconButton>
          </div>
          {/*  <Grid item xs={6}>
              <SearchMenu searchTerms={searchTerms} onChange={handleSearchChange} handleLeftDrawerToggle={handleLeftDrawerToggle} />
            </Grid>
              [theme.breakpoints.up('md')]: {
      width: '65%',
      },
      [theme.breakpoints.up('xl')]: {
        width: '43.5%',
      },
      [theme.breakpoints.up('xxl')]: {
        width: '48.75%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '65%',
      },
      maxWidth: '65%',
            */}
          {!isSmall && <div style={{
            display: "flex",
            justifyContent: "center"
          }}>
            <ViewSwitcher />
          </div>}
          <div style={{
            marginRight: "0.5rem"
          }}>
            <div style={{ 
              padding: isSmall ? '8px' : '4.4px', 
              border: '1px solid #D8D5CC', 
              borderRadius: '50%', 
              width: isSmall ? '35px' : '27px', 
              height: isSmall ? '35px' : '27px', 
              cursor: 'pointer',
              display: "flex",
              placeItems: "center"
            }}
              onClick={() => handleBookmark()}
              onMouseEnter={handleBookmarkHover}
              onMouseLeave={handleBookMarkOnLeave}>
              <img src={isBookmarked ? ButtonBookMarkActive : ( isBookMarkActive ? ButtonBookMarkHovered : ButtonBookMark )} alt="plus-icon" />
            </div>
          </div>
        </Grid>
      </Toolbar>
    </div>
  );
}

export default ProjectDetailSearchBar;
