import React from 'react';
import { useLocation } from 'react-router-dom'; // Import useNavigate and useLocation

import Toolbar from '@mui/material/Toolbar';
import { Grid, Typography, useMediaQuery, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Divider from '@mui/material/Divider';
import SearchBox from 'component/SearchBox';
import TurnedInNotOutlinedIcon from 'assets/images/icons/bookmark-icon.svg';
import FunctionalIcon from 'assets/images/icons/Functional-Button-large.svg';
import ViewSwitcher from '../ViewSwitcher'
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';


function MapSearchBar({
  searchTerms,
  handleSearchChange,
  handleLeftDrawerToggle,
  projectData,
  selectedProject,
  viewBookmarked,
  viewFilters,
  handleBookMarkViews,
  handleFilterViews,
  showBackButton,
  filteredData,
  handleBackButtonClick,
  resetFilteredData,
  handlePopoverContentClose
}) {
  const location = useLocation();
  const theme = useTheme();
  // Media query for tablet devices
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  return (
    <div style={{
      backgroundColor: '#f2eee3',
      boxShadow: 'none',
      borderBottom: 'none',
      position: 'sticky',
      top: '3rem',
      zIndex: 9,
      paddingTop: "1.3rem",
      width: !(selectedProject && isMedium) ? '100%' : 'calc(2/5 * 100%)'
    }}>
      <Toolbar disableGutters sx={{ padding: "25px" }}>
        <Grid item style={{
          display: 'flex',
          flexDirection: "row",
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}>
          <Grid item xs={4}>
            {location.pathname !== '/entry/map' ?
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {
                  showBackButton && (
                    <IconButton style={{}} onClick={handleBackButtonClick} disableFocusRipple disableRipple>
                      <ArrowBackIosIcon style={{ fontSize: '16px', color: 'black' }} />
                    </IconButton>
                  )
                }
                <SearchBox searchTerms={searchTerms} onChange={handleSearchChange} handleLeftDrawerToggle={handleLeftDrawerToggle} />
                <div style={{ cursor: 'pointer' }} className="Help-entrypage-filter-1" onClick={handleFilterViews}>
                  {filteredData && filteredData?.length > 0 && (
                    <span style={{ position: 'absolute', top: '48px', left: '394px' }}><FiberManualRecordRoundedIcon style={{ color: '#2D8FE8', fontSize: '10px' }} /></span>
                  )}
                  <img src={FunctionalIcon} alt='functional-icon' style={{
                    color: "#164A1A", height: '1.8rem', margin: '6px'
                  }} />
                  {viewFilters && <Divider sx={{ borderColor: '#164A1A', borderWidth: '1px' }} />}
                </div>
                <div style={{ cursor: 'pointer' }} onClick={handleBookMarkViews}>
                  <img src={TurnedInNotOutlinedIcon} alt='bookmark-icon' style={{
                    color: "#164A1A", height: '1.8rem', margin: '10px 10px 0px 10px'
                  }} />
                  {viewBookmarked && <Divider sx={{ borderColor: '#164A1A', borderWidth: '1px' }} />}
                </div>

              </div>
              :
              <div></div>}
          </Grid>
          {!(selectedProject && isMedium) && <div style={{
            position: "absolute",
            left: "50%",
            transform: "translate(-50%)"
          }}>
            <ViewSwitcher handleLeftDrawerToggle={handleLeftDrawerToggle} resetFilteredData={resetFilteredData} handlePopoverContentClose={handlePopoverContentClose}/>
          </div>}
          {!(selectedProject && isMedium) && <Grid item sx={{ paddingTop: '2rem', paddingBottom: '1rem' }}>
            {projectData.length !== 0 &&
              <Typography variant="h5" sx={{ color: "#626364" }}>
                {projectData.length} Projects
              </Typography>
            }
          </Grid>}
        </Grid>
      </Toolbar>
    </div>
  );
}

export default MapSearchBar;
