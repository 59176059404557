import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate and useLocation
import { ButtonGroup, Button } from '@mui/material';
import mapInactive from '../../assets/images/icons/map-inactive.svg';
import mapActive from '../../assets/images/icons/map-active.svg'
import tilesInactive from '../../assets/images/icons/tiles-inactive.svg';
import tilesActive from '../../assets/images/icons/tiles-active.svg'
import tableInactive from '../../assets/images/icons/table-inactive.svg';
import tableActive from '../../assets/images/icons/table-active.svg';
import './viewSwitcher.css'
import { useJoyride } from "utilities/JoyrideProvider";


function ViewSwitcher({resetFilteredData, handlePopoverContentClose}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeOption, setActiveOption] = useState(location.pathname);

  const { nextStep, joyrideState } = useJoyride();

  const handleNavigation = (route) => {
    setActiveOption(route);
    navigate(route);
  };

  // useEffect(() => {
  //   // Simulate component loading time (e.g., fetching data)
  //   if(joyrideState.stepIndex === 2 || joyrideState.stepIndex === 3){
  //     const timer = setTimeout(() => {
  //       nextStep(); // Notify Joyride that the component is ready
  //     }, 500); // Adjust this delay based on your actual loading time
  
  //     return () => clearTimeout(timer); // Cleanup on unmount
  //   }
  // }, [activeOption]);

  // useEffect(() => {
  //   if (!projectLoading && (joyrideState.stepIndex === 2 || joyrideState.stepIndex === 3)) {
  //     // Ensure Joyride continues only after data is loaded
  //     nextStep();
  //   }
  // }, [projectLoading, joyrideState.stepIndex]);

   // Watch for stepIndex changes and update the active option based on Joyride step
  //  useEffect(() => {
  //   switch (joyrideState.stepIndex) {
  //     case 2:
  //       handleNavigation('/entry/grid');
  //       break;
  //     case 3:
  //       handleNavigation('/entry/table');
  //       break;
  //     case 1:
  //       handleNavigation('/entry/map');
  //       break;
  //     case 4:
  //         //handlePopoverContentClose();
  //         handleNavigation('/entry/table');
  //         break;
  //     default:
  //       //handlePopoverContentClose();
  //       //handleNavigation('/entry/map');
  //       // No matching stepIndex, no action needed
  //       break;
  //   }
  // }, [joyrideState.stepIndex]); // Update when stepIndex changes

  // Also update activeOption when the route changes
  useEffect(() => {
    setActiveOption(location.pathname);
  }, [location.pathname]);


 

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <ButtonGroup sx={{ height: '48px', boxShadow: '2px 12px 32px 2px rgba(29, 29, 27, 0.05)', borderRadius: '40%' }}>
        <Button
          id="map-switcher-button"
          disableRipple
          sx={{
            borderTopLeftRadius: '45%',
            borderBottomLeftRadius: '45%',
            border: activeOption === '/entry/map' ? '1.7px solid #D2FFAB' : '1.7px solid #D8D5CC',
            borderRight: activeOption === '/entry/grid' ? '1.7px solid #D2FFAB' : '1.7px solid #D8D5CC',
            backgroundColor: activeOption === '/entry/map' ? '#164A1A' : '#F9F5EB',
            "&:hover": {
              background: activeOption === '/entry/map'  ? '#164A1A' : '#F2EEE3',
              border: activeOption === '/entry/map' ? '1.7px solid #D2FFAB' : '1.7px solid #D8D5CC',
              borderRight: activeOption === '/entry/grid' ? '1.7px solid #D2FFAB' : '1.7px solid #D8D5CC',
            },
          }}
          onClick={() => handleNavigation('/entry/map')}
        >
          {activeOption === '/entry/map' ? <img src={mapActive} alt="map" /> : <img src={mapInactive} alt="map" />}

        </Button>
        <Button
          id="grid-switcher-button"
          disableRipple
          sx={{
            border: activeOption === '/entry/grid' ? '1.7px solid #D2FFAB' : '1.7px solid #D8D5CC',
            borderLeftColor: activeOption === '/entry/map' ? '#D2FFAB' : '',
            backgroundColor: activeOption === '/entry/grid' ? '#164A1A' : '#F9F5EB',
            "&:hover": {
              background: activeOption === '/entry/grid'  ? '#164A1A' : '#F2EEE3',
              border: activeOption === '/entry/grid' ? '1.7px solid #D2FFAB' : '1.7px solid #D8D5CC',
            },
          }}
          onClick={() => handleNavigation('/entry/grid')}
        >
          {/*  <GridIcon style={{ color: activeOption === '/entry/grid' ? '#F2EEE3' : '#164A1A' }} /> */}
          {activeOption === '/entry/grid' ? <img src={tilesActive} alt="map" /> : <img src={tilesInactive} alt="tiles" />}
        </Button>
        <Button
          id="list-switcher-button"
          disableRipple
          className="my-fourth-step"
          sx={{
            borderTopRightRadius: '45%',
            borderBottomRightRadius: '45%',
            border: activeOption === '/entry/table' ? '1.7px solid #D2FFAB' : '1.7px solid #D8D5CC',
            borderLeftColor: activeOption === '/entry/grid' ? '#D2FFAB' : '',
            backgroundColor: activeOption === '/entry/table' ? '#164A1A' : '#F9F5EB',
            "&:hover": {
              background:  activeOption === '/entry/table' ? '#164A1A' : '#F2EEE3',
              border: activeOption === '/entry/table' ? '1.7px solid #D2FFAB' : '1.7px solid #D8D5CC',
            },
          }}
          onClick={() => handleNavigation('/entry/table')}
        >
          {/* <ListIcon style={{ color: activeOption === '/entry/table' ? '#F2EEE3' : '#164A1A' }} /> */}
          {activeOption === '/entry/table' ? <img src={tableActive} alt="map" /> : <img src={tableInactive} alt="table" />}
        </Button>
      </ButtonGroup>
    </Box>

  );
}

export default ViewSwitcher;
