// MainRoutes.js
import { lazy } from 'react';
import AdminLayout from 'layout/AdminLayout';
import ProtectedRoute from './ProtectedRoute';
import Loadable from 'utilities/Loadable';

const NotFound = Loadable(lazy(() => import('views/pages/PageNotFound')));
// const MainView = Loadable(lazy(() => import('views/AdminView/MainGrid')));
// const UserView = Loadable(lazy(() => import('views/AdminView/Users')));
// const ProjectsView = Loadable(lazy(() => import('views/AdminView/Projects')));

const PageRoutes = {
    path: '/admin',
    element: <AdminLayout />,
    children: [
        {
            path: 'overview',
            element: <ProtectedRoute />,
        },
        {
            path: 'users',
            element: <ProtectedRoute />,
            children: [
                {
                  path: ':userId',
                  element: <ProtectedRoute />,
                },
              ],
        },
        {
            path: 'projects',
            element: <ProtectedRoute />,
        },
        {
            path: '*/',
            element: <NotFound />,
        }
    ],
};

export default PageRoutes;
