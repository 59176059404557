import React from "react";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Divider from '@mui/material/Divider';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TurnedInNotOutlinedIcon from 'assets/images/icons/bookmark-icon.svg';
import MagnifyIcon from 'assets/images/icons/magnify-icon.svg';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import FunctionalIcon from 'assets/images/icons/Functional-Button-large.svg';

const SearchContainer = styled("div")(() => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  padding: "1.5rem 1rem 1rem",
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  width: '-webkit-fill-available',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const StyledInputBase = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== "showBackButton",
})(({ theme, showBackButton }) => ({
  color: "#626364 !important",
  fontFamily: "MADE TOMMY",
  fontWeight: 400,
  backgroundColor: "#F2EEE3 !important",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: "10px 20px",
    transition: theme.transitions.create("width"),
    width: "100%",
    fontWeight: 400,
    fontFamily: "MADE TOMMY",
    color: "#626364 !important",
    border: `1.5px solid #D1CEC5`,
    borderRadius: theme.shape.borderRadius,
  },
  '&::after': {
    content: `url(${MagnifyIcon})`,
    right: '15px',
    position: 'absolute'
  }
}));

function MobileSearchBar({
  searchTerm,
  handleSearchChange,
  handleBackButtonClick,
  showBackButton,
  handleBookMarkViews,
  viewBookmarked,
  handleFilterViews,
  filteredData,
  viewFilters,
}) {
  return (
    <SearchContainer>
      <Search>
        {showBackButton && (
          <div
            style={{
              alignSelf: 'stretch',
              background: '#f2eee3',
              paddingRight: '0.7rem',
              paddingLeft: '0.5rem'
            }}
            onClick={handleBackButtonClick}
          >
            <ArrowBackIosIcon
              style={{
                cursor: "pointer",
                height: '100%',
                fontSize: '16px',
                fill: "#164A1A",
              }}
            />
          </div>
        )}
        <StyledInputBase
          placeholder={searchTerm ? searchTerm : "Search"}
          inputProps={{ "aria-label": "search" }}
          onChange={handleSearchChange}
          value={searchTerm}
          showBackButton={showBackButton}
        />
      </Search>

      <div style={{cursor: 'pointer'}} className="Help-entrypage-filter-1" onClick={handleFilterViews}>
            {filteredData && filteredData?.length > 0  && (
              <span style={{position: 'absolute',top: '33px',right: '53px'}}><FiberManualRecordRoundedIcon style={{color: '#2D8FE8', fontSize: '10px'}}/></span>
            )}
            <img src={FunctionalIcon} alt='functional-icon' style={{
              color: "#164A1A", height: '1.8rem', margin: '6px'
            }} />
          {viewFilters &&  <Divider sx={{borderColor: '#164A1A', borderWidth:'1px'}}/>} 
          </div>

      <div style={{ cursor: "pointer" }} onClick={handleBookMarkViews}>
        <img
          src={TurnedInNotOutlinedIcon}
          alt="bookmark-icon"
          style={{
            color: "#164A1A",
            height: "1.8rem",
            margin: '10px 10px 0px 10px',
          }}
        />
        {viewBookmarked && (
          <Divider sx={{ borderColor: "#164A1A", borderWidth: "1px" }} />
        )}
      </div>
    </SearchContainer>
  );
}

export default MobileSearchBar;
