import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery, Typography, IconButton, Paper } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Divider from '@mui/material/Divider';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import SideBarSearchMenu from 'component/SidebarSearchMenu';
import TurnedInNotOutlinedIcon from 'assets/images/icons/bookmark-icon.svg';
import FunctionalIcon from 'assets/images/icons/Functional-Button-large.svg';

const SearchGridTable = ({
  children,
  onSuggestionClick,
  suggestions,
  highlightMatch,
  searchTerm,
  viewBookmarked,
  viewFilters,
  showBackButton,
  handleSearchChange,
  handleBackButtonClick,
  handleBookMarkViews,
  handleFilterViews,
  numberOfProjects,
  filteredData,
  loading
}) => {
  const theme = useTheme();
  const location = useLocation();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const drawer = (
    <>
      <Box sx={{
        padding: '20px 1rem 15px 1rem',
        position: "sticky",
        top: "0",
        zIndex: 9,
        background: "#f2eee3"
      }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {
            showBackButton && (
              <IconButton onClick={handleBackButtonClick} disableFocusRipple disableRipple>
                <ArrowBackIosIcon style={{ fontSize: '16px', color: 'black' }} />
              </IconButton>
            )
          }
          <SideBarSearchMenu
            searchTerm={searchTerm}
            handleSearchChange={handleSearchChange}
            handleBackButtonClick={handleBackButtonClick}
            onSuggestionClick={onSuggestionClick}
            highlightMatch={highlightMatch}
            suggestions={suggestions}
            showBackButton={showBackButton}
          />
          <div style={{ cursor: 'pointer' }} className="Help-entrypage-filter-1" onClick={handleFilterViews}>
            {filteredData && filteredData?.length > 0 && (
              <span style={{ position: 'absolute', top: '48px', left: '394px' }}><FiberManualRecordRoundedIcon style={{ color: '#2D8FE8', fontSize: '10px' }} /></span>
            )}
            <img src={FunctionalIcon} alt='functional-icon' style={{
              color: "#164A1A", height: '1.8rem', margin: '6px'
            }} />
            {viewFilters && <Divider sx={{ borderColor: '#164A1A', borderWidth: '1px' }} />}
          </div>
          <div style={{ cursor: 'pointer' }} className="Help-entrypage-bookmarks-1" onClick={handleBookMarkViews}>
            <img src={TurnedInNotOutlinedIcon} alt='bookmark-icon' style={{
              color: "#164A1A", height: '1.8rem', margin: '10px 10px 0px 10px',
            }} />
            {viewBookmarked && <Divider sx={{ borderColor: '#164A1A', borderWidth: '1px' }} />}
          </div>
        </div>
      </Box>
      {
        !viewFilters
        &&
        <Box sx={{ padding: '0rem 1rem 0rem 1rem' }}>
          {numberOfProjects?.length !== 0 ? (
            <Typography variant="h5">
              {numberOfProjects?.length === 1
                ? "1 Project"
                : `${numberOfProjects?.length} Projects`}
            </Typography>
          ) : (
            !loading &&
            <Typography variant="h5">
              No result found.
            </Typography>
          )}
        </Box>
      }
      <Box
        component="div"
        style={{
          height: !matchUpMd ? 'calc(95vh - 56px)' : 'calc(95vh - 88px)',
          paddingLeft: '21px',
          paddingRight: '21px',
        }}
      >
        {location.pathname !== '/entry/map' && <Box>
          {children}
        </Box>}
      </Box>
    </>
  );


  // default render for browser
  return (
    <Paper
      className="Help-entrypage-filter"
      sx={{
        // position: "absolute",
        overflowY: 'auto',
        width: matchDownMd ? "calc(2/5 * 100%)" : "100%",
        background: '#F2EEE3',
        color: theme.palette.text.primary,
        border: 0,
        height: 'calc(100vh - 56px)',
        [theme.breakpoints.up('md')]: {
          top: '57px',
        },
        [theme.breakpoints.down('md')]: {
          width: '100%',
          top: '57px',
          zIndex: 9999
        }
      }}
    >
      {location.pathname !== '/entry/map' && drawer}
    </Paper>
  );
};

export default SearchGridTable;