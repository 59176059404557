import React from "react";
import Cookies from 'js-cookie';
import { Typography, Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";


const Footer = () => {
  const theme = useTheme();
  // Media query for mobile devices
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const tour_completed = Cookies.get('tour_completed');

  return (
    <Box
      sx={{
        fontFamily: "MADE TOMMY",
        backgroundColor: "#164A1A",
        fontWeight: "500",
        textAlign: "center",
        width: "100%",
        position: !tour_completed ? "static" : "relative",
        zIndex: 9999999999999,
        [theme.breakpoints.up("md")]: {
          padding: "2rem",
        },
        [theme.breakpoints.down("md")]: {
          padding: "1rem",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "1rem",
        },
      }}
    >
      <div style={{ display: "flex",
        flexWrap: isSmall ? "wrap" : "no-wrap",}}>
        <Typography
          variant="body1"
          component="span"
          sx={{ fontWeight: "500", 
          padding: isSmall ? "1rem 1rem 2rem 1rem" : "0", }}
          color={"#F9F5EB"}
          marginRight={10}
        >
          ©{" "}
          <a
            href="https://www.biometrio.earth/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "#F9F5EB" }}
          >
            {" "}
            biometrio.earth
          </a>{" "}
          2024
        </Typography>

        <div
          style={{
            display: "flex",
            flexDirection: isSmall ? "column" : "row",
            alignItems: isSmall ? "baseline" : "center",
            justifyContent: isSmall ? "flex-start" : "center",
            margin: isSmall ? "0" : "auto",
          }}
        >
          <Typography mr={10} color={"#D2FFAB"} sx={{ fontWeight: "300", 
            padding: isSmall ? "1rem" : "", }}>
            Imprint
          </Typography>
          <Typography mr={10} color={"#D2FFAB"} sx={{ fontWeight: "300", 
            padding: isSmall ? "1rem" : "",}}>
            Data Safety
          </Typography>
          <Typography color={"#D2FFAB"} sx={{ fontWeight: "300", 
            padding: isSmall ? "1rem" : "", }}>
            Cookie Policy
          </Typography>
        </div>
      </div>
    </Box>
  );
};

export default Footer;
