/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */

const FONT_FAMILY_HEADING = '"Cormorant Garamond", "serif"';
const FONT_FAMILY_PRIMARY = '"MADE TOMMY", "sans-serif"';


export default function themeTypography(theme) {

  return {
    fontFamily: FONT_FAMILY_PRIMARY,
    h1: {
      fontFamily: FONT_FAMILY_HEADING,
      fontSize: '48px',
      lineHeight: '52px',
      letterSpacing: "0px",
      fontWeight: 600,
      color: "#2B2B2B",
    },
    h2: {
      fontFamily: FONT_FAMILY_HEADING,
      fontSize: '38px',
      lineHeight: '46px',
      letterSpacing: "0px",
      fontWeight: 700,
      color: "#2B2B2B",
    },
    h3: {
      fontSize: '24px',
      fontFamily: FONT_FAMILY_PRIMARY,
      color: theme.heading,
      fontWeight: 500,
      lineHeight: '31.68px',
    },
    h4: {
      fontSize: '22px',
      lineHeight: '29px',
      fontFamily: FONT_FAMILY_PRIMARY,
      color: theme.heading,
      fontWeight: 500
    },
    h5: {
      fontSize: '18px',
      lineHeight: '26.46px',
      fontFamily: FONT_FAMILY_PRIMARY,
      color: theme.heading,
      fontWeight: 500,
      letterSpacing: 0,
    },
    h6: {
      fontWeight: 700,
      lineHeight: '26.46px',
      fontSize: '14px',
      fontFamily: FONT_FAMILY_PRIMARY,
      color: theme.heading,

    },
    tabsHeading :{
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "17px",
      letterSpacing: "0.04em"
    },
    body1: {
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "16px",
      fontWeight: 300,
      lineHeight: "19.84px",
      letterSpacing: "0px"
    },
    body2: {
      letterSpacing: '0em',
      fontWeight: 400,
      fontFamily: FONT_FAMILY_PRIMARY,
      lineHeight: '1.5em',
      color: theme.darkTextPrimary
    },
    subtitle1: {
      fontSize: '16px',
      fontFamily: FONT_FAMILY_PRIMARY,
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: 0,
      color: "#626364"
    },
    subtitle2: {
      fontFamily: FONT_FAMILY_PRIMARY,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "17px",
      letterSpacing: "0px",
    },
    caption: {
      fontSize: '0.75rem',
      fontFamily: FONT_FAMILY_PRIMARY,
      color: theme.darkTextSecondary,
      fontWeight: 400
    },

    button: {
      textTransform: 'capitalize'
    },
    customInput: {
      marginTop: 1,
      marginBottom: 1,
      '& > label': {
        top: 23,
        left: 0,
        color: theme.grey500,
        '&[data-shrink="false"]': {
          top: 5
        }
      },
      '& > div > input': {
        padding: '30.5px 14px 11.5px !important'
      },
      '& legend': {
        display: 'none'
      },
      '& fieldset': {
        top: 0
      }
    },
    mainContent: {
      backgroundColor: theme.background,
      width: '100%',
      minHeight: 'calc(100vh - 88px)',
      flexGrow: 1,
      padding: '20px',
      marginTop: '88px',
      marginRight: '20px',
      borderRadius: `${theme?.customization?.borderRadius}px`
    },
    menuCaption: {
      fontSize: '0.875rem',
      fontWeight: 500,
      color: theme.heading,
      padding: '6px',
      textTransform: 'capitalize',
      marginTop: '10px'
    },
    subMenuCaption: {
      fontSize: '0.6875rem',
      fontWeight: 500,
      color: theme.darkTextSecondary,
      textTransform: 'capitalize'
    },
    commonAvatar: {
      cursor: 'pointer',
      borderRadius: '8px'
    },
    smallAvatar: {
      width: '22px',
      height: '22px',
      fontSize: '1rem'
    },
    mediumAvatar: {
      width: '34px',
      height: '34px',
      fontSize: '1.2rem'
    },
    largeAvatar: {
      width: '44px',
      height: '44px',
      fontSize: '1.5rem'
    }
  };
}
