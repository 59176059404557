import React, { lazy } from "react";
import { AppBar, Box, CssBaseline, Grid, useMediaQuery} from "@mui/material";
import Header from "../../component/Header";
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';


import { useLocation } from "react-router-dom";
import Loadable from "utilities/Loadable";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useJoyride } from "utilities/JoyrideProvider";


const GlossaryPage = Loadable(lazy(() => import("views/pages/GlossaryPage")));
const HelpPage = Loadable(lazy(() => import("views/pages/HelpPage")));
const MobileGlossaryPage = Loadable(lazy(() => import("views/pages/GlossaryPage/mobile")));
const MobileHelpPage = Loadable(lazy(() => import("views/pages/HelpPage/mobile")));

const PageLayout = ({ children }) => {
  const location = useLocation();
  const theme = useTheme();
    // Media query for mobile devices
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [openDrawer, setOpenDrawer] = useState(false);
  const { setStepReadyState } = useJoyride();


  const OpenHelpPageDrawer = (open) => {
    setOpenDrawer(open);

  }
 
  const renderPageContent = () => {
    switch (location.pathname) {
      case "/pages/glossary":
        return !isSmall ? <GlossaryPage/> : <MobileGlossaryPage/>;
      case "/pages/glossary/":
          return !isSmall ? <GlossaryPage/> : <MobileGlossaryPage/>;
      case "/pages/help":
        return !isSmall ? <HelpPage />  : <MobileHelpPage OpenHelpPageDrawer={OpenHelpPageDrawer} openDrawer={openDrawer}/>;
      default:
        return null;
    }
  };

  const triggerTour = () => {
    localStorage.removeItem("selectedProject");
    setStepReadyState(true); // Notify Joyride that the step is ready
  }

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{
            bgcolor: theme.palette.background.default,
            borderBottom: '1.5px solid #164A1A',
            background: '#F9F5EB',
            padding: 0,
            top: 0,
            height: '56px',
            width: "100%",

          }}
        >
          <Header triggerTour={triggerTour} />
        </AppBar>
        {
          location.pathname === '/pages/help' && isSmall && 
       
            <div
            onClick={() => setOpenDrawer(!openDrawer)}
            style={{
              background: "#F9F5EB",
              position: "fixed",
              transform: "translateY(-50%)",
              top: "70%",
              zIndex: 9999,
              padding: "1rem",
              borderTopRightRadius: "4px",
              borderBottomRightRadius: "4px",
            }}
          >
            {
              openDrawer ? 
              <KeyboardArrowLeftRoundedIcon/>
              : 
              <KeyboardArrowRightRoundedIcon />
            }
          </div>
        }
    </Box>
      <main>
        <Grid

          sx={{
            overflow: "scroll",
            overflowX: "hidden",
            height: "100vh",
            [theme.breakpoints.up("xl")]: {
              overflow: "scroll",
              overflowX: "hidden",
              height: "100vh",
            },
            [theme.breakpoints.down("md")]: {
              overflowY: "scroll",
              height: "100vh",
              width: "auto"
            },
            [theme.breakpoints.down("xs")]: {
              overflowY: "scroll",
              height: "100vh",
              width: "auto"
            },
          }}
        >
          {renderPageContent()}
        </Grid>
      </main>
      {children}
    </div>
  );
};
export default PageLayout;
