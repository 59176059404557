import PropTypes from 'prop-types';
import { Grid, Typography, IconButton } from '@mui/material';

import ChartCard from 'ui-component/cards/ChartCard';
import { gridSpacing } from 'store/constant';
import info from '../assets/images/icons/info.svg'
import share from 'assets/images/icons/Share.svg'

const ChartLayout = ({ chart, title }) => {
  return (
    <>
      <ChartCard>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography variant="h5" sx={{ color: "#2B2B2B"}}>{title}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item display="none" alignItems="center">
                <IconButton><img src={share} alt="share" /></IconButton>
                <IconButton><img src={info} alt="info" /></IconButton>
              </Grid>
            </Grid>
          </Grid>
          {chart}
        </Grid>
      </ChartCard>

    </>
  );
};

ChartLayout.propTypes = {
  isLoading: PropTypes.bool,
  chart: PropTypes.node
};

export default ChartLayout;