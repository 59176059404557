import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer, { drawerClasses } from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import MenuContent from './Menu';
import { useMediaQuery, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer)({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: 'border-box',
  [`& .${drawerClasses.paper}`]: {
    width: drawerWidth,
    boxSizing: 'border-box',
  },
});

const SideMenu = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      {isMobile && (
        <IconButton
          onClick={toggleDrawer}
          sx={{
            position: 'absolute',
            top: 5,
            left: -5,
            zIndex: 1300, // Ensure the button is on top
          }}
        >
          <ArrowCircleLeftIcon sx={{color: '#164A1A'}}/>
        </IconButton>
      )}
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={open}
        onClose={toggleDrawer}
        sx={{
          display: { xs: 'block', md: 'block' },
          zIndex: 0,
          [`& .${drawerClasses.paper}`]: {
            backgroundColor: '#F2EEE3',
          },
        }}
      >
        <Divider />
        <MenuContent />
      </Drawer>
    </>
  );
}

export default SideMenu;