import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';
import {  gridDrawerWidth} from 'store/constant';



const ProjectGridTablePanel = ({ drawerOpen, window, content }) => {
  const theme = useTheme();
  // Media query for tablet devices
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const container = window !== undefined ? () => window.document.body : undefined;
  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, width: "auto" }} aria-label="mailbox folders">
      <Drawer
        container={container}
        variant={'persistent'}
        anchor="left"
        open={drawerOpen}
        sx={{
          '& .MuiDrawer-paper': {
            width: isMedium ? "calc(3/5 * 100%)" : '550px',
            background: "#F2EEE3!important",
            color: theme.palette.text.primary,
            borderBottom: 'none',
            borderRight: '1px solid #D8D5CC',
            borderLeft: '1px solid #D8D5CC',
            overflow: "hidden",
            top: '57px',
            // height: "calc(100vh - 10.7rem)",
            [theme.breakpoints.up('md')]: {
             top: '57px',
             width: gridDrawerWidth - 30,
              overflow: "hidden",
              /*  zIndex: 1222 */
              /*   zIndex: 1200 */
            },
            [theme.breakpoints.up('lg')]: {
              width: gridDrawerWidth - 30,
            }
          }
        }}
        ModalProps={{ keepMounted: true, hideBackdrop: true, }}
        color="inherit"
      >
        {content}
      </Drawer>
    </Box>
  );
};

ProjectGridTablePanel.propTypes = {
  drawerOpen: PropTypes.bool,
  window: PropTypes.object,
};

export default ProjectGridTablePanel;

