import React, { useState } from 'react';
import {
    Autocomplete,
    TextField,
    styled,
    createTheme,
    ThemeProvider,
} from '@mui/material';

const theme = createTheme({
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                            borderColor: '#164A1A',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#164A1A',
                        },
                    },
                    '& .MuiAutocomplete-input': {
                        color: '#164A1A',
                    },
                    '& .MuiInputBase-root': {
                        backgroundColor: '#F2EEE3',
                        border: '1px solid  #D1CEC5',
                    },
                },
                paper: {
                    backgroundColor: '#F2EEE3',
                    border: '1.5px solid #164A1A',
                    zIndex:13000
                },
            },
        },
    },
});

const StyledAutocomplete = styled(Autocomplete)(({ theme, open }) => ({
    '& .MuiOutlinedInput-root': {
        color: '#164A1A',
        '& fieldset': {
            border: open ? '1.5px solid #164A1A' : '1.5px solid #D1CEC5',
        },
        '&:hover fieldset': {
            borderColor: '#164A1A',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#164A1A',
        },
    },
    '& .MuiAutocomplete-input': {
        color: '#164A1A',
        borderRadius: '4px',
    },
    '& .MuiInputBase-root': {
        backgroundColor: '#F2EEE3'
    },
    '& .MuiAutocomplete-paper': {
        backgroundColor: '#F2EEE3',
        border: '1.5px solid #164A1A',
    },
}));

const SelectFilter = ({ selectData, label, value, onSelect }) => {
    const [open, setOpen] = useState(false);

    const selectList = Object.values(selectData).map((value, index) => ({
        label: `${value}`,
        id: `${index}`
    }));

    const handleChange = (event, newValue) => {
        onSelect(event, newValue);
    };

    return (
        <ThemeProvider theme={theme}>

            <StyledAutocomplete
                options={selectList}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                onChange={handleChange}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
                value={selectList.find(item => item.label === value) || null}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        style={{ borderRadius: '4px' }}
                        label={label}
                        variant="outlined"
                        InputLabelProps={{
                            style: { color: '#2B2B2B' },
                        }}
                    />
                )}
            />
        </ThemeProvider>
    );
};

export default SelectFilter;