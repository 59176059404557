import ForestIcon from '@mui/icons-material/Forest';
import NatureIcon from '@mui/icons-material/Nature';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import AcUnitIcon from '@mui/icons-material/AcUnit';

// theme constant
export const gridSpacing = 3;
export const drawerWidth = 400;
export const appDrawerWidth = 320;
export const gridDrawerWidth = 500;
export const projectDetailWidth = 984;



export const records2 = [
  {
    projectDetails: {
      name: 'Palizada',
      countries: ['Country 1, country 2'],
      region: 'Region',
    },
    client: 'client',
    projectSites: {
      projectsites_count: '4 ',
      area: 'Area Size'

    },
    tags: ["tag 1/label 1", "tag 1/label 1", "tag 1/label 1"],
    netvegetation: {
      icon: <ForestIcon style={{ verticalAlign: 'middle', fontSize: '25px' }} />,
      number: "45",
      percentage: "percent"
    },
    latestCarbon: {
      icon: <NatureIcon style={{ verticalAlign: 'middle', fontSize: '25px' }} />,
      number: "45",
      percentage: "percent"
    },
    numberOfSpecies: {
      icon: <NaturePeopleIcon style={{ verticalAlign: 'middle', fontSize: '25px' }} />,
      number: "45",
      percentage: "percent"
    },
    redList: {
      icon: <AcUnitIcon style={{ verticalAlign: 'middle', fontSize: '25px' }} />,
      number: "45",
      percentage: "percent"
    },
    sdg: [
      { src: "https://via.placeholder.com/30", alt: "placeholder 1" },
      { src: "https://via.placeholder.com/30", alt: "placeholder 2" },
      { src: "https://via.placeholder.com/30", alt: "placeholder 2" },
      { src: "https://via.placeholder.com/30", alt: "placeholder 2" }
    ],
    sites: [
      {
        projectDetails: {
          projectName: 'ProjectSite',
          country: 'Country',
          region: 'Region',
        },
        client: 'client',
        projectSites: {
          projectSites: '4 Project Sites',
          area: 'Area Size'

        },
        tags: ["tag 1/label 1", "tag 1/label 1", "tag 1/label 1"],
        netvegetation: {
          icon: <ForestIcon style={{ verticalAlign: 'middle', fontSize: '25px' }} />,
          number: "45",
          percentage: "percent"
        },
        latestCarbon: {
          icon: <NatureIcon style={{ verticalAlign: 'middle', fontSize: '25px' }} />,
          number: "45",
          percentage: "percent"
        },
        numberOfSpecies: {
          icon: <NaturePeopleIcon style={{ verticalAlign: 'middle', fontSize: '25px' }} />,
          number: "45",
          percentage: "percent"
        },
        redList: {
          icon: <AcUnitIcon style={{ verticalAlign: 'middle', fontSize: '25px' }} />,
          number: "45",
          percentage: "percent"
        },
        sdg: [
          { src: "https://via.placeholder.com/30", alt: "placeholder 1" },
          { src: "https://via.placeholder.com/30", alt: "placeholder 2" },
          { src: "https://via.placeholder.com/30", alt: "placeholder 2" },
          { src: "https://via.placeholder.com/30", alt: "placeholder 2" }
        ],
      }
    ]
  },
  {
    projectDetails: {
      projectName: 'ProjectName',
      country: 'Country',
      region: 'Region',
    },
    client: 'client',
    projectSites: {
      projectSites: '4 Project Sites',
      area: 'Area Size'

    },
    tags: ["tag 1/label 1", "tag 1/label 1", "tag 1/label 1", "tag 1/label 1"],
    netvegetation: {
      icon: <ForestIcon style={{ verticalAlign: 'middle', fontSize: '25px' }} />,
      number: "45",
      percentage: "percent"
    },
    latestCarbon: {
      icon: <NatureIcon style={{ verticalAlign: 'middle', fontSize: '25px' }} />,
      number: "45",
      percentage: "percent"
    },
    numberOfSpecies: {
      icon: <NaturePeopleIcon style={{ verticalAlign: 'middle', fontSize: '25px' }} />,
      number: "45",
      percentage: "percent"
    },
    redList: {
      icon: <AcUnitIcon style={{ verticalAlign: 'middle', fontSize: '25px' }} />,
      number: "45",
      percentage: "percent"
    },
    sdg: [
      { src: "https://via.placeholder.com/30", alt: "placeholder 1" },
      { src: "https://via.placeholder.com/30", alt: "placeholder 2" },
      { src: "https://via.placeholder.com/30", alt: "placeholder 2" },
      { src: "https://via.placeholder.com/30", alt: "placeholder 2" }
    ],
    sites: [
      {
        projectDetails: {
          projectName: 'ProjectSite',
          country: 'Country',
          region: 'Region',
        },
        client: 'client',
        projectSites: {
          projectSites: '4 Project Sites',
          area: 'Area Size'

        },
        tags: ["tag 1/label 1", "tag 1/label 1", "tag 1/label 1"],
        netvegetation: {
          icon: <ForestIcon style={{ verticalAlign: 'middle', fontSize: '25px' }} />,
          number: "45",
          percentage: "percent"
        },
        latestCarbon: {
          icon: <NatureIcon style={{ verticalAlign: 'middle', fontSize: '25px' }} />,
          number: "45",
          percentage: "percent"
        },
        numberOfSpecies: {
          icon: <NaturePeopleIcon style={{ verticalAlign: 'middle', fontSize: '25px' }} />,
          number: "45",
          percentage: "percent"
        },
        redList: {
          icon: <AcUnitIcon style={{ verticalAlign: 'middle', fontSize: '25px' }} />,
          number: "45",
          percentage: "percent"
        },
        sdg: [
          { src: "https://via.placeholder.com/30", alt: "placeholder 1" },
          { src: "https://via.placeholder.com/30", alt: "placeholder 2" },
          { src: "https://via.placeholder.com/30", alt: "placeholder 2" },
          { src: "https://via.placeholder.com/30", alt: "placeholder 2" }
        ],
      }
    ]
  },
];

export const features = [
  'Create a responsive and user-friendly web application.',
  'Utilize Material-UI components for a modern and consistent design.',
  'Implement routing for navigation within the app.',
  'Fetch and display dynamic data from an API.',
  'Ensure proper error handling and validation for a robust user experience.',
];

export const sdgImages = [
  {
    src: 'https://via.placeholder.com/150',
    alt: 'SDG 1',
  },
  {
    src: 'https://via.placeholder.com/150',
    alt: 'SDG 2',
  },
  {
    src: 'https://via.placeholder.com/150',
    alt: 'SDG 3',
  },
];

export const cardData = [
  {
    teaserImage: "https://uploads-ssl.webflow.com/641c27692f22a561b5b3427a/64ef3006ddb0cd20b50de855_biometrio_earth_about_team_outdoors_sensor-640.jpeg",
    name: "Project Name",
    countries:["Country", "country2"],
    tags: ["lorem","ipsum"],
    itemList: [
      { imageUrl: 'https://via.placeholder.com/100x100', text: 'Project site 1', location: 'Some text, location here' },
      { imageUrl: 'https://via.placeholder.com/100x100', text: 'Project site 1', location: 'Some text, location here' },
      { imageUrl: 'https://via.placeholder.com/100x100', text: 'Project site 1', location: 'Some text, location here' },
    ],
  },
  {
    teaserImage: "https://uploads-ssl.webflow.com/641c27692f22a561b5b3427a/64ef3006ddb0cd20b50de855_biometrio_earth_about_team_outdoors_sensor-640.jpeg",
    name: "Sipecam",
    countries:["Country", "country2"],
    tags: ["lorem","ipsum"],
    itemList: [
      { imageUrl: 'https://via.placeholder.com/100x100', text: 'Project site 1', location: 'Some text, location here' },
      { imageUrl: 'https://via.placeholder.com/100x100', text: 'Project site 1', location: 'Some text, location here' },
      { imageUrl: 'https://via.placeholder.com/100x100', text: 'Project site 1', location: 'Some text, location here' },
    ],
  },
  {
    teaserImage: "https://uploads-ssl.webflow.com/641c27692f22a561b5b3427a/64ef3006ddb0cd20b50de855_biometrio_earth_about_team_outdoors_sensor-640.jpeg",
    name: "Indonesia",
    countries:["Country", "country2"],
    tags: ["lorem","ipsum"],
    itemList: [
      { imageUrl: 'https://via.placeholder.com/100x100', text: 'Project site 1', location: 'Some text, location here' },
      { imageUrl: 'https://via.placeholder.com/100x100', text: 'Project site 1', location: 'Some text, location here' },
      { imageUrl: 'https://via.placeholder.com/100x100', text: 'Project site 1', location: 'Some text, location here' },
    ],
  },
  {
    teaserImage: "https://uploads-ssl.webflow.com/641c27692f22a561b5b3427a/64ef3006ddb0cd20b50de855_biometrio_earth_about_team_outdoors_sensor-640.jpeg",
    name: "Test Project 2",
    countries:["Country", "country2"],
    tags: ["lorem","ipsum"],
    itemList: [
      { imageUrl: 'https://via.placeholder.com/100x100', text: 'Project site 1', location: 'Some text, location here' },
      { imageUrl: 'https://via.placeholder.com/100x100', text: 'Project site 1', location: 'Some text, location here' },
      { imageUrl: 'https://via.placeholder.com/100x100', text: 'Project site 1', location: 'Some text, location here' },
    ],
  },
  {
    teaserImage: "https://uploads-ssl.webflow.com/641c27692f22a561b5b3427a/64ef3006ddb0cd20b50de855_biometrio_earth_about_team_outdoors_sensor-640.jpeg",
    name: "xprise",
    countries:["Country", "country2"],
    tags: ["lorem","ipsum"],
    itemList: [
      { imageUrl: 'https://via.placeholder.com/100x100', text: 'Project site 1', location: 'Some text, location here' },
      { imageUrl: 'https://via.placeholder.com/100x100', text: 'Project site 1', location: 'Some text, location here' },
      { imageUrl: 'https://via.placeholder.com/100x100', text: 'Project site 1', location: 'Some text, location here' },
    ],
  }
];
