import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';

const mainListItems = [
  { text: 'Home', icon: <HomeRoundedIcon />, path: '/admin/overview' },
  { text: 'Users', icon: <PeopleRoundedIcon />, path: '/admin/users' },
  // { text: 'Projects', icon: <AnalyticsRoundedIcon />, path: '/admin/projects' },
];

// Custom styles for ListItemButton
const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  color: '#164A1A', // Default color for text and icons
  borderRadius: '10px',
  '& .MuiListItemIcon-root': {
    color: '#164A1A', // Default color for icons
  },
  '& .MuiListItemText-root': {
    color: '#164A1A', // Default color for text
  },
  '&:hover': {
    backgroundColor: '#EAFFD8', // Background color on hover
    color: '#F9F5EB', // Text and icon color on hover
    '& .MuiListItemIcon-root': {
      color: '#164A1A', // Icon color on hover
    },
    '& .MuiListItemText-root': {
      color: '#F9F5EB', // Text color on hover
    },
  },
  '&.Mui-selected': {
    backgroundColor: '#D2FFAB', // Background color when selected
    color: '#F9F5EB', // Text and icon color when selected
    '& .MuiListItemIcon-root': {
      color: '#164A1A', // Icon color when selected
    },
    '& .MuiListItemText-root': {
      color: '#F9F5EB', // Text color when selected
    },
    '&:hover': {
      backgroundColor: '#EAFFD8', // Background color on hover when selected
      color: '#164A1A', // Keep hover text color when selected
      '& .MuiListItemIcon-root': {
        color: '#164A1A', // Icon color on hover when selected
      },
      '& .MuiListItemText-root': {
        color: '#F9F5EB', // Text color on hover when selected
      },
    },
  },
}));

const MenuContent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (route) => {
    navigate(route); // Perform navigation when item is clicked
  };

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
      <List dense sx={{ paddingTop: '70px' }}>
        {mainListItems.map((item, index) => (
          <ListItem 
            key={index} 
            disablePadding 
            sx={{ display: 'block' }}
            onClick={() => handleNavigation(item.path)} // Call handleNavigation on click
          >
            <StyledListItemButton selected={location.pathname === item.path}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </StyledListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

export default MenuContent;