
const TourGuideSteps = [
  {
    content: (
      <><p style={{textAlign: 'left'}}>Through our client facing dashboard we provide insights and measures for any of our service products.</p><p style={{textAlign: 'left'}}>  It allows registered users access to their specific projects and gain insides by metrics, statistics, cartography, multi-media material for their monitored projects to assess and evaluate biodiversity through ecosystem structure and functional data as also species population and community composition measures.</p><p style={{textAlign: 'left'}}>The entry page provides three different view modes: the map view, the grid view and the list view.</p></>
    ),
    hideCloseButton: true,
    disableBeacon: true,
    disableOverlay: false,
    disableOverlayClose: true,
    placement: 'center',
    isFixed: true,
    styles: {
      tooltip: {
        width: 500,
      },
    },
    spotlightClicks: false,  // Keeps spotlight interactive
    spotlightPadding: 0,
    target: 'body',
    title: 'The biometrio.earth nature analytics dashboard entry page',
    prevpath: '/entry/map',
  },
  {
    content: (
      <><p style={{textAlign: 'left'}}>The map view provides a cartographic overview of the client’s projects.</p><p style={{textAlign: 'left'}}>It is divided into the two main components: the left-side project panel and the right-side map, where project locations are provided by map pins, that are colored indicating the different service product types of either b.e in-situ starter, b.e remote, or b.e complete, as also others. </p><p style={{textAlign: 'left'}}>On hovering over a projects map pin, a popup window is shown at the map pin position providing project title, abstract, and product type information.  </p><p style={{textAlign: 'left'}}>On clicking a projects map pin, the project preview panel is shown.</p></>
    ),
    hideCloseButton: true,
    spotlightClicks: false,
    isFixed: true,
    placement: 'bottom',
    spotlightPadding: 0,
    target: '#map-switcher-button',
    title: 'The map view',
    path: '/entry/map',
    prevpath: '/entry/map',
  },
  {
    content: (
       <><p style={{textAlign: 'left'}}>Project cards are provided on the left-side project panel.</p><p style={{textAlign: 'left'}}>Cards provide the projects title, country and client information as also a project teaser image, and eventually project specific thematic tags.</p><p style={{textAlign: 'left'}}>On hovering over a projects card, a popup window is shown at the map pin position providing project title, abstract, and product type information. </p><p style={{textAlign: 'left'}}>On clicking a project card, the project preview panel is shown.</p></>
    ),
    hideCloseButton: true,
    spotlightClicks: false,
    isFixed: true,
    placement: 'right',
    spotlightPadding: 0,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.Help-entrypage-projectpanel',
    title: 'The map view’s project panel',
    path: '/entry/map',
    prevpath: '/entry/map',
  },
  {
    content: (
      <>
      <p style={{textAlign: 'left'}}>Within the grid view, project cards are presented in a grid layout. </p>
      <p style={{textAlign: 'left'}}> Project cards are attached with additional information including project size, number of project sites, 4 core metrics on most recent natural landscape index, total above ground biomass, number of endangered species, and total number of species to be expected in the project region. </p>
      <p style={{textAlign: 'left'}}>On clicking a project card, the project preview panel is shown.</p>
      </>
    ),
    hideCloseButton: true,
    isFixed: true,
    disableScrolling: true,
    disableOverlayClose: true,
    placement: 'bottom',
    spotlightPadding: 0,
    target: '#grid-switcher-button',
    title: 'The grid view',
    path: '/entry/grid',
    prevpath: '/entry/map',
  },
  {
    content: (
      <>
      <p style={{textAlign: 'left'}}>Within the list view, project cards are presented in a list layout.</p>
      <p style={{textAlign: 'left'}}>Project cards are attached with additional information including project size, number of project sites, 4 core metrics on most recent natural landscape index, total above ground biomass, number of endangered species, and total number of species to be expected in the project region, and a maximum of four sustainable development goals the project is related to. </p>
      <p style={{textAlign: 'left'}}>On clicking a project card, the project preview panel is shown.</p>
      </>
    ),
    hideCloseButton: true,
    isFixed: true,
    disableScrolling: true,
    disableOverlayClose: true,
    placement: 'bottom',
    spotlightPadding: 0,
    target: '#list-switcher-button',
    title: 'The list view',
    path: '/entry/table',
    prevpath: '/entry/grid',
  },
  {
    content: (
      <>
      <p>
        Click on the filter button to view the search and filter toolbar.
      </p>
      </>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    isFixed: true,
    placement: 'left',
    spotlightPadding: 0,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.Help-entrypage-filter-1',
    title: 'Filter Button',
    path: '/entry/table',
    prevpath: '/entry/table',
  },
  {
    content: (
      <>
      <p style={{textAlign: 'left'}}>
        The search and filtering toolbar allows for free text search or categorical search for projects amongst the provided filter categories. 
      </p>
      <p style={{textAlign: 'left'}}> Filters are applied by clicking the respective button. </p>
      <p style={{textAlign: 'left'}}>Likewise, all filters can be reset by clicking the respective clear filters button.</p>
      </>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    spotlightClicks: false,
    disableScrolling: true,
    isFixed: true,
    placement: 'right',
    spotlightPadding: 0,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.Help-entrypage-filter',
    title: 'Filtering and searching for projects',
    path: '/entry/table',
    prevpath: '/entry/table',
  },
  {
    content: (
      <>
      <p>
        Click on the bookmark button to view the bookedmarked projects.
      </p>
      </>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    disableScrolling: true,
    isFixed: true,
    placement: 'bottom',
    spotlightPadding: 5,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: '.Help-entrypage-bookmarks-1',
    title: 'Bookmark Button',
    path: '/entry/table',
    prevpath: '/entry/table',
  },
  {
    content: (
      <>
      <p style={{textAlign: 'left'}}>
        Individual projects can be bookmarked by clicking the respective icon in each of the three entry page representations project card. 
      </p>
      <p style={{textAlign: 'left'}}> The limited set of bookmarked projects can be seen by activating the bookmark icon the search and filtering toolbar.</p>
      </>
    ),
    hideCloseButton: true,
    disableScrolling: true,
    disableOverlayClose: true,
    disableScrolling: true,
    placement: 'bottom',
    spotlightPadding: 10,
    target: '#projects-table-container',
    title: 'Bookmarking of projects',
    path: '/entry/table',
    prevpath: '/entry/table',
  },
  {
    content: (
      <>
      <p>
        Click on a project to view the project preview.
      </p>
      </>
    ),
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,  
    isFixed: true,
    placement: 'top',
    spotlightPadding: 0,
    target: '.Help-entrypage-preview-1',
    title: 'Project Preview',
    path: '/entry/table',
    prevpath: '/entry/table',
  },
  {
    content: (
      <>
      <p style={{textAlign: 'left'}}>
       A project preview is available through the project cards of the entry page in whatever view (map, grid, list). 
      </p>
      <p style={{textAlign: 'left'}}> Project preview is opened on either clicking the project card or the respective map pin. </p>
      <p style={{textAlign: 'left'}}>
         The project preview is opened as pop up window, aligned to the page layout. 
      </p>
      <p style={{textAlign: 'left'}}> In map view, the map will automatically zoom to the project extent and shows the actual project boundary. 
      </p>
      <div style={{textAlign: 'left'}}> 
      <p style={{textAlign: 'left'}}>
      Depending on the service product the content of the preview varies 
      </p>
      <ul>
        <li>
          For b.e diagnostix and b.e remote projects the preview contains additional information including project size, number of project sites, 4 core metrics on most recent natural landscape index, total above ground biomass, number of endangered species, and total number of species to be expected in the project region, and the sustainable development goals the project is related to. 
        </li>
        <li>
          For b.e in-situ starter and b.e complete curated media shall be available, i.e audio snippets from field recordings, foto and video footages of detected species by in-situ sensors. 
        </li>
      </ul>
      </div>
      </>
    ),
    //disableBeacon: true,
    // disableOverlayClose: true,
    hideFooter: false,
    hideCloseButton: true,
    spotlightClicks: false,  
    placement: 'left',
    spotlightPadding: 0,
    styles: {
      options: {
        zIndex: 10000,
      },
      tooltip: {
        maxHeight: '800px', // Set a max height for the tooltip
        width: 900,
        overflowY: 'auto',  // Enable vertical scrolling if content exceeds max height
        padding: '10px',    // Adjust padding if necessary
      },
    },
    target: '.Help-entrypage-preview',
    title: 'Project preview',
    path: '/entry/table',
    prevpath: '/entry/table',
  },
  {
    content: (
      <div>
        <p style={{textAlign: 'left'}}>The project preview provides the button that links to the detailed project page. 
        </p>
        <p style={{textAlign: 'left'}}>Click on the "View project" to navigate to the detailed project page. 
        </p>
      </div>
    ), 
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,  
    placement: 'left',
    spotlightPadding: 5,
    target: '#view-project-button',
    title: 'Project preview',
    path: '/entry/table',
    prevpath: '/entry/table',
  },
  {
    content: (
      <>
      <p style={{textAlign: 'left'}}>
      The project page extents the level of information detail as provided in the project preview. 
      </p>
      <div>
        <p style={{textAlign: 'left'}}>
          The project page is structured into 3 main components 
        </p>
        <ul>
          <li style={{textAlign: 'left'}}>General project information</li>
          <li style={{textAlign: 'left'}}>A map component </li>
          <li style={{textAlign: 'left'}}>A statistics component providing structured thematic (essential biodiversity variables) tabs </li>
        </ul>
      </div>
      </>
    ),
    hideCloseButton: true,
    isFixed: true,
    disableScrolling: true,
    disableOverlayClose: true,
    placement: 'center',
    spotlightPadding: 40,
    target: 'body',
    title: 'The project page ',
    path: `/project/projectdetail/e23aaef0-32fa-44cc-8573-58bc376caaa4`,
    prevpath: `/project/projectdetail/e23aaef0-32fa-44cc-8573-58bc376caaa4`,
  },
  {
    content: (
      <>
      <p style={{textAlign: 'left'}}>
      The general project information provides insights into the actual project monitoring results.
      </p>
      <div>
        <p style={{textAlign: 'left'}}>
        These include: 
        </p>
        <ul>
          <li style={{textAlign: 'left'}}>Project title, country, client and project type information</li>
          <li style={{textAlign: 'left'}}>Project abstract, size, year of evolvement, number of project sites </li>
          <li style={{textAlign: 'left'}}>Sustainable development goals related to the project goals</li>
          <li style={{textAlign: 'left'}}>Curated audio records from field recordings </li>
          <li style={{textAlign: 'left'}}>Curated image and video footages from field recordings  </li>
          <li style={{textAlign: 'left'}}>Preview cards of all project sites within the project, that will link to the project site page  </li>
          
        </ul>
      </div>
      </>
    ),
    hideCloseButton: true,
    isFixed: true,
    disableScrolling: true,
    disableOverlayClose: true,
    scrollToFirstStep: false, // Enable scroll to the first step
    placement: 'right',
    spotlightPadding: 10,
    target: '.Help-projectpage-general',
    title: 'Project details',
    path: `/project/projectdetail/e23aaef0-32fa-44cc-8573-58bc376caaa4`,
    prevpath: `/project/projectdetail/e23aaef0-32fa-44cc-8573-58bc376caaa4`,
  },
  {
    content: (
      <>
      <p style={{textAlign: 'left'}}>The map component delivers cartographic representations of recent remote sensing based monitoring datasets as also the location of in-situ sensors.  The general project information provides insights into the actual project monitoring results.
      </p>
      <p style={{textAlign: 'left'}}>
      It basically features a preview of the project and project site boundaries, standard interactive map navigation tools and full screen mode, and the possibility to switch background layers between topographic and satellite maps. 
        </p>
        <p style={{textAlign: 'left'}}>
        Further thematic map content is dynamic and changes with the actual product type. While b.e Diagnostix projects feature public data map layers on above ground biomass, tree cover loss, and land cover, the b.e remote and complete projects provide most recent data layers from in-house processed annual land cover, canopy height, above ground biomass and forest fragmentation products.  
        </p>
      
      </>
    ),
    hideCloseButton: true,
    isFixed: true,
    disableScrolling: false,
    disableOverlayClose: true,
    scrollToFirstStep: false, // Enable scroll to the first step
    placement: 'left',
    spotlightPadding: 10,
    target: '.Help-projectpage-map',
    title: 'Project map component',
    path: `/project/projectdetail/e23aaef0-32fa-44cc-8573-58bc376caaa4`,
    prevpath: `/project/projectdetail/e23aaef0-32fa-44cc-8573-58bc376caaa4`,
  },
  {
    content: (
      <>
      <p style={{textAlign: 'left'}}>
        The project page highlights biodiversity monitoring metrics and indicators for the overall project area. In case of multiple project sites, these are aggregated at the project level. The biodiversity indicators provided follow the concept of the Essential Biodiversity Variables and with that are provided through the thematic tabs: Ecosystem Structure, Ecosystem Function, Species population, and Community composition. In addition, we deliver information on Species traits as also summarized project statistics on monitoring periods and in-situ sensors. 
      </p>
      </>
    ),
    hideCloseButton: true,
    isFixed: true,
    disableScrolling: true,
    disableOverlayClose: true,
    scrollToFirstStep: false, // Enable scroll to the first step
    placement: 'left',
    spotlightPadding: 10,
    target: '.Help-projectpage-tabs',
    title: 'Project page tabs',
    path: `/project/projectdetail/e23aaef0-32fa-44cc-8573-58bc376caaa4`,
    prevpath: `/project/projectdetail/e23aaef0-32fa-44cc-8573-58bc376caaa4`,
  },
  {
    content: (
      <>
      <p style={{textAlign: 'left'}}>
      This tab is dedicated to the spatial arrangement of ecosystem units collectively defined by the organisms forming these units, and it includes metrics and indicators such as the latest natural landscape index, forest landscape index, active fire occurrences, and tree cover loss. Additionally, it features a land cover class distribution donut diagram, a line diagram showing land cover loss, gain, and net change for any given land cover class, a bar chart of annual tree cover loss, and a bar chart of the annual number of active fires. 
      </p>
      </>
    ),
    hideCloseButton: true,
    isFixed: true,
    disableScrolling: true,
    disableOverlayClose: true,
    scrollToFirstStep: false, // Enable scroll to the first step
    spotlightClicks: false,
    placement: 'bottom',
    spotlightPadding: 5,
    target: '#ecosystemstructure',
    //target: '.Help-projectpage-ecosystemstructure, .Help-projectpage-ecosystemstructure-content',
    title: 'Ecosystem structure tab',
    path: `/project/projectdetail/e23aaef0-32fa-44cc-8573-58bc376caaa4`,
    prevpath: `/project/projectdetail/e23aaef0-32fa-44cc-8573-58bc376caaa4`,
  },
  {
    content: (
      <>
      <p style={{textAlign: 'left'}}>
      This tab is dedicated to attributes related to the performance of ecosystems resulting from the collective activities of its organisms, and it includes metrics and indicators such as the latest above-ground biomass stock and density, green above-ground biomass stock and density, and blue above-ground biomass stock and density. Additionally, it features a line diagram of annual above-ground biomass, a line diagram showing above-ground biomass loss, gain, and net change, as well as a line diagram of annual above-ground biomass density. 
      </p>
      </>
    ),
    hideCloseButton: true,
    isFixed: true,
    disableScrolling: true,
    disableOverlayClose: true,
    scrollToFirstStep: false, // Enable scroll to the first step
    spotlightClicks: false,
    placement: 'bottom',
    spotlightPadding: 5,
    target: '#ecosystemfunction',
    //target: '.Help-projectpage-ecosystemfunctioning, .Help-projectpage-ecosystemfunctioning-content',
    title: 'Ecosystem function tab',
    path: `/project/projectdetail/e23aaef0-32fa-44cc-8573-58bc376caaa4`,
    prevpath: `/project/projectdetail/e23aaef0-32fa-44cc-8573-58bc376caaa4`,
  },
  {
    content: (
      <>
      <p style={{textAlign: 'left'}}>
      This tab is dedicated to the spatial and temporal variability in the distribution and abundance of species populations, including metrics on the number of species richness and their IUCN threatened species status. It also features a complete list of species detected through audio recorders or by image vision from camera trap images and videos.
      </p>
      </>
    ),
    hideCloseButton: true,
    isFixed: true,
    disableScrolling: true,
    disableOverlayClose: true,
    scrollToFirstStep: false, // Enable scroll to the first step
    spotlightClicks: false,
    placement: 'bottom',
    spotlightPadding: 5,
    target: '#speciespopulation',
    //target: '.Help-projectpage-speciespopulation, .Help-projectpage-speciespopulation-content',
    title: 'Species population tab',
    path: `/project/projectdetail/e23aaef0-32fa-44cc-8573-58bc376caaa4`,
    prevpath: `/project/projectdetail/e23aaef0-32fa-44cc-8573-58bc376caaa4`,
  },
  {
    content: (
      <>
      <p style={{textAlign: 'left'}}>
      This tab is dedicated to the abundance and diversity of organisms that make up ecosystems, including metrics on the species richness of mammals and birds, whether detected, historically reported, or modeled. It also includes metrics and diagrams on the number of bird, mammal, bat, and amphibian species detected and expected, along with various metrics and diagrams on community composition by family or species order. 
      </p>
      </>
    ),
    hideCloseButton: true,
    isFixed: true,
    disableScrolling: true,
    disableOverlayClose: true,
    placement: 'bottom',
    spotlightPadding: 5,
    target: '#communitycomposition',
    //target: '.Help-projectpage-communitycomposition, .Help-projectpage-communitycomposition-content',
    title: 'Community composition tab',
    path: `/project/projectdetail/e23aaef0-32fa-44cc-8573-58bc376caaa4`,
    prevpath: `/project/projectdetail/e23aaef0-32fa-44cc-8573-58bc376caaa4`,
  },
  {
    content: (
      <>
      <p style={{textAlign: 'left'}}>
      This tab is dedicated to within-species variation in trait measurements along the axis of taxonomic diversity, featuring metrics and line charts on derived and aggregated acoustic indices, animal activities over daytime, the number of introduced and invasive species, trophic guild distribution, and diet distribution of species in the project area, as well as body mass and body size distributions.
      </p>
      </>
    ),
    hideCloseButton: true,
    isFixed: true,
    disableScrolling: true,
    disableOverlayClose: true,
    scrollToFirstStep: false, // Enable scroll to the first step
    spotlightClicks: false,
    placement: 'bottom',
    spotlightPadding: 5,
    target: '#speciestraits',
    //target: '.Help-projectpage-speciestraits, .Help-projectpage-speciestraits-content',
    title: 'Species traits tab',
    path: `/project/projectdetail/e23aaef0-32fa-44cc-8573-58bc376caaa4`,
    prevpath: `/project/projectdetail/e23aaef0-32fa-44cc-8573-58bc376caaa4`,
  },
  {
    content: (
      <>
      <p style={{textAlign: 'left'}}>
      This section provides comprehensive metrics offering insights into the number of monitoring days using field sensors, the total number of field sensors and devices deployed, and the quantity of audio and image/video files recorded. 
      </p>
      </>
    ),
    hideCloseButton: true,
    isFixed: true,
    disableScrolling: true,
    disableOverlayClose: true,
    spotlightClicks: false,
    placement: 'bottom',
    spotlightPadding: 5,
    target: '#projectstatistics',
    //target: '.Help-projectpage-projectstatistics, .Help-projectpage-projectstatistics-content',
    title: 'Project statistics tab',
    path: `/project/projectdetail/e23aaef0-32fa-44cc-8573-58bc376caaa4`,
    prevpath: `/project/projectdetail/e23aaef0-32fa-44cc-8573-58bc376caaa4`,
  }
];

export { TourGuideSteps };