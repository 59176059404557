import React from 'react';
import { styled, alpha, useTheme } from '@mui/material/styles';
import {
  useMediaQuery
} from "@mui/material";
import InputBase from '@mui/material/InputBase';
import ClearIcon from '@mui/icons-material/Clear';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import './index.css';

const SearchContainer = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: '10px',
  marginLeft: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledInputBase = styled(InputBase, { 
  shouldForwardProp: (prop) => prop !== 'showBackButton' && prop !== 'isMedium' })(({ theme, showBackButton, isMedium }) => ({
  color: '#626364 !important',
  fontFamily: "MADE TOMMY",
  fontWeight: 400,
  backgroundColor: '#F2EEE3 !important',
  '& .MuiInputBase-input': {
    paddingTop: '15px',
    minWidth: isMedium ? "unset" : showBackButton ? '150px' : '200px',
    paddingBottom: '15px',
    paddingLeft: '20px',
    paddingRight: '20px',
    transition: theme.transitions.create('width'),
    width: '100%',
    fontWeight: 400,
    fontFamily: "MADE TOMMY",
    color: '#626364 !important',
    border: `1.5px solid #D1CEC5`,
    borderRadius: theme.shape.borderRadius,
  },
}));

function SideBarSearchMenu({
  searchTerm,
  handleSearchChange,
  handleBackButtonClick,
  onSuggestionClick,
  highlightMatch,
  suggestions,
  showBackButton,
}) {

  const theme = useTheme();
  // Media query for tablet devices
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  // const renderHighlightedText = (suggestion) => {
  //   const nameParts = suggestion.name.split(new RegExp(`(${searchTerm})`, 'gi'));
  //   const clientParts = suggestion.client ? suggestion.client.split(new RegExp(`(${searchTerm})`, 'gi')) : [];
  //   const beProductParts = suggestion.be_product ? suggestion.be_product.split(new RegExp(`(${searchTerm})`, 'gi')) : [];
  //   const tagParts = suggestion.tags.flatMap(tag => tag.split(new RegExp(`(${searchTerm})`, 'gi')));

  //   const parts = [
  //     ...nameParts.map((part, index) => ({
  //       text: part,
  //       highlighted: index % 2 === 1
  //     })),
  //     ...clientParts.map((part, index) => ({
  //       text: part,
  //       highlighted: index % 2 === 1
  //     })),
  //     ...beProductParts.map((part, index) => ({
  //       text: part,
  //       highlighted: index % 2 === 1
  //     })),
  //     ...tagParts.map((part, index) => ({
  //       text: part,
  //       highlighted: index % 2 === 1
  //     })),
  //   ];

  //   return (
  //     <>
  //       {parts.map((part, index) => (
  //         <span key={index} style={{ backgroundColor: part.highlighted ? 'yellow' : 'transparent' }}>
  //           {part.text}
  //         </span>
  //       ))}
  //     </>
  //   );
  // };
  return (
    <SearchContainer>
      <Search>
        <StyledInputBase
          placeholder={searchTerm ? searchTerm : "Search…"}
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleSearchChange}
          value={searchTerm}
          showBackButton={showBackButton}
          isMedium={isMedium}
        />

        {/* Suggestions Dropdown */}
        {suggestions.length > 0 && (
          <List
            sx={{
              position: 'absolute',
              top: '100%',
              left: 0,
              zIndex: 1,
              display: 'none',
              backgroundColor: '#F9F5EB',
              color: '#164A1A',
              fontFamily: "'MADE TOMMY', sans-serif",
              fontSize: "14px",
              fontWeight: 400,
              width: '100%',
              overflowY: 'auto',
              height: '300px'
            }}
            aria-label="suggestions-dropdown"
          >
            {suggestions.map((suggest, index) => (
              <React.Fragment key={index}>
                {suggest.name &&
                  <ListItemButton onClick={() => onSuggestionClick(suggest.identifier)}>
                    <ListItem key={index} sx={{ width: '100%' }}>
                      <span style={{
                        color: '#164A1A', fontFamily: "'MADE TOMMY', sans-serif",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}>
                        {highlightMatch(suggest.name, searchTerm)}

                      </span>

                    </ListItem>
                  </ListItemButton>
                }
                {suggest.client &&
                  <ListItemButton onClick={() => onSuggestionClick(suggest.identifier)}>
                    <ListItem key={index} sx={{ width: '100%' }}>
                      <span style={{
                        color: '#164A1A', fontFamily: "'MADE TOMMY', sans-serif",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}>
                        {highlightMatch(suggest.client, searchTerm)}

                      </span>

                    </ListItem>
                  </ListItemButton>
                }
                {suggest.tags.length > 0 &&
                  <ListItemButton onClick={() => onSuggestionClick(suggest.identifier)}>
                    <ListItem key={index} sx={{ width: '100%' }}>
                      <span style={{
                        color: '#164A1A', fontFamily: "'MADE TOMMY', sans-serif",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}>
                        {highlightMatch(suggest.tags.join(', '), searchTerm)}

                      </span>

                    </ListItem>
                  </ListItemButton>
                }
                {suggest.be_product !== null &&
                  <ListItemButton onClick={() => onSuggestionClick(suggest.identifier)}>
                    <ListItem key={index} sx={{ width: '100%' }}>
                      <span style={{
                        color: '#164A1A', fontFamily: "'MADE TOMMY', sans-serif",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}>
                        {highlightMatch(suggest.be_product, searchTerm)}


                      </span>

                    </ListItem>
                  </ListItemButton>
                }

              </React.Fragment>
            ))}
          </List>
        )}

        {
          searchTerm &&

          <ClearIcon onClick={handleBackButtonClick} style={{ cursor: 'pointer', position: 'absolute', right: '2rem', top: '1.2rem', height: '16px', fill: '#164A1A' }} />
        }

        <span style={{ position: 'absolute', right: '1rem', top: '1.2rem' }}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="#164A1A" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.46941 10.511C8.56427 11.2091 7.42975 11.6244 6.19824 11.6244C3.23753 11.6244 0.837402 9.22426 0.837402 6.26355C0.837402 3.30284 3.23753 0.90271 6.19824 0.90271C9.15895 0.90271 11.5591 3.30284 11.5591 6.26355C11.5591 7.45148 11.1727 8.54917 10.5187 9.43779L15.1774 14.3294L14.0912 15.3639L9.46941 10.511ZM10.0591 6.26355C10.0591 8.39583 8.33052 10.1244 6.19824 10.1244C4.06596 10.1244 2.3374 8.39583 2.3374 6.26355C2.3374 4.13127 4.06596 2.40271 6.19824 2.40271C8.33052 2.40271 10.0591 4.13127 10.0591 6.26355Z" fill="#164A1A" />
          </svg>
        </span>
      </Search>
    </SearchContainer>
  );
}

export default SideBarSearchMenu;
