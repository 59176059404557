import React, {useEffect} from "react";
import { Box, Grid, styled, Typography, useMediaQuery } from "@mui/material";
import Cookies from 'js-cookie';
import { useTheme } from "@emotion/react";
import MessageMobile from "./MessageMobile";
import InfoDataBox from "./InfoDataBox";
import ChartLayout from "utilities/ChartLayout";
import CustomChart from "utilities/CustomChart";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useJoyride } from "utilities/JoyrideProvider";

const BoxContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "theme",
})(({ theme }) => ({
  margin: "auto",
  marginTop: "2rem",
  marginBottom: "2rem",
  width: "90%",
  [theme.breakpoints.down("sm")]: {
    width: "327px",
  },
}));

function ProjectTab({ tabData, isLoadingTabs, loading, compactProjectDetail }) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { setStepReadyState, joyrideState, stepReady } = useJoyride();
  const tour_completed = Cookies.get('tour_completed');
  
  const transformData = (data) => {
    const grouped = data.reduce((acc, item) => {
      const icon = item.data.resources.access_url;
      const groupTitle = item.data.resources.caption_text
        .replace(" density", "")
        .replace(" stock", ""); // e.g., "Biomass", "Green Biomass", "Blue Biomass"

      if (!acc[icon]) {
        acc[icon] = {
          type: "metrics_group",
          text: "blabla",
          title: groupTitle,
          icon: icon,
          metrics: [],
        };
      }

      acc[icon].metrics.push(item.data);

      return acc;
    }, {});

    return Object.values(grouped);
  };

  const extractSingleItemArrays = (arr) => {
    return arr.map((item) => {
      if (Array.isArray(item) && item.length === 1) {
        return item[0];
      }
      return item;
    });
  };

  const transformChartData = (data) => {
    const grouped = data.reduce((acc, item) => {
      const chartGroup = item.group_name;
      if (chartGroup === null) {
        acc[Math.random()] = item;
      } else if (!acc[chartGroup]) {
        acc[chartGroup] = [item];
      } else {
        acc[chartGroup].push(item);
      }

      return acc;
    }, []);
    return extractSingleItemArrays(Object.values(grouped)).sort((a, b) => {
      let nameA, nameB;

      if (Array.isArray(a)) {
        nameA = a[0]?.type ?? "";
      } else if (typeof a === "object" && a !== null) {
        nameA = a.type ?? "";
      }

      if (Array.isArray(b)) {
        nameB = b[0]?.type ?? "";
      } else if (typeof b === "object" && b !== null) {
        nameB = b.type ?? "";
      }

      if (nameA < nameB) {
        return 1;
      } else if (nameA > nameB) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  let metrics;
  let metricsType = tabData?.content?.metrics
    .map((m) => m.type)
    .reduce((acc, item) => item, "");
  if (
    tabData.name === "ecosystem function" &&
    typeof metricsType === "string" &&
    metricsType === "metric"
  ) {
    metrics = transformData(tabData?.content?.metrics);
  } else {
    metrics = tabData?.content?.metrics;
  }

  let charts = transformChartData(tabData.content?.charts);

  useEffect(() => {
    // Simulate data loading
    setStepReadyState(false);
    setTimeout(() => {
      if(!isLoadingTabs && !loading && tour_completed && stepReady) {
       // handleProjectPreviewClose();
       setStepReadyState(true); // Notify Joyride that the step is ready
      }
    }, 1000); // Adjust this delay to simulate your loading time
  }, [setStepReadyState]);

  return (
    <BoxContainer theme={theme}>
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        flexDirection="column"
      >
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          mb={3}
        >
          {metrics.map((data, idx) => (
            <InfoDataBox
              key={idx}
              infoindex={idx}
              infoData={data}
              infoName={tabData.name}
              compactProjectDetail={compactProjectDetail}
              forceGreenText={tabData.name === "ecosystem function"}
            />
          ))}

          {!isSmall ? (
            <>
              {charts.map((chartData, idx) => (
                <Grid
                  key={idx}
                  item
                  sx={{ margin: "0.5rem 0rem", width: "100%" }}
                >
                  <ChartLayout
                    border={false}
                    chart={<CustomChart chartData={chartData} />}
                    title={chartData?.title}
                  />
                </Grid>
              ))}
            </>
          ) : (
            <MessageMobile />
          )}

          {(tabData.content?.tables && tabData.content?.tables.length > 0) && (
            <>
             {tabData.content?.tables.map((table,idx) => (
              <div key={idx} style={{ width: '100%' }}>
                <Typography variant="h4" sx={{ color: "#2B2B2B", margin: "1rem 0rem"}}>{table.title}</Typography>
                <div style={{ width: '100%' }}>
                  <DataGrid 
                    rows={table.rows}
                    columns={table.columns.map((r) => { return { ...r, flex: 1 }})}
                    autoHeight
                    initialState={{
                      density: 'compact',
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[10,50,100]}
                    slots={{ toolbar: GridToolbar }}
                    sx={{
                      '& .MuiDataGrid-container--top [role=row]': {
                        background: "#e9e5da"
                      }
                    }}/>
                </div>
              </div>
             ))}
            </>
          )}
        </Grid>
      </Grid>
    </BoxContainer>
  );
}

ProjectTab.propTypes = {};

export default ProjectTab;
