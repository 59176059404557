import { createRoot } from "react-dom/client";

// third party
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

// sentry monitoring
import * as Sentry from "@sentry/react";

// Google analytics
import ReactGA from "react-ga4";

import App from "App";
import { store } from "store";
import "assets/scss/style.scss";
import { config } from "./config";
import { Auth0Provider } from "@auth0/auth0-react";

const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
const audience = process.env.REACT_APP_AUTH0AUTHORIZE;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const ENV = process.env.REACT_APP_ENV;

// GA tracking id
const GA_TRACKING_ID = process.env.REACT_APP_GA_ID;

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENV,

  integrations: [
   // Sentry.replayIntegration(),
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Use GA only in production env
if (
  ENV === "prod"
  && GA_TRACKING_ID
) {
  ReactGA.initialize(GA_TRACKING_ID);
}

// Handler redirect after login
const onRedirectCallback = (appState) => {
  // if appState provides returnTo redirect to that url
  // if not, redirect to default home page
  window.location.replace(appState && appState.returnTo
    ? appState.returnTo
    : window.location.pathname)
};


// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const AppWithAuth = () => {
  const providerConfig = {
    domain: domain,
    clientId: clientId,
    authorizationParams: {
      redirect_uri: redirectUri,
      audience: audience,
    },
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
    onRedirectCallback: onRedirectCallback,
  };

  return (
    <Provider store={store}>
      <BrowserRouter basename={config.basename}>
        <Auth0Provider {...providerConfig}>
          <App />
        </Auth0Provider>
      </BrowserRouter>
    </Provider>
  );
};

const container = document.getElementById("root");

// provide environment type in doc title
document.title = `b.e.dashboard (${ENV})`;

const root = createRoot(container);
root.render(<AppWithAuth />);
