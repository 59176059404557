import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { searchProjects, getProjectDataByView, getProjectByIdView } from '../../api/index';
import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'js-cookie';


import { useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, useMediaQuery } from '@mui/material';
import MapSearchBar from 'component/MapSearchBar';
import ViewSwitcher from 'component/ViewSwitcher';
import ProjectPreviewContent from 'component/ProjectPreviewContent';
import FilterComponent from 'component/Filter';
import Header from '../../component/Header';
import Footer from '../../component/Footer';
import { features } from 'store/constant';
import ProjectPreviewSidePanel from '../../ui-component/ProjectPreviewSidePanel'
import ProjectGridTablePanel from '../../ui-component/ProjectGridTablePanel'
import SearchGridTable from 'component/SearchGridTable';
import { useJoyride } from "utilities/JoyrideProvider";
// import { TourGuideSteps } from 'utilities/Steps';


// Helper function to get the view from the pathname
export const getViewFromPathname = (pathname) => {
  switch (pathname) {
    case '/entry/map':
      return 'map';
    case '/entry/table':
      return 'table';
    case '/entry/grid':
      return 'grid';
    default:
      // Default to 'map' if the pathname
      return 'map';
  }
};


// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const location = useLocation();
  const mainContainerRef = useRef();
  const navigate = useNavigate();
  const { logout } = useAuth0();

  const [viewBookmarked, setViewMarked] = useState(false);
  const [viewFilters, setViewFilters] = useState(false);
  const [searchCardData, setSearchCardData] = useState([]);
  const [projectLoading, setProjectLoading] = useState(true);
  const [isSelectedProjectLoading, setSelectedProjectLoading] = useState(true);
  const [selectedProject, setSelectedProject] = useState(localStorage.getItem("selectedProject"));
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [openFilterAlert, setOpenFilterAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(''); // Add state for the alert message
  const [filteredData, setFilteredData] = useState([]);
  const { nextStep, joyrideState,setStepReadyState } = useJoyride();
  const tour_completed = Cookies.get('tour_completed');
  // Media query for mobile devices
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));


  // Media query for tablet devices
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const token = localStorage.getItem('access_token');

  const handleLogout = useCallback(() => {
    localStorage.removeItem('access_token');
    Cookies.remove('access_token');
    logout({ logoutParams: { returnTo: window.location.origin } })
  }, [logout]);

  const MAX_RETRY_COUNT = 3; // Maximum number of retry attempts
  const RETRY_DELAY = 1000; // Delay between retry attempts in milliseconds

  const fetchDataByView = async (location, retryCount = 0) => {
    try {
      setProjectLoading(true);
      setSelectedProjectLoading(true);
      const view = getViewFromPathname(location);
      try {
        const response = await getProjectDataByView(token, view);
        if (response.detail === "not validated: Signature has expired" || response.status === 401) {
          console.error('Unauthorized access. Redirecting to login.');
          navigate('/');
          return;
        }
        const { data } = response.data;
        if(data.length === 0){
          navigate('/info');
        }else {
          setSearchCardData(data);
          setProjectLoading(false);
          setSelectedProjectLoading(false);
        }

      } catch (error) {
        console.error('Error:', error);
        if (error.response && error.response.status === 401) {
          handleLogout();
        }
        setProjectLoading(false);

        // Retry if the error is related to database connection and retry count is less than maximum
        if (retryCount < MAX_RETRY_COUNT && isDatabaseConnectionError(error)) {
          await new Promise(resolve => setTimeout(resolve, RETRY_DELAY)); // Wait before retrying
          await fetchDataByView(location, retryCount + 1); // Retry the fetch
        }
      }
    } catch (error) {
      console.error('Error:', error);
      if (error.response && error.response.status === 401) {
        handleLogout();
      }
      setProjectLoading(false);
      setSelectedProjectLoading(false);
    }
  };

  // Function to check if the error is related to database connection
  const isDatabaseConnectionError = (error) => {
    return error.message.includes("ConnectionDoesNotExistError");
  };

  useEffect(() => {
    fetchDataByView(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleSearchChange = async (e) => {

    try {
      const newSearchTerm = e.target.value;
      setSearchTerm(newSearchTerm);
      setProjectLoading(true);
      setSelectedProjectLoading(true);

      if (searchCardData.length > 0 && newSearchTerm) {

        const regex = new RegExp(`\\b${newSearchTerm}`, 'i');
        const filteredSuggestions = searchCardData.filter(item =>
          regex.test(item.client) || regex.test(item.name) || item.tags.some(tag => regex.test(tag))

        );
        setSuggestions(filteredSuggestions);

      } else {
        setSuggestions([]);
      }

      if (!newSearchTerm.trim()) {
        setSearchPerformed(false);
        setSelectedProject(null);
        setProjectLoading(false);
        setSelectedProjectLoading(false);
        // If the search term is empty, retrieve all projects
        const allProjectsResponse = await searchProjects('', token);
        const allProjectsData = allProjectsResponse?.data;
        setSearchCardData(allProjectsData?.data);

      } else {
        const response = await searchProjects(newSearchTerm, token);
        const { data } = response?.data;
        // Convert object to array if necessary
        setSelectedProject(data);
        setSelectedProjectLoading(false);
        setSearchPerformed(true);
        setProjectLoading(false);
      }
    } catch (error) {
      //console.error('Error:', error);
      if (error.response && error.response.status === 401) {
        handleLogout();
      }
      setSearchPerformed(false);
      setSelectedProjectLoading(true);
      setProjectLoading(false);
    }
  };

  // Format search term with matched text 
  const highlightMatch = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} className="highlight" style={{ color: '#164A1A', fontWeight: 'bolder' }}>{part}</span>
      ) : (
        part
      )
    );
  };

  const handleBookMarkViews = async (e) => {
    try {
      setViewMarked(true);
      if(joyrideState.stepIndex === 7 && joyrideState.currentAction === "next"){
        setTimeout(() => {
          nextStep();
        }, 400);
      }
      setViewFilters(false);
      setSearchPerformed(false);
      setSelectedProject(null);
      setProjectLoading(false);
      setSelectedProjectLoading(false);
      setFilteredData([]);

      const storedBookmarks = JSON.parse(localStorage.getItem('bookmarks'));

      if (storedBookmarks) {
        // Sort bookedmarked data in alphabetical order
        const sortedBookedMarkedData = storedBookmarks.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        setSearchCardData(sortedBookedMarkedData);
      } else {
        setSearchCardData([]);
      }
    } catch (error) {
      console.error('Error:', error);
      if (error.response && error.response.status === 401) {
        handleLogout();
      }
      setSearchPerformed(false);
      setSelectedProjectLoading(true);
      setProjectLoading(false);
    }
  };

  const handleFilterViews = async (e) => {
    try {
      setViewFilters(true);
      if(joyrideState.stepIndex === 5 && joyrideState.currentAction === "next"){
        setTimeout(() => {
          nextStep();
        }, 400);
      }
      setViewMarked(false);
      setFilteredData([]);
    } catch (error) {
      console.error('Error:', error);
      if (error.response && error.response.status === 401) {
        handleLogout();
      }
      setViewFilters(false);
    }
  };


  const getFilteredData = async (data) => {
    try {
      setViewFilters(false);
      setViewMarked(false);
      setSearchCardData(data);
      setFilteredData(data)
    } catch (error) {
      console.error('Error:', error);
      if (error.response && error.response.status === 401) {
        handleLogout();
      }
      setViewFilters(false);
    }
  };

  const getAlertMessage = (value, showAlert) => {
    setAlertMessage(value);
    setOpenFilterAlert(showAlert);
  };

  const handleCloseFilterAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenFilterAlert(false);
  };

  const resetFilteredData = () => {
    setFilteredData([]);
  }

  const handleBackButtonClick = async () => {
    setSearchTerm('');
    setSearchPerformed(false);
    setProjectLoading(false);
    setSelectedProjectLoading(false);
    setViewMarked(false);
    setViewFilters(false);
    setSearchCardData([]);
    setSuggestions([]);
    setFilteredData([]);
    await fetchDataByView()
  };


  const filteredCardData =
    searchCardData?.filter((item) => {
      return item?.name?.toLowerCase().includes(searchTerm.toLowerCase());
    }) || [];

  const filteredSelectedData =
    searchPerformed
      ? filteredCardData.find((item) => {
        return item?.name?.toLowerCase().includes(searchTerm.toLowerCase());
      }) || null
      : selectedProject || null || filteredCardData[0];

  const handleProjectItemClick = (projectId) => {
    localStorage.setItem("selectedProject", projectId);
    if(joyrideState.stepIndex === 9 && joyrideState.currentAction === "next"){
      setTimeout(() => {
        nextStep();
      }, 300);
    }
    // set a provisional state of selectedProject to
    // quickly handle project click
    setSelectedProject({ identifier: projectId });

    setSelectedProjectLoading(true);
    if (searchTerm === " " && !tour_completed) {
      setSelectedProject(null);
    }

    const view = getViewFromPathname(location.pathname);
    getProjectByIdView(projectId, token, view)
      .then(response => {
        if (response.detail === "not validated: Signature has expired" || response.status === 401) {
          console.error('Unauthorized access. Redirecting to login.');
          navigate('/');
          return;
        }
        setSelectedProject(response?.data?.data);
        setSelectedProjectLoading(false)
      })
      .catch(error => {
        console.error('Error:', error);
        if (error.response && error.response.status === 401) {
          handleLogout();
        }
        setSelectedProjectLoading(true)
        setSelectedProject(null);
      });
  };

  const handleProjectPreviewClose = () => {
    setSelectedProject(null);
    localStorage.removeItem("selectedProject");
  };

  const handleProjectDetails = (uniqueId) => {
    localStorage.setItem("newselectedProject", uniqueId);
    if(joyrideState.stepIndex === 11 && joyrideState.currentAction === "next"){
      setTimeout(() => {
        nextStep();
      }, 1000);
    }
    //setSelectedProject(null);
    setSelectedProjectLoading(false);
    navigate(`/project/projectdetail/${uniqueId}`);
  };

  useEffect(() => {
    if (localStorage.getItem("selectedProject")) {

      setSelectedProjectLoading(true)
      const view = getViewFromPathname(location.pathname);
      getProjectByIdView(localStorage.getItem("selectedProject"), token, view)
        .then(response => {
          if (response.detail === "not validated: Signature has expired" || response.status === 401) {
            console.error('Unauthorized access. Redirecting to login.');
            navigate('/');
            return;
          }

          if (response.data.status) {
            setSelectedProject(response?.data?.data);
            setSelectedProjectLoading(false)
          } else {
            setSelectedProject({...localStorage.getItem("selectedProject"), ...response.data});
            setSelectedProjectLoading(false);
          }
        })
        .catch(error => {
          console.error('Error:', error);
          if (error.response && error.response.status === 401) {
            handleLogout();
          }
          setSelectedProjectLoading(true)
          setSelectedProject(null);
        });
    }
  }, [location, handleLogout, navigate, token])

  const CommonContext = [
    selectedProject,
    filteredCardData,
    projectLoading,
  ]

  const TableGridViewContext = [
    handleProjectItemClick,
    openFilterAlert,
    alertMessage,
    handleCloseFilterAlert,
    handleProjectPreviewClose,
  ]

  const MapGridViewContext = [
    viewFilters,
    filteredData,
    viewBookmarked,
  ]

  const showBackButton = searchTerm !== '' || viewBookmarked || viewFilters;

  const MapViewContext = [
    searchTerm,
    handleSearchChange,
    suggestions,
    highlightMatch,
    handleBackButtonClick,
    handleBookMarkViews,
    handleFilterViews,
    getFilteredData,
    setSelectedProject,
    isSelectedProjectLoading,
    setSelectedProjectLoading,
    showBackButton,
    handleProjectDetails,
    filteredSelectedData
  ]

  const context = [
    ...CommonContext,
    ...(location.pathname !== '/entry/map' ? TableGridViewContext : []),
    ...(location.pathname !== '/entry/table' ? MapGridViewContext : []),
    ...(location.pathname === '/entry/map' ? MapViewContext : []),
  ]

  useEffect(() => {
      // Watch for step index 0 and previous action
      if (joyrideState.stepIndex === 9 && joyrideState.manualStartTour) {
        handleBackButtonClick()
      } 
  }, [joyrideState.stepIndex]); // Dependencies include stepIndex and action

  const triggerTour = () => {
    handleProjectPreviewClose();
    setStepReadyState(true); // Notify Joyride that the step is ready
  }
 
  return (
    <div
      ref={mainContainerRef}
      id="main-container"
      style={{ height: "100vh", overflowX: "hidden", overflowY: "scroll" }}>
      <Box  className="Help-entrypage-bookmarks" style={{ display: 'block', }}>
        <CssBaseline />
        <AppBar
          enableColorOnDark
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            bgcolor: theme.palette.background.default,
            transition: selectedProject ? theme.transitions.create('width') : 'none',
            borderBottom: '1.5px solid #164A1A',
            boxShadow: 'none',
            background: '#F9F5EB',
            padding: 0,
            top: 0,
            zIndex: !matchUpMd ? '9999999' : '',
            height: '56px',
            width: "100%",
          }}
        >
          <Header triggerTour={triggerTour} />
          {location.pathname === '/entry/map' && !isSmall && (
            <>
              {!(selectedProject && isMedium) &&
                <div style={{
                  width: "fit-content",
                  position: "absolute",
                  transform: "translateX(-50%)",
                  left: `calc(50% + ${selectedProject ? "408px" : "178px"})`,
                  top: "80px"
                }}>
                  <ViewSwitcher resetFilteredData={resetFilteredData}  handlePopoverContentClose={handleProjectPreviewClose}/>
                </div>}
            </>
          )}
        </AppBar>
        {
          location.pathname !== '/entry/map' && !isSmall && (
            <MapSearchBar
              searchTerms={searchTerm}
              handleSearchChange={handleSearchChange}
              projectData={filteredCardData}
              selectedProject={selectedProject}
              viewBookmarked={viewBookmarked}
              viewFilters={viewFilters}
              handleBookMarkViews={handleBookMarkViews}
              handleFilterViews={handleFilterViews}
              showBackButton={searchTerm !== '' || viewBookmarked || viewFilters}
              filteredData={filteredData}
              handleBackButtonClick={handleBackButtonClick}
              resetFilteredData={resetFilteredData}
              handlePopoverContentClose={handleProjectPreviewClose}
            />
          )
        }

        {location.pathname !== '/entry/map'  &&
          <>
            <ProjectPreviewSidePanel
              isProjectSelected={selectedProject !== null}
              content={
                (selectedProject) && (
                  <ProjectPreviewContent
                    features={features}
                    onClick={() => handleProjectDetails(selectedProject.identifier || selectedProject.identifier)}
                    selectedProject={selectedProject}
                    loading={isSelectedProjectLoading}
                    wildlife_visual_resources={selectedProject?.wildlife_visual_resources}
                    onClose={handleProjectPreviewClose}
                    handlePopoverContentClose={handleProjectPreviewClose}
                  />
                )
              }
            />
          </>
        }
        {
          location.pathname !== '/entry/map' && viewFilters &&
          (
            <ProjectGridTablePanel
              drawerOpen={viewFilters}
              content={
                <SearchGridTable
                  searchTerm={searchTerm}
                  handleSearchChange={handleSearchChange}
                  suggestions={suggestions}
                  highlightMatch={highlightMatch}
                  handleBookMarkViews={handleBookMarkViews}
                  handleFilterViews={handleFilterViews}
                  handleBackButtonClick={handleBackButtonClick}
                  showBackButton={searchTerm !== '' || viewBookmarked || viewFilters}
                  numberOfProjects={filteredCardData}
                  filteredData={filteredData}
                  viewBookmarked={viewBookmarked}
                  viewFilters={viewFilters}
                >
                  <FilterComponent getAlertMessage={getAlertMessage} getFilteredData={getFilteredData} />
                </SearchGridTable>
              }
            />
          )
        }

        <Outlet context={context} />

        {location.pathname !== '/entry/map' &&
          <div style={{ marginTop: '3rem' }}>
            <Footer />
          </div>
        }
      </Box>

      {location.pathname === '/entry/map' && !isSmall && (
        <Footer />
      )
      }
    </div>
  );
};

export default MainLayout;
