import React, {useEffect, useRef} from "react";
import {
  Grid,
  Typography,
  Divider,
  Card,
  CardHeader,
  CardContent,
  Box,
  IconButton,
  Paper,
  styled
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import tooltipCalender from "../../assets/images/icons/tooltip-clender.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTheme } from "@emotion/react";
import { useJoyride } from "utilities/JoyrideProvider";


const Item = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "theme",
})(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#F9F5EB",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: "5px",
  width: "auto",
  [theme.breakpoints.down("sm")]: {
    width: "327px",
  },
}));

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#EAFFD8",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#EAFFD8",
    color: "#2B2B2B",
    width: "1000px",
    boxShadow: "4px 20px 36px 8px #1D1D1B14",
    border: "1px solid #164A1A",
    padding: 0,
  },
}));

const removeDuplicates = (data) => {
  let uniqueRecords = [];
  let uniqueKeys = new Set(); // To store unique combinations of keys

  data.forEach((record) => {
    // Form a unique key using the desired properties
    let key = `${record.name}-${record.timestamp}-${record.timestamp_end}-${record.value}`;
    // Check if the key is not already in the set of unique keys
    if (!uniqueKeys.has(key)) {
      uniqueKeys.add(key); // Add the key to the set
      uniqueRecords.push(record); // Add the record to the list of unique records
    }
  });

  return uniqueRecords;
};

// Function to sort the array
const sortObjects = (objA, objB) => {
  // Check if description includes "detected" for both objects
  const includesDetectedA = objA.name.includes("detected");
  const includesDetectedB = objB.name.includes("detected");

  // If one object includes "detected" and the other doesn't, prioritize the one with "detected"
  if (includesDetectedA && !includesDetectedB) {
    return -1;
  } else if (!includesDetectedA && includesDetectedB) {
    return 1;
  } else {
    // If both or neither include "detected", maintain the original order
    return 0;
  }
};

export const InfoDataTooltip = ({ data, index, infoName }) => {
  const elementRef = useRef(null);
  const { setStepReadyState, joyrideState } = useJoyride();
  const readMore = (value) => {
    if (process.env.REACT_APP_ORIGIN === "http://localhost:3000/") {
      return `${process.env.REACT_APP_ORIGIN}pages/glossary/#${value}`;
    } else {
      return `${process.env.REACT_APP_ORIGIN}/pages/glossary/#${value}`;
    }
  };

  
  useEffect(() => {
    if(joyrideState.currentAction !== 'skip' || joyrideState.currentAction !== ''){
      // Simulate data loading
      setStepReadyState(false);
      setTimeout(() => {
          setStepReadyState(true); // Notify Joyride that the step is ready
      }, 200); // Adjust this delay to simulate your loading time
    }
  }, [setStepReadyState]);


  return (
    <CustomTooltip
      arrow
      sx={{
        zIndex: 99999999,
      }}
      placement="top"
      title={
        <Card
          sx={{
            height: "100%",
            background: "#EAFFD8",
            padding: 0,
            margin: 0,
          }}
        >
          <CardHeader
            sx={{ padding: "1rem" }}
            subheader={
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "MADE TOMMY",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "17px",
                  letterSpacing: "0px",
                  textAlign: "left",
                  color: "#2B2B2B",
                  paddingBottom: "1rem",
                }}
              >
                {data?.resources?.caption_text}
              </Typography>
            }
          ></CardHeader>
          <Divider sx={{ border: "1px solid #164A1A" }} />
          <CardContent sx={{ padding: "1rem" }}>
            <div style={{ marginBottom: "15px" }}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "MADE TOMMY",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "14px",
                  letterSpacing: "0px",
                  textAlign: "left",
                  paddingBottom: "1rem",
                }}
              >
                {" "}
                Description{" "}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "MADE TOMMY",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {data?.resources?.description}
              </Typography>
            </div>
            <div style={{ padding: "0.5rem 0rem" }}>
              <Typography
                variant="div"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#2B2B2B",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                <span
                  sx={{
                    color: "#2B2B2B",
                    fontSize: "14px",
                    fontWeight: "500",
                    marginLeft: "1rem",
                  }}
                >
                  {" "}
                  Reporting period{" "}
                </span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  &nbsp; &nbsp; &nbsp;
                  <img
                    src={tooltipCalender}
                    alt="calendar"
                    width="16px"
                    height="16px"
                  />
                  &nbsp;
                  <span
                    sx={{
                      color: "#2B2B2B",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    {data?.formatted_timestamp}
                  </span>
                </div>
              </Typography>
              <Typography
                variant="div"
                sx={{
                  paddingTop: "10px",
                  color: "#2B2B2B",
                  fontSize: "13px",
                  fontWeight: "400",
                }}
              >
                {data?.resources?.citation}
              </Typography>
              {data?.glossary_resource && (
                <Typography variant="body1" sx={{ marginTop: "1rem" }}>
                  <a
                    href={readMore(data?.glossary_resource)}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: "#164A1A",
                      textDecoration: "underline",
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    Read more
                  </a>
                </Typography>
              )}
            </div>
          </CardContent>
        </Card>
      }
    >
      <IconButton
       ref={elementRef}
        disableRipple
        disableFocusRipple
        disableTouchRipple
        aria-owns={`mouse-over-popover`}
        aria-haspopup="true"
        className="my-twelve-step"
      >
        <InfoOutlinedIcon
          sx={{ color: "#919397", marginTop: "-10px" }}
          fontSize="small"
        />
      </IconButton>
    </CustomTooltip>
  );
};

const InfoDataBox = ({ infoindex, infoData, infoName, compactProjectDetail, forceGreenText = false }) => {
  const theme = useTheme();

  let metrics = infoData;
  
  if (metrics.type === 'metrics_group') {
    metrics = removeDuplicates(infoData.metrics);
    metrics.sort(sortObjects);
  }

  return (
    <>
      {(
        (infoData.type === 'metrics_group' &&  metrics.length > 0)
        || (infoData.type === 'metric')
      ) && (
        <Grid
          item
          xs={6}
          mb={3}
          sx={{
            maxWidth: compactProjectDetail ? "100%!important" : '',
            width: compactProjectDetail ? "100%!important" : '',
            flexGrow: compactProjectDetail ? "1!important" : '0',
            [theme.breakpoints.down("sm")]: {
              maxWidth: "327px!important",
              width: "327px"
            },
          }}
        >
          <Item theme={theme}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                paddingTop: "15px",
                paddingLeft: "20px",
              }}
              p={3}
            >
              <img
                src={infoData.type === 'metric' ? infoData.data.resources.access_url : infoData.icon}
                alt="grid-icon"
                style={{
                  paddingRight: "2rem",
                  filter:
                    "invert(20%) sepia(66%) saturate(510%) hue-rotate(75deg) brightness(95%) contrast(96%)",
                }}
              />
              {infoData.type === 'metrics_group' ? (
                <Box
                  sx={{ borderLeft: "1px solid #D8D5CC", paddingLeft: "2rem" }}
                >
                  {metrics.map((data, idx) => (
                    <Box key={idx} mb={3}>
                      <Typography
                        variant="h3"
                        mb={1}
                        fontWeight={500}
                        color={
                          forceGreenText || (
                            data.resources.identifier.toLowerCase().includes("detected")
                            || data.name.includes("detected")
                          ) ? "#164A1A" : "#919397"
                        }
                      >
                        {data.formatted_value}
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                        <Typography
                          variant="subtitle2"
                          mr={1}
                          mb={2}
                          color={"#626364"}
                        >
                          {data.resources.caption_text} ({data?.formatted_unit})
                        </Typography>
                        <InfoDataTooltip data={data} index={infoindex} infoName={infoName}/>
                      </Box>
                    </Box>
                  ))}
                </Box>
              ) : (
                <Box sx={{ paddingLeft: "2rem" }}>
                  <Box>
                    <Typography
                      variant="h3"
                      mb={1}
                      fontWeight={500}
                      color={"#164A1A"}
                      style={{ fontFamily: '"MADE TOMMY", sans-serif' }}
                    >
                      {metrics.data.formatted_value}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                      <Typography variant="subtitle2" mr={1} mb={2} color={"#626364"}
                        sx={{
                          fontSize: 14,
                          fontFamily: 'MADE TOMMY',
                          fontWeight: '400',
                          wordWrap: 'break-word',
                          textTransform: 'capitalize'
                        }}>
                        {metrics.data.resources.caption_text} ({metrics.data.formatted_unit})
                      </Typography>
                      <InfoDataTooltip data={metrics.data} index={infoindex} infoName={infoName}/>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Item>
        </Grid>
      )}
    </>
  );
};

export default InfoDataBox;
