import React, { useState, useEffect, useRef, useCallback } from "react";
import { styled } from "@mui/system";
import {
  Paper,
  Grid,
  Typography,
  IconButton,
  Dialog,
  Slide,
  Backdrop,
  Skeleton,
  useTheme,
  useMediaQuery
} from "@mui/material";
import {
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
} from "@mui/icons-material";
import closeButton from "../../assets/images/icons/close.png";
import nextButton from ".././../assets/images/icons/next.svg";
import prevButton from ".././../assets/images/icons/PrevButton.svg";

const CarouselContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "isSmall" && prop !== "isMedium",
})(({ isSmall, isMedium }) => ({
  position: "relative",
  maxWidth: isSmall ? "287px" : isMedium ? "" : "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  margin: "1rem 0rem",
}));

const CarouselInner = styled("div")({
  display: "flex",
  transition: "transform 0.5s ease-in-out",
});

const CarouselItem = styled(Paper)({
  width: "370px",
  height: "210px",
  backgroundColor: "transparent",
  boxSizing: "border-box",
  marginRight: "8px",
  cursor: "pointer",
});

const waitFor = (delay = 1000) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, delay);
  });
};

export const fetchWithRetry = async (
  resourceUrl,
  accessToken,
  retries = 10,
  initialDelay = 1000,
) => {
  let attempt = 0;
  let delay = initialDelay;

  while (attempt < retries) {
    const response = await fetch(resourceUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      return response; // Return the successful response
    }

    if (response.status === 500) {
      return response; // Return server error
    }

    attempt++;
    console.log(
      `Attempt ${attempt} failed with status ${response.status}. Retrying in ${
        delay / 1000
      } seconds...`,
    );
    await waitFor(delay);
    delay *= 2; // Exponential backoff
  }

  throw new Error("Failed to fetch resource after multiple attempts");
};

// Function to update a specific item in the resources array
export const updateResource = (resources, resourceId, newValues) => {
  // Find the index of the resource to update
  const index = resources.findIndex(
    (resource) => resource.identifier === resourceId,
  );

  if (index !== -1) {
    // Update the resource at the found index
    resources[index] = { ...resources[index], ...newValues };
  }

  // Return the updated resources array
  return resources;
};

const ProjectDetailCarousel = ({
  country,
  wildlife_visual_resources
}) => {

  const theme = useTheme();
  const [loading, _setLoading] = useState(true);
  const [carouselData, _setCarouselData] = useState([]);
  const accessToken = localStorage.getItem("access_token");
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  const carouselDataRef = useRef(carouselData);
  const setCarouselData = (data) => {
    carouselDataRef.current = data;
    _setCarouselData(data);
  };

  const loadingRef = useRef(loading);
  const setLoading = (l) => {
    loadingRef.current = l;
    _setLoading(l);
  };

  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const fetchCarouselData = useCallback(async (resources) => {
    try {
      let batches = [];
      let batch = [];
      let batchLength = 3;
      resources.forEach((r, idx) => {
        if (idx % batchLength === 0 && idx !== 0) {
          batches.push(batch);
          batch = [r];
        } else {
          batch.push(r);
        }

        if (idx + 1 === resources.length && batch.length < batchLength) {
          batches.push(batch);
        }
      });

      for (const b of batches) {
        await Promise.all(
          b.map(async (r) => {
            let response;
            try {
              response = await fetchWithRetry(r.thumbnail_url, accessToken);

              if (response.status === 500) {
                throw new Error("500 Server error");
              }

              const blob = await response.blob();
              const fileURL = URL.createObjectURL(blob);

              setCarouselData((prevData) =>
                updateResource([...prevData], r.identifier, {
                  thumbnail_url: fileURL,
                  loading: false,
                }),
              );
            } catch (error) {
              console.error(`Error fetching data from ${r.thumbnail_url}:`, error);
            }
            if (loadingRef.current) setLoading(false);
          }),
        );
      }
    } catch (error) {
      setLoading(true);
      console.error("Error fetching files data:", error);
    }
  },[accessToken]);

  useEffect(() => {
    if (wildlife_visual_resources && accessToken) {
      let resources = [
        ...wildlife_visual_resources.map((r) => {
          return { ...r, type: r.access_url.includes('.webm') ? "video" : "image", loading: true };
        })
      ];
      setCarouselData(resources);
      if (loadingRef.current) setLoading(false);
    }
  }, [wildlife_visual_resources, accessToken]);

  const handleItemOnClick = (index) => {
    setModalOpen(true);
  };

  const [currentLargeItemIndex, setCurrentLargeItemIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentLargeItemIndex((prevIndex) =>
      prevIndex === 0 ? carouselData.length - 1 : prevIndex - 1,
    );
  };

  const handleNext = () => {
    setCurrentLargeItemIndex((prevIndex) =>
      prevIndex === carouselData.length - 1 ? 0 : prevIndex + 1,
    );
  };

  return (
    <>
      {loading === true ? (
        <Skeleton variant="rect" width={400} height={200} />
      ) : (
        carouselData.length > 0 && (
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: 0,
              [theme.breakpoints.up('md')]: {
                maxWidth: "calc(1 / 3* 100vw - 64px)",
              },
              [theme.breakpoints.down('md')]: {
                maxWidth: "unset",
              }
            }}
          >
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                margin: "1rem 0rem",
              }}
            >
              {loading ? (
                <Skeleton variant="rectangular" width={360} height={480} />
              ) : (
                /* Thumbnail Carousel */
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div>
                      <IconButton
                        disableRipple
                        disableTouchRipple
                        disableFocusRipple
                        onClick={handlePrevious}
                        variant="outlined"
                        color="primary"
                      >
                        <KeyboardArrowLeftIcon size="small" />
                      </IconButton>
                    </div>
                    <CarouselContainer isSmall={isSmall} isMedium={isMedium}>
                      <CarouselInner
                        style={{
                          transform: `translateX(-${
                            currentLargeItemIndex * (100 / carouselData.length)
                          }%)`,
                        }}
                      >
                        {carouselData.map((item, index) => (
                          <CarouselItem
                            key={`${
                              item.identifer !== undefined
                                ? item.identifer
                                : index
                            }-${item.loading}`}
                            style={{
                              flex: 1,
                              filter:
                                index === currentLargeItemIndex
                                  ? ""
                                  : "opacity(0.4)",
                            }}
                            onClick={() => handleItemOnClick(index)}
                          >
                              <>
                                  <Skeleton
                                    variant="rectangular"
                                    width={360}
                                    height={480}
                                    sx={{ 
                                      display: !item.loading ? 'none' : 'block'
                                    }}
                                  />
                                  <img
                                    src={item.thumbnail_url}
                                    alt={`item ${index + 1}`}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      display: item.loading ? 'none' : 'block'
                                    }}
                                    onLoad={() => {
                                      setCarouselData((prevData) =>
                                        updateResource([...prevData], item.identifier, {
                                          loading: false,
                                        }),
                                      );
                                    }}
                                  />
                              </>
                          </CarouselItem>
                        ))}
                      </CarouselInner>
                    </CarouselContainer>

                    <div>
                      <IconButton
                        disableRipple
                        disableTouchRipple
                        disableFocusRipple
                        onClick={handleNext}
                        variant="outlined"
                        color="primary"
                      >
                        <KeyboardArrowRightIcon size="small" />
                      </IconButton>
                    </div>
                  </div>

                  {/* Indicators */}
                  {carouselData.length < 10 && (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: isSmall ? "wrap" : "no-wrap",
                        justifyContent: "center",
                        marginTop: "1rem",
                      }}
                    >
                      {carouselData.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor:
                              index === currentLargeItemIndex
                                ? "#0A330E"
                                : "#919397",
                            borderRadius: "50%",
                            margin: "0 5px",
                            cursor: "pointer",
                            marginTop: isSmall ? "15px" : "0",
                          }}
                          onClick={() => setCurrentLargeItemIndex(index)}
                        />
                      ))}
                    </div>
                  )}
                  {carouselData.length > 10 && (
                    <div
                      style={{
                        color: "#626364",
                        paddingLeft: "41px",
                      }}
                    >
                      {currentLargeItemIndex + 1} / {carouselData.length}
                    </div>
                  )}
                </>
              )}
            </Grid>

            <Dialog
              open={modalOpen}
              onClose={handleCloseModal}
              TransitionComponent={Slide}
              fullScreen
              BackdropComponent={(props) => (
                <Backdrop {...props} sx={{ backgroundColor: "#2B2B2BCC" }} />
              )}
              PaperProps={{
                sx: {
                  boxShadow: "none",
                  border: "none",
                  backgroundColor: "#272727",
                },
              }}
              sx={{
                zIndex: 99999,
              }}
            >
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleCloseModal}
                aria-label="close"
                sx={{ position: "absolute", top: 10, right: isSmall ? 22 : 60 }}
              >
                <img src={closeButton} alt="close" />
              </IconButton>

              <React.Fragment>
                <div
                  style={{
                    display: "flex",
                    flexDirection: isSmall ? "column" : "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: isSmall ? "6rem" : "5rem",
                  }}
                >
                  {!isSmall && (
                    <IconButton
                      disableRipple
                      disableTouchRipple
                      disableFocusRipple
                      onClick={handlePrevious}
                      variant="outlined"
                      color="primary"
                      sx={{ marginRight: "4rem" }}
                    >
                      <img src={prevButton} alt="nextbutton" />
                    </IconButton>
                  )}

                  <div style={{ textAlign: "center", marginBottom: "1rem" }}>
                    {carouselData[currentLargeItemIndex].loading ? (
                      <Skeleton
                        variant="rectangular"
                        width={1096.08}
                        height={616.55}
                        sx={{ backgroundColor: "#9d9d9d54" }}
                      />
                    ) : (
                      <>
                        {/* Large Image */}
                        {carouselData.length > 0 &&
                          carouselData[currentLargeItemIndex].type ===
                            "image" && (
                            <img
                              src={
                                carouselData[currentLargeItemIndex].access_url
                              }
                              alt="Large"
                              style={{
                                width: isSmall ? "360px" : "1096.08px",
                                maxHeight: "616.55px",
                                objectFit: "contain",
                              }}
                            />
                          )}
                        {carouselData.length > 0 &&
                          carouselData[currentLargeItemIndex].type ===
                            "video" && (
                            <video
                              key={currentLargeItemIndex}
                              controls
                              width="100%"
                              height="616.55px"
                              style={{
                                width: "1096.08px",
                                maxHeight: "616.55px",
                                objectFit: "contain",
                              }}
                            >
                              <source
                                src={
                                  carouselData[currentLargeItemIndex].access_url
                                }
                                type="video/webm"
                              />
                              Your browser does not support the video tag.
                            </video>
                          )}
                      </>
                    )}
                  </div>

                  {!isSmall && (
                    <IconButton
                      disableRipple
                      disableTouchRipple
                      disableFocusRipple
                      onClick={handleNext}
                      variant="outlined"
                      color="primary"
                      sx={{ marginLeft: "4rem" }}
                    >
                      <img src={nextButton} alt="nextbutton" />
                    </IconButton>
                  )}

                  {isSmall && (
                    <div>
                      <IconButton
                        disableRipple
                        disableTouchRipple
                        disableFocusRipple
                        onClick={handlePrevious}
                        variant="outlined"
                        color="primary"
                        sx={{ marginRight: "4rem" }}
                      >
                        <img src={prevButton} alt="nextbutton" />
                      </IconButton>
                      <IconButton
                        disableRipple
                        disableTouchRipple
                        disableFocusRipple
                        onClick={handleNext}
                        variant="outlined"
                        color="primary"
                        sx={{ marginLeft: "4rem" }}
                      >
                        <img src={nextButton} alt="nextbutton" />
                      </IconButton>
                    </div>
                  )}
                </div>

                <Grid
                  container
                  style={{
                    width: "100%",
                    height: "100%",
                    background: "#2B2B2B",
                  }}
                >
                  {/* Indicators */}

                  <Grid
                    container
                    style={{
                      marginBottom: isSmall ? "0rem" : "3rem",
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: "100%",
                    }}
                  >
                    <Grid item>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexWrap: isSmall ? "wrap" : "no-wrap",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "1rem 0rem",
                        }}
                      >
                        {carouselData.map((item, index) => (
                          <div
                            key={index}
                            style={{
                              width: "10px",
                              height: "10px",
                              backgroundColor:
                                index === currentLargeItemIndex
                                  ? "#B7F97E"
                                  : "#545454",
                              borderRadius: "50%",
                              margin: "0 5px",
                              cursor: "pointer",
                              marginTop: isSmall ? "15px" : "0",
                            }}
                            onClick={() => setCurrentLargeItemIndex(index)}
                          />
                        ))}
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "1rem 0rem",
                        }}
                      >
                        <Grid
                          container
                          maxWidth="lg"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-strt",
                            alignItems: "flex-start",
                            padding: isSmall ? "1.5rem 5rem 0" : "3rem 5rem",
                          }}
                        >
                          <Typography
                            variant="body1"
                            style={{ color: "white", fontSize: "18px" }}
                          >
                            {carouselData[currentLargeItemIndex]?.caption_text}{" "}
                            <span
                              style={{ fontSize: "13px", marginLeft: "15px" }}
                            >
                              {carouselData[currentLargeItemIndex]?.citation}
                            </span>
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{ color: "white", paddingTop: "2rem" }}
                          >
                            Country: {country}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </React.Fragment>
            </Dialog>
          </Grid>
        )
      )}
    </>
  );
};

export default ProjectDetailCarousel;
