import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Box, TabScrollButton, useMediaQuery, useTheme } from "@mui/material";
import ProjectTab from "./ProjectTab";
import { useJoyride } from "utilities/JoyrideProvider";

function CustomTabPanel(props) {
  const { children, value, index, tabname } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${tabname}`}
      aria-labelledby={`${tabname}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const customOrder = [
  "ecosystem structure",
  "ecosystem function",
  "species population",
  "community composition",
  "species traits",
  "project statistics",
];

function sortByCustomOrder(arr) {
  const orderMap = new Map(customOrder.map((name, index) => [name, index]));
  return arr.sort((a, b) => (orderMap.get(a.name) - orderMap.get(b.name)));
}

export default function ProjectDetailNavTabs({ tabsData, isLoadingTabs, loading, compactProjectDetail }) {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { joyrideState } = useJoyride();
  const [value, setValue] = useState(0);

  const sortedTabsData = sortByCustomOrder(tabsData).filter(tab => {
    const { charts, metrics, tables } = tab.content;
    const metricsGroups = metrics.filter(m => m.type === "metrics_group");
    return charts.length || metrics.length || tables.length || 
           (metricsGroups.length && metricsGroups.some(group => Array.isArray(group.metrics) && group.metrics.length > 0));
  });

  const handleChange = (event, newValue) => setValue(newValue);

  const tabClassNames = {
    "ecosystem structure": "Help-projectpage-ecosystemstructure",
    "ecosystem function": "Help-projectpage-ecosystemfunctioning",
    "species population": "Help-projectpage-speciespopulation",
    "community composition": "Help-projectpage-communitycomposition",
    "species traits": "Help-projectpage-speciestraits",
    "project statistics": "Help-projectpage-projectstatistics",
  };

  const handleJoyrideStepChange = (stepIndex) => {
    const tabSteps = [17, 18, 19, 20, 21, 22];
    if (tabSteps.includes(stepIndex)) {
      const nextTabIndex = stepIndex - 16;
      if (nextTabIndex >= 0 && nextTabIndex < sortedTabsData.length) {
        setValue(nextTabIndex);
      }
    }
  };

  useEffect(() => {
    handleJoyrideStepChange(joyrideState.stepIndex);
  }, [joyrideState.stepIndex]);


  return (
    <>
      <Box
        className="Help-projectpage-tabs"
        sx={{
          borderBottom: 1,
          borderTop: 1,
          borderColor: "#D8D5CC",
          position: "sticky",
          top: "96px",
          [theme.breakpoints.down("md")]: { top: "152px" },
          [theme.breakpoints.down("sm")]: { top: "133px" },
          background: "#F9F5EB",
          zIndex: 999,
          width: "100%",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="project detail tabs"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          sx={{ background: "#F9F5EB" }}
          ScrollButtonComponent={(props) => (
            <TabScrollButton {...props} disabled={(value === 0 && props.direction === "left") || (value === sortedTabsData.length - 1 && props.direction === "right")} />
          )}
        >
          {sortedTabsData.map((tab, idx) => (
            <Tab
              key={idx}
              label={tab.name}
              id={tab.name.replace(' ','')}
              aria-controls={`tabpanel-${tab.name.replace(' ','')}`}
              sx={{
                margin: idx === 0 ? 0 : "0rem 2rem",
                textTransform: "uppercase",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "17px",
                letterSpacing: "0.04em",
                width: isSmall || isMedium ? "calc(100vw - 80px)" : "auto",
                height: isSmall || isMedium ? "65px" : "auto",
              }}
            />
          ))}
        </Tabs>
      </Box>
      <Box sx={{ width: "100%", background: "#F2EEE3" }}>
        {sortedTabsData.map((tab, idx) => (
          <CustomTabPanel 
            key={idx} 
            value={value} 
            index={idx} 
            className={tabClassNames[tab.name]}
            tabname={tab.name.replace(' ','')}>
            <ProjectTab tabData={tab} isLoadingTabs={isLoadingTabs} loading={loading} compactProjectDetail={compactProjectDetail} />
          </CustomTabPanel>
        ))}
      </Box>
    </>
  );
}



