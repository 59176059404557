import React, { useState, useEffect, useRef, useCallback } from 'react';
import { styled } from '@mui/system';
import { Paper, Grid, Typography, IconButton, Dialog, Slide, Backdrop, Skeleton } from '@mui/material';
import { KeyboardArrowRight as KeyboardArrowRightIcon} from '@mui/icons-material';
import closeButton from '../../assets/images/icons/close.png'
import { fetchWithRetry, updateResource } from 'component/ProjectDetailContent/ProjectDetailCarousel';

import nextButton from '.././../assets/images/icons/next.svg';
import prevButton from '.././../assets/images/icons/PrevButton.svg';

const CarouselContainer = styled('div')({
  position: 'relative',
  maxWidth: '90%',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'row',
  margin: '1rem 0rem',
});

const CarouselInner = styled('div')({
  display: 'flex',
  transition: 'transform 0.5s ease-in-out',
});

const CarouselItem = styled(Paper)({
  width: '122px',
  height: '68px',
  boxSizing: 'border-box',
  marginRight: '8px',
  cursor: 'pointer',
  backgroundColor: 'transparent'
});

const itemsPerGroup = 3;
const ImageCarousel = ({
  country,
  wildlife_visual_resources,
}) => {

  const [carouselData, setCarouselData] = useState([]);
  const [loading, _setLoading] = useState(true);
  const accessToken = localStorage.getItem('access_token');

  const [currentGroup, setCurrentGroup] = React.useState(0);

  const [modalOpen, setModalOpen] = useState(false);
  const [currentModalIndex, setCurrentModalIndex] = useState(0);

  const loadingRef = useRef(loading);
  const setLoading = (l) => {
    loadingRef.current = l;
    _setLoading(l);
  };

  const handleGroupNext = () => {
    setCurrentGroup((prevGroup) => (prevGroup === totalGroups - 1 ? 0 : prevGroup + 1));
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const fetchCarouselData = useCallback(async (resources) => {
    try {
      let batches = [];
      let batch = [];
      let batchLength = 3;
      resources.forEach((r, idx) => {
        if (idx % batchLength === 0 && idx !== 0) {
          batches.push(batch);
          batch = [r];
        } else {
          batch.push(r);
        }

        if (idx + 1 === resources.length && batch.length < batchLength) {
          batches.push(batch);
        }
      });

      for (const b of batches) {
        await Promise.all(
          b.map(async (r) => {
            let response;
            try {
              response = await fetchWithRetry(r.thumbnail_url, accessToken);

              if (response.status === 500) {
                throw new Error("500 Server error");
              }

              const blob = await response.blob();
              const fileURL = URL.createObjectURL(blob);

              setCarouselData((prevData) =>
                updateResource([...prevData], r.identifier, {
                  thumbnail_url: fileURL,
                  loading: false,
                }),
              );
            } catch (error) {
              console.error(`Error fetching data from ${r.thumbnail_url}:`, error);
            }
            if (loadingRef.current) setLoading(false);
          }),
        );
      }
    } catch (error) {
      setLoading(true);
      console.error("Error fetching files data:", error);
    }
  },[accessToken]);

  useEffect(() => {
    if (wildlife_visual_resources && accessToken) {
      let resources = wildlife_visual_resources.map((r) => {
        // Check if the resource is already in carouselData to avoid setting loading to true again
        const existingResource = carouselData.find((item) => item.access_url === r.access_url);
        return {
          ...r,
          type: r.access_url.includes('.webm') ? "video" : "image",
          loading: existingResource ? existingResource.loading : true, // Keep loading state if it already exists
        };
      });

      setCarouselData(resources);
      if (loadingRef.current && !carouselData.some((item) => item.loading)) {
        setLoading(false);  // Only set loading to false if all resources are loaded
      }
    }
  }, [wildlife_visual_resources, accessToken]);

  const allMedia = [...carouselData];
  const totalItems = allMedia?.length || 0;
  const totalGroups = Math.ceil(totalItems / itemsPerGroup);

  const handleItemOnClick = (index) => {
    setCurrentModalIndex(index);
    setModalOpen(true);
  };



  const handlePrevious = () => {
    setCurrentModalIndex(prevIndex => (prevIndex === 0 ? allMedia.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentModalIndex(prevIndex => (prevIndex === allMedia.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <>
      {allMedia?.length > 0 ? <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 0 }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0.5rem 0rem' }}>
          <Typography variant="h5" style={{ marginRight: '0.5rem' }}>
            Selected detections
          </Typography>
        </div>
        <Grid container style={{ display: 'flex', flexDirection: 'row', height: '100%', width: "407px", margin: '0rem 0rem 2.5rem 0rem' }}>
          {loading ? (
            <Skeleton variant="rectangular" width={360} height={68 * itemsPerGroup} />
          ) : (
            <CarouselContainer>
              <CarouselInner style={{ transform: `translateX(-${currentGroup * (100 / totalGroups)}%)` }}>
                {[...Array(totalGroups)].map((_, groupIndex) => {
                  const groupItems = allMedia
                    .slice(groupIndex * itemsPerGroup, (groupIndex + 1) * itemsPerGroup);

                  return (
                    <div key={groupIndex} style={{ display: 'flex', width: '100%' }}>
                      {groupItems?.map((item, itemIndex) => (
                        <CarouselItem
                          key={itemIndex}
                          style={{ flex: 1 }}
                          onClick={() => handleItemOnClick(groupIndex * itemsPerGroup + itemIndex)}
                        >
                          <>
                            <Skeleton
                              variant="rectangular"
                              width={122}
                              height={68}
                              sx={{ 
                                display: !item.loading ? 'none' : 'block'
                              }}
                            />
                            <img
                              src={item.thumbnail_url}
                              alt={`item ${groupIndex * itemsPerGroup + itemIndex + 1}`}
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                display: item.loading ? 'none' : 'block'
                              }}
                              onLoad={() => {
                                setCarouselData((prevData) =>
                                  updateResource([...prevData], item.identifier, {
                                    loading: false,
                                  }),
                                );
                              }}
                            />
                          </>
                        </CarouselItem>
                      ))}
                    </div>
                  );
                })}
              </CarouselInner>
            </CarouselContainer>
          )}
          {totalItems > itemsPerGroup && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                disableRipple
                disableTouchRipple
                disableFocusRipple
                onClick={handleGroupNext}// this is the one i changed
                variant="outlined"
                color="primary"
              >
                <KeyboardArrowRightIcon size="small" />
              </IconButton>
            </div>
          )}
        </Grid>

        <Dialog
          open={modalOpen}
          onClose={handleCloseModal}
          TransitionComponent={Slide}
          fullScreen
          BackdropComponent={(props) => <Backdrop {...props} sx={{ backgroundColor: '#2B2B2BCC' }} />}
          PaperProps={{ sx: { boxShadow: 'none', border: 'none', backgroundColor: '#272727' } }}
        >

          <React.Fragment>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: "center",
                alignItems: 'center',
                marginTop: '5rem',
              }}>
              <IconButton
                disableRipple
                disableTouchRipple
                disableFocusRipple
                onClick={handlePrevious}
                variant="outlined"
                color="primary"
                sx={{ marginRight: '4rem' }}
              >

                <img src={prevButton} alt="nextbutton" />
              </IconButton>

              <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
                {/* Large Image */}
                {allMedia.length > 0 && allMedia[currentModalIndex].type === 'image' && (
                  <img
                    src={allMedia[currentModalIndex].access_url}
                    alt="Large"
                    style={{ width: '1096.08px', maxHeight: '616.55px', objectFit: 'contain' }}
                  />
                )}
                {allMedia.length > 0 && allMedia[currentModalIndex].type === 'video' && (
                  <video
                    controls
                    width="100%"
                    height="616.55px"
                    style={{ width: '1096.08px', maxHeight: '616.55px', objectFit: 'contain' }}
                  >
                    <source src={allMedia[currentModalIndex].access_url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleCloseModal}
                aria-label="close"
                sx={{ position: 'absolute', top: 10, right: '2rem' }}
              >
                <img src={closeButton} alt="close" />
              </IconButton>

              <IconButton
                disableRipple
                disableTouchRipple
                disableFocusRipple
                onClick={handleNext}
                variant="outlined"
                color="primary"
                sx={{ marginLeft: '4rem' }}
              >
                <img src={nextButton} alt="nextbutton" />
              </IconButton>
            </div>

            <Grid container style={{ width: '100%', height: '100%', background: '#2B2B2B', }}>
              {/* Indicators */}

              <Grid container style={{ marginBottom: '3rem', display: 'flex', flexDirection: 'column', maxWidth: '100%' }}>
                <Grid item >
                  <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: '1rem 0rem' }}>
                    {allMedia.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          width: '10px',
                          height: '10px',
                          backgroundColor: index === currentModalIndex ? '#B7F97E' : '#545454',
                          borderRadius: '50%',
                          margin: '0 5px',
                          cursor: 'pointer',
                        }}
                        onClick={() => setCurrentModalIndex(index)}
                      />
                    ))}
                  </Grid>
                  <Grid item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '1rem 0rem' }}>
                    <Grid container maxWidth="lg" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-strt', alignItems: 'flex-start', padding: "3rem 5rem" }}>
                      <Typography variant="body1" style={{ color: 'white', fontSize: '18px' }}>
                        {allMedia[currentModalIndex]?.caption_text} <span style={{ fontSize: '13px', marginLeft: '15px' }}>
                          {allMedia[currentModalIndex]?.citation}
                        </span>
                      </Typography>
                      <Typography variant="body1" style={{ color: 'white', paddingTop: '2rem' }}>
                        Country: {country}
                      </Typography>
                      <Typography variant="body1" style={{ color: 'white' }}>
                        Year
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid >
            </Grid>

          </React.Fragment>

        </Dialog>

      </Grid> : <Grid>  <Skeleton variant="rect" width={400} height={200} /></Grid>}
    </>
  );
};

export default ImageCarousel;
