
import InfoIcon from "../../assets/images/icons/info-icon.svg";

const MessageMobile = () => {
  return (
    <div className="message-mobile">
      <div
        style={{
          paddingTop: "3px",
          paddingRight: "11px",
        }}
      >
        <img src={InfoIcon} />
      </div>
      <div>
        Further information, such as statistics and charts can be acessed using
        this application on a desktop or tablet.
      </div>
    </div>
  );
};

export default MessageMobile;
