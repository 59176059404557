import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { Pagination, Grid, Typography } from "@mui/material";
import PlayIcon from '../../assets/images/icons/Play.svg';
import PauseIcon from '../../assets/images/icons/Pause.svg';
import audioOn from '../../assets/images/icons/audiolevel.svg';
import PlayIconMini from '../../assets/images/icons/playiconmini.svg';
import Skeleton from "@mui/material/Skeleton";

const formWaveSurferOptions = ref => ({
  container: ref,
  waveColor: "#164A1A",
  progressColor: "#D2FFAB",
  cursorColor: "#D2FFAB",
  barWidth: 3,
  barRadius: 3,
  responsive: true,
  height: 80,
  // If true, normalize by the maximum peak instead of 1.0.
  normalize: true,
  // Use the PeakCache to improve rendering speed of large waveforms.
  partialRender: true
});

export default function Waveform({ tracks, loading }) {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [playing, setPlay] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [currentPage, setCurrentPage] = useState(1);
  const [duration, setDuration] = useState(0);
  const [currentTrackCaption, setCurrentTrackCaption] = useState("");
  const tracksPerPage = 5;

  const indexOfLastTrack = currentPage * tracksPerPage;
  const indexOfFirstTrack = indexOfLastTrack - tracksPerPage;
  const currentTracks = tracks.slice(indexOfFirstTrack, indexOfLastTrack);

  const totalPages = Math.ceil(tracks?.length / tracksPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    setPlay(false);

    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);

    // Load the URL of the first track initially
    wavesurfer.current.load(tracks[0].access_url);
    setCurrentTrackCaption(tracks[0].caption_text); // Set initial track caption

    wavesurfer.current.on("ready", function () {
      if (wavesurfer.current) {
        wavesurfer.current.setVolume(volume);
        setVolume(volume);
        setDuration(wavesurfer.current.getDuration());
      }
    });

    return () => wavesurfer.current.destroy();
  }, []);

  const handlePlayPause = () => {
    setPlay(!playing);
    wavesurfer.current.playPause();
  };

  // Inside your component function:
  const getCurrentTrackDuration = () => {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const onVolumeChange = e => {
    const { target } = e;
    const newVolume = +target.value;

    if (newVolume) {
      setVolume(newVolume);
      wavesurfer.current.setVolume(newVolume || 1);
    }
  };

  const handleTrackClick = (trackUrl, trackCaption) => { // Update to accept track caption
    wavesurfer.current.stop();
    wavesurfer.current.empty();
    wavesurfer.current.load(trackUrl);
    setPlay(false); // Autoplay the selected track
    setCurrentTrackCaption(trackCaption); // Update current track caption
  };

  /* const handleTrackClick = (trackUrl, trackCaption) => {
    wavesurfer.current.stop();
    wavesurfer.current.empty();
    wavesurfer.current.load(trackUrl);
    setPlay(false);
    setCurrentTrackCaption(trackCaption);
  };
 */
  return (
    <div>
      {loading ? <p>loading ...</p> : <Grid item xs={12} sx={{ maxWidth: "500px" }}>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between" >
          <Grid item xs={8}>
            <div className="controls">
              <button onClick={handlePlayPause} style={{ background: 'transparent', border: 'none', marginRight: '1rem' }}>{!playing ? <div style={{ position: 'relative', borderRadius: '50%', border: '2px solid #164A1A', height: "3rem",width: "3rem"}}>
                <img src={PlayIcon} alt="play-icon" style={{
                  color: "#164A1A", fontSize: '4rem', cursor: 'pointer',
                  height: "1.5rem",
                  width: "1.5rem",
                  position: "relative",
                  left: "0",
                  top: ".6rem",
                  
                }} /> 
              </div> 
              : <div style={{ position: 'relative', borderRadius: '50%', border: '2px solid #164A1A', height: "3rem",width: "3rem"}}>
              <img src={PauseIcon} alt="play-icon" style={{
                color: "#164A1A", fontSize: '4rem', cursor: 'pointer',
                height: "1.5rem",
                width: "1.5rem",
                position: "relative",
                left: "0",
                top: ".6rem",
                
              }} /> 
            </div> }</button>
              <img src={audioOn} alt="audio" />
              <input
                type="range"
                id="volume"
                name="volume"
                min="0.01"
                max="1"
                step=".025"
                onChange={onVolumeChange}
                defaultValue={volume}
                style={{
                  background: `linear-gradient(to right, #164A1A 0%, #164A1A ${(volume - 0.01) * 100}%, #D8D5CC ${(volume - 0.01) * 100}%, #D8D5CC 100%)`,
                  width: '105px',
                  height: '4px',
                }}
              />
            </div>
          </Grid>

          <Grid style={{
            textAlign: "right",
            paddingRight: "5px"
          }} item xs={2}>
            <Typography variant="body2">{getCurrentTrackDuration()}</Typography>
          </Grid>
        </Grid>


        <div id="waveform" ref={waveformRef} style={{ background: '#f2eee3', padding: '5px' }} />
        <div style={{ paddingBottom: "1rem" }}>
          {currentTrackCaption && (
            <Typography variant="subtitle2" style={{ textAlign: 'left', margin: '18px 0px' }}>{currentTrackCaption}</Typography>
          )}
        </div>

        <div className="playlist" key={currentTracks}>
          {currentTracks?.map((track, index) => (
            <React.Fragment key={`${index}-${track.loading}`}>
              {track.loading ? <Skeleton variant="rect" width={410} height={58} sx={{
                marginTop: "1px",
                marginBottom: "1px",
              }} /> : 
              <div
                key={index}
                className={"playlist-item"}
                style={{ 
                  borderColor: currentTrackCaption === track.caption_text ? '#164A1A' : "",
                  background: currentTrackCaption === track.caption_text ? '#F2EEE3' : ""
                }}
                onClick={() => handleTrackClick(track.access_url, track.caption_text)} // Handle track click
              >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <img style={{ paddingRight: "1rem" }} src={PlayIconMini} alt="play-icon"/>
                  <Typography variant="subtitle2">{track.caption_text.length > 60 ? `${track.caption_text.substring(0, 60)}...` : track.caption_text}</Typography>
                  <Typography variant="subtitle2">{getCurrentTrackDuration()}</Typography> {/* Pass the track object to the function */}
                </div>
              </div>
              }
            </React.Fragment>
          ))}
          {totalPages > 1 && (
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              shape="rounded"
              sx={{
                marginTop: '10px'
              }}
            />
          )}
        </div>
      </Grid >}
    </div>
  );
}
