// MainRoutes.js
import { lazy } from 'react';
import PageLayout from 'layout/PageLayout';
import ProtectedRoute from './ProtectedRoute';
import Loadable from 'utilities/Loadable';

const NotFound = Loadable(lazy(() => import('views/pages/PageNotFound')));

const PageRoutes = {
  path: '/pages',
  element: <PageLayout />,
  children: [

    {
      path: 'glossary',
      element: <ProtectedRoute  />,
      children: [
        {
          path: ':glossaryname',
          element: <ProtectedRoute />,
        },
      ],
    },
    {
      path: 'help',
      element: <ProtectedRoute  />
    },
    {
      path: '*/',
      element: <NotFound />,
    }
  ],
};

export default PageRoutes;
