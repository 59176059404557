// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const SET_GRID_TABLE_MENU = '@customization/SET_GRID_TABLE_MENU';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const GRID_TABLE_OPEN = '@customization/SGRID_TABLE_OPEN';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// action types
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const UPDATE_PROJECT_DATA = 'UPDATE_PROJECT_DATA';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';



export const loginRequest = () => ({
  type: LOGIN_REQUEST
});

export const loginSuccess = () => ({
  type: LOGIN_SUCCESS
});

export const loginFailure = error => ({
  type: LOGIN_FAILURE,
  payload: error
});


// logout actions
export const logoutRequest = () => ({
  type: LOGOUT_REQUEST
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS
});

export const logoutFailure = error => ({
  type: LOGOUT_FAILURE,
  payload: error
});

export const updateUserData = (userData) => ({
  type: UPDATE_USER_DATA,
  payload: userData,
});

export const updateProjectData = (projectData) => ({
  type: UPDATE_PROJECT_DATA,
  payload: projectData,
});
