import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Cookies from "js-cookie"; // Import Cookies
import {
  Modal,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Button,
  useTheme,
  useMediaQuery
} from "@mui/material";
import Terms from "../../../utilities/terms";
import Header from "component/Header";

const BoxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65vw",
  bgcolor: "#f2eee3",
  border: "2px solid #164A1A",
  borderRadius: "4px",
  boxShadow: 24,
  p: 4,
};

const TermsBox = {
  maxHeight: "400px",
  overflow: "auto",
  padding: "1rem",
  background: "#a29f9317",
  marginTop: "1rem",
  marginBottom: "1rem",
};

const TermsTitleStyle = {
  fontWeight: 500,
  marginBottom: "0.5rem",
};

const TermsContentStyle = {
  textAlign: "justify",
  marginBottom: "1rem",
};

const WarningStyle = {
  marginTop: "0.5rem",
  color: "#b90404",
  fontSize: "15px",
  textAlign: "right",
};

const UsersAgreement = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  let [searchParams] = useSearchParams();

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [showError, setErrorVisibility] = useState(false);

  const handleButtonClick = () => {
    if (!hasAcceptedTerms) {
      setErrorVisibility(true);
    } else {
      Cookies.set("terms_accepted", "true", { expires: 60, path: "/" });
      setErrorVisibility(false);

      if (searchParams.has('next')) {
        navigate(searchParams.get('next'));
      } else {
        navigate("/entry/map");
      }
    }
  };

  const TermsContent = (
      <Box variant='div' sx={{
        [theme.breakpoints.down('sm')]: {
          padding: '1rem',
          paddingTop: '4rem'
        }
      }}>
        <Typography id="modal-modal-title" variant="h4" component="h2">
          Terms & conditions
        </Typography>
        <div id="modal-modal-terms" style={TermsBox}>
          {Object.entries(Terms).map(([k, v]) => (
            <div key={k}>
              <div style={TermsTitleStyle}>
                {k}. {v.title}
              </div>
              <div style={TermsContentStyle}>{v.content}</div>
              <ul>
                {v.subcontent.map((c, idx) => (
                  <li key={idx}>{c}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <Box variant='div' sx={{
          [theme.breakpoints.down('sm')]: {
            display: 'block',
            textAlign: 'right'
          },
          [theme.breakpoints.up('sm')]: {
            display: "flex",
            justifyContent: "space-between",
          },
        }}>
          <FormControlLabel
            required
            sx={{
              textAlign: 'left'
            }}
            control={
              <Checkbox
                value={hasAcceptedTerms}
                onChange={() => setHasAcceptedTerms(!hasAcceptedTerms)}
              />
            }
            label="I have read and accepted the Terms & Conditions."
          />
          <Button variant="contained" onClick={handleButtonClick} sx={{
            [theme.breakpoints.down('sm')]: {
              marginTop: '1rem'
            }
          }}>
            Continue
          </Button>
        </Box>
        {showError && (
          <div style={WarningStyle}>
            * You must accept the Terms & Conditions in order to continue.
          </div>
        )}
      </Box>
  );

  return (
    <>
      <Header />
      {isSmall ? 
        <>
          {TermsContent}
        </>
      : <Modal
        open={true}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        // hideBackdrop={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={BoxStyle}>
          {TermsContent}
        </Box>
      </Modal>}
    </>
  );
};

export default UsersAgreement;
