// MainRoutes.js

import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'utilities/Loadable';
import MobileLayout from 'layout/MobileLayout';

const GridView = Loadable(lazy(() => import('views/GridView')));
const TableView = Loadable(lazy(() => import('views/TableView')));
const MapView = Loadable(lazy(() => import('views/MapView')));
const NotFound = Loadable(lazy(() => import('views/pages/PageNotFound')));



export const MobileRoutes = {
  path: '/entry/*',
  element: <MobileLayout />

};

export const MainRoutes = {
  path: '/entry',
  element: <MainLayout />,
  children: [
    {
      path: 'map',
      element: <MapView /> 
    },
    {
      path: 'grid',
      element:<GridView /> 
    },
    {
      path: 'table',
      element: <TableView />
    },
    {
      path: '*/',
      element: <NotFound />,
    }
  ],

};
