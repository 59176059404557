import { useSelector } from 'react-redux';
import React from 'react'
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider, Box, CircularProgress, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import ThemeRoutes from 'routes';
import theme from 'themes';
import Loadable from 'utilities/Loadable';
import { JoyrideProvider } from 'utilities/JoyrideProvider';

import NavigationScroll from 'utilities/NavigationScroll';


const App = () => {
  const customization = useSelector((state) => state.customization);
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#F2EEE3', // Optional: Add a semi-transparent background
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Typography variant="body1" sx={{ marginBottom: 2, fontFamily: 'inherit' }}>
            Loading...
          </Typography>
          <CircularProgress style={{ color: '#164A1A' }} />
        </Box>
      </Box>
    );
  }


  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme(customization)}>
        <CssBaseline />
        <NavigationScroll>
          {isLoading && <Loadable />}
          <JoyrideProvider>
            <ThemeRoutes />
          </JoyrideProvider>
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
