import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // Import useNavigate and useLocation
import Cookies from 'js-cookie'; // Import Cookies
import {
  AppBar,
  Drawer,
  Grid,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  IconButton,
  Link,
  Container,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useJoyride } from "utilities/JoyrideProvider";

import LogoButton from '../../ui-component/LogoButton'
import OpenLinkIcon from '../../assets/images/icons/OpenLink.svg';
import ProfileSection from '../ProfileSection';
import MobileProfileSection from 'component/ProfileSection/mobileProfile';
import bioLogo from '../../assets/Logo.svg';

const logoArgs = {
  alt: "biometrio",
  href: "/entry/map",
  src: bioLogo
};


function Navigation({ title = 'biometrio.earth', menuItems, menuItemsMobile, triggerTour }) {
  const theme = useTheme();
  const location = useLocation();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const [tourStartedManually, setTourStartedManually] = useState(false);
  const navigate = useNavigate();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const { setStepReadyState, setJoyrideState, joyrideState} = useJoyride();
  const tour_completed = Cookies.get('tour_completed');


  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const checkPage = (pathname) => {
    if (pathname === '/pages/glossary' || pathname === '/pages/help') {
      return 'auto';
    } else {
      return 1200;
    }
  };

  useEffect(() => {
    checkPage(location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);


  useEffect(() => {
    const termsAccepted = Cookies.get('terms_accepted') === 'true';
    setHasAcceptedTerms(termsAccepted);
  }, []);


  const menuItemsNotEULA = [
    { label: 'About', link:' https://www.biometrio.earth/unser-ansatz' },
    { label: 'Technology' , link:' https://www.biometrio.earth/technologie'},
  ];

  const EULAToolbarStyle = {
    position: 'absolute',
    width: '100%',
    background: '#f2eee3',
    zIndex: 9999999
  }

   useEffect(() => {
    if(joyrideState.currentAction !== 'skip' || joyrideState.currentAction !== ''){
      // Simulate data loading
      setStepReadyState(false);
      setTimeout(() => {
          setStepReadyState(true); // Notify Joyride that the step is ready
      }, 200); // Adjust this delay to simulate your loading time
    }
  }, [setStepReadyState, joyrideState.currentAction]);


 
  const handleStartTour = () => {

    triggerTour();
    setTourStartedManually(true); // Set the flag to true when starting the tour
    
    Cookies.remove('tour_completed');
    navigate('/entry/map');
    setStepReadyState(true);
    setJoyrideState(prevState => ({
      ...prevState,
      manualStartTour: true,
    }));
  };

  useEffect(() => {
    if(tour_completed && tourStartedManually){
      Cookies.remove('tour_completed'); 
    }
  }, [tour_completed, tourStartedManually]);

  
 

  return (

    <Toolbar style={{ 
      display: 'flex',
      justifyContent: 'space-between',
      padding: 0,
      ...(!hasAcceptedTerms ? EULAToolbarStyle : {}),
    }}>
      {/* Mobile Hamburger Menu */}
      {matchDownMd && <>
        <Grid className="my-fourteen-step" item xs={3} sx={{ width: "300px", }}>
          <LogoButton
            href={hasAcceptedTerms ? "/" : ''}
            component={Link}
            {...logoArgs}
          />

        </Grid>
        {
          drawerOpen ?
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              onClick={toggleDrawer}
            >
              <CloseIcon style={{ color: '#164A1A' }} />
            </IconButton>
            :

            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
            >
              <MenuIcon style={{ color: '#164A1A', borderRadius: '10px' }} />
            </IconButton>

        }

        <Drawer
          anchor="top"
          open={drawerOpen}
          onClose={toggleDrawer}
          sx={{
            zIndex: checkPage(location.pathname),
            '& .MuiDrawer-paper': {
              background: "#F9F5EB !important",
              height: "100vh"
            }
          }}
        >
          <AppBar position="static" className="my-fourteen-step" style={{
            display: matchDownMd ? 'none' : 'block',
            background: '#F9F5EB'
          }}>
            <Container maxWidth="lg">
              <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid  className="my-fourteen-step" item xs={3} sx={{ width: "300px" }}>
                  <LogoButton
                    href={hasAcceptedTerms ? "/" : ''}
                    component={Link}
                    {...logoArgs}
                  />
                </Grid>
              </Toolbar>
            </Container>
          </AppBar>
          {
            matchDownMd &&
            <List sx={{ width: '100%', marginTop: '4rem' }}>
              {!hasAcceptedTerms ?
              menuItemsNotEULA.map((item, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={
                    <Link
                      href={item.link}
                      target='_blank'
                      rel="noreferrer"
                      style={{ display: "flex", alignItems: "center", textDecoration: 'none' }}>
                      <Typography variant="h4" style={{ marginRight: "5px", whiteSpace: 'nowrap', textTransform: 'uppercase', fontWeight: 400, color: '#164A1A', letterSpacing: '1px', cursor: 'pointer', fontSize: '0.875rem' }}>
                        {item.label}
                      </Typography>
                      <img src={OpenLinkIcon} alt="open-link-icon" />
                    </Link>}
                    style={{ color: 'black', marginRight: '20px' }}
                  />
                </ListItem>
              )) :
              menuItemsMobile.map((item, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid

                      item.label === 'Help' && process.env.REACT_APP_ORIGIN !== 'https://dashboard.biometrio.earth/' ?
                      (
                        // <Link
                        //   onClick={toggleDrawer}
                        //   component={RouterLink}
                        //   to={item.link}
                        //   rel="noreferrer"
                        //   style={{ display: "flex", alignItems: "center", textDecoration: 'none' }}>
                        //   <Typography variant="h4" style={{ marginRight: "5px", whiteSpace: 'nowrap', textTransform: 'uppercase', fontWeight: 400, color: '#164A1A', letterSpacing: '1px', cursor: 'pointer', fontSize: '0.875rem' }}>
                        //     {item.label}
                        //   </Typography>
                        // </Link>
                        <Typography variant="h4" onClick={handleStartTour} style={{ display: 'none', marginRight: "5px", whiteSpace: 'nowrap', textTransform: 'uppercase', fontWeight: 400, color: '#164A1A', letterSpacing: '1px', cursor: 'pointer', fontSize: '0.875rem' }}>
                        {item.label}
                      </Typography>
                      )
                       
                        :
                        item.label === 'Glossary' ?
                          <Link
                          className="my-fifteen-step"
                          onClick={toggleDrawer}
                            component={RouterLink}
                            to={item.link}
                            rel="noreferrer"
                            style={{ display: "flex", alignItems: "center", textDecoration: 'none' }}>
                            <Typography variant="h4" style={{ marginRight: "5px", whiteSpace: 'nowrap', textTransform: 'uppercase', fontWeight: 400, color: '#164A1A', letterSpacing: '1px', cursor: 'pointer', fontSize: '0.875rem' }}>
                              {item.label}
                            </Typography>
                          </Link>
                          :
                          item.label === 'Map' ?
                            <Link
                              component={RouterLink}
                              to={item.link}
                              rel="noreferrer"
                              style={{ display: "flex", alignItems: "center", textDecoration: 'none' }}>
                              <Typography variant="h4" style={{ marginRight: "5px", whiteSpace: 'nowrap', textTransform: 'uppercase', fontWeight: 400, color: '#164A1A', letterSpacing: '1px', cursor: 'pointer', fontSize: '0.875rem' }}>
                                {item.label}
                              </Typography>
                            </Link>
                            : <Link
                              href={item.link}
                              target='_blank'
                              rel="noreferrer"
                              style={{ display: "flex", alignItems: "center", textDecoration: 'none' }}>
                              <Typography variant="h4" style={{ marginRight: "5px", whiteSpace: 'nowrap', textTransform: 'uppercase', fontWeight: 400, color: '#164A1A', letterSpacing: '1px', cursor: 'pointer', fontSize: '0.875rem' }}>
                                {item.label}
                              </Typography>
                              <img src={OpenLinkIcon} alt="open-link-icon" />
                            </Link>
                    }
                    style={{ color: 'black', marginRight: '20px' }}
                  />
                </ListItem>
              ))}

              <ListItem sx={{ marginTop: '1rem', marginBottom: '1.5rem' }}>
                <Divider width sx={{ color: '#164A1A', border: '0.8px solid #164A1A', width: '95%', margin: 'auto', justifyContent: 'center' }} />
              </ListItem>


              <ListItem paddingTop={3}>
                <MobileProfileSection />
              </ListItem>
            </List>
          }
        </Drawer>

      </>

      }


      {matchUpMd && <>

        <Grid item xs={3} sx={{ width: "380px" }} className="my-fourteen-step">
          <LogoButton
            href="/"
            component={Link}
            {...logoArgs}
            sx={{
              width: "260px"
            }}
          />
        </Grid>

        <List
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 0,
            paddingBottom: '0 !important',
            [theme.breakpoints.up('md')]: {
              gap: 2,
            },

          }}
        >
          {!hasAcceptedTerms ?
            menuItemsNotEULA.map((item, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={
                  <Link
                    href={item.link}
                    target='_blank'
                    rel="noreferrer"
                    style={{ display: "flex", alignItems: "center", textDecoration: 'none' }}>
                    <Typography variant="h4" style={{ marginRight: "5px", whiteSpace: 'nowrap', textTransform: 'uppercase', fontWeight: 400, color: '#164A1A', letterSpacing: '1px', cursor: 'pointer', fontSize: '0.875rem' }}>
                      {item.label}
                    </Typography>
                    <img src={OpenLinkIcon} alt="open-link-icon" />
                  </Link>}
                  style={{ color: 'black', marginRight: '20px' }}
                />
              </ListItem>
            )) :
            menuItems.map((item, index) => (
            <ListItem
              key={index}
              disablePadding  // Remove padding
            >
              <ListItemText
                primary={
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid

                  item.label === 'Help' && process.env.REACT_APP_ORIGIN !== 'https://dashboard.biometrio.earth/' ?
                  (
                    // <Link
                    //   onClick={toggleDrawer}
                    //   component={RouterLink}
                    //   to={item.link}
                    //   rel="noreferrer"
                    //   style={{ display: "flex", alignItems: "center", textDecoration: 'none' }}>
                    //   <Typography variant="h4" style={{ marginRight: "5px", whiteSpace: 'nowrap', textTransform: 'uppercase', fontWeight: 400, color: '#164A1A', letterSpacing: '1px', cursor: 'pointer', fontSize: '0.875rem' }}>
                    //     {item.label}
                    //   </Typography>
                    // </Link>
                    <Typography variant="h4" onClick={handleStartTour} style={{ marginRight: "5px", whiteSpace: 'nowrap', textTransform: 'uppercase', fontWeight: 400, color: '#164A1A', letterSpacing: '1px', cursor: 'pointer', fontSize: '0.875rem' }}>
                    {item.label}
                  </Typography>)
                    :
                    item.label === 'Glossary' ?
                      <Link
                        className="my-fifteen-step"
                        component={RouterLink}
                        to={item.link}
                        rel="noreferrer"
                        style={{ display: "flex", alignItems: "center", textDecoration: 'none' }}>
                        <Typography variant="h4" style={{ marginRight: "5px", whiteSpace: 'nowrap', textTransform: 'uppercase', fontWeight: 400, color: '#164A1A', letterSpacing: '1px', cursor: 'pointer', fontSize: '0.875rem' }}>
                          {item.label}
                        </Typography>
                      </Link>
                      : <Link
                        href={item.link}
                        target='_blank'
                        rel="noreferrer"
                        style={{ display: "flex", alignItems: "center", textDecoration: 'none' }}>
                        <Typography variant="h4" style={{ marginRight: "5px", whiteSpace: 'nowrap', textTransform: 'uppercase', fontWeight: 400, color: '#164A1A', letterSpacing: '1px', cursor: 'pointer', fontSize: '0.875rem' }}>
                          {item.label}
                        </Typography>
                        <img src={OpenLinkIcon} alt="open-link-icon" />
                      </Link>
                }
                style={{ color: 'black', marginRight: '20px' }}
              />
            </ListItem>

          ))}

          <ListItem style={{ marginLeft: '20px' }}>
            <ProfileSection />
          </ListItem>
        </List></>}
    </Toolbar>


  );
}

export default Navigation;
