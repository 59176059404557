import React, { lazy } from "react";
import { useLocation } from "react-router-dom";
import { AppBar, Box, CssBaseline, Grid, useMediaQuery, Stack } from "@mui/material";
import Header from "../../component/Header";
import Loadable from "utilities/Loadable";
import { useTheme } from "@mui/material/styles";
import { alpha } from '@mui/material/styles';

import Footer from "../../component/Footer";
import widget1 from "../../assets/images/icons/Wolke_3@3x 5.svg";
import SideMenu from "component/Admin/SideMenu";


const MainGrid = Loadable(lazy(() => import("views/AdminView/MainGrid")));
const Users = Loadable(lazy(() => import("views/AdminView/Users")));
const Projects = Loadable(lazy(() => import("views/AdminView/Projects")));
const UserDetails = Loadable(lazy(() => import("views/AdminView/UserDetails")));


const AdminLayout = ({ children }) => {
    const location = useLocation();
    const theme = useTheme();


    const renderPageContent = () => {
        switch (location.pathname) {
            case "/admin/overview":
                return <MainGrid />;
            case "/admin/users":
                return <Users />;
            case "/admin/projects":
                return <Projects />;
            default:
                // Check if the path starts with '/admin/users/' and has a dynamic userId
                if (location.pathname.startsWith("/admin/users/")) {
                    return <UserDetails />;
                }
                return null;
        }
    };


    return (
        <div>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    color="inherit"
                    elevation={0}
                    sx={{
                        bgcolor: theme.palette.background.default,
                        borderBottom: '1.5px solid #164A1A',
                        background: '#F9F5EB',
                        padding: 0,
                        top: 0,
                        height: '56px',
                        width: "100%",

                    }}
                >
                    <Header />
                </AppBar>

            </Box>
            <main>
                <Grid

                    sx={{
                        overflow: "scroll",
                        overflowX: "hidden",
                        height: "100vh",
                        [theme.breakpoints.up("xl")]: {
                            overflow: "scroll",
                            overflowX: "hidden",
                            height: "100vh",
                        },
                        [theme.breakpoints.down("md")]: {
                            overflowY: "scroll",
                            height: "100vh",
                            width: "auto"
                        },
                        [theme.breakpoints.down("xs")]: {
                            overflowY: "scroll",
                            height: "100vh",
                            width: "auto"
                        },
                    }}
                >
                    <Grid
                        sx={{
                            [theme.breakpoints.up("xl")]: {
                                display: "inline-block",
                            },
                            [theme.breakpoints.down("md")]: {
                                display: "none",
                            },
                            [theme.breakpoints.down("xs")]: {
                                display: "none",
                            },
                        }}
                    >
                        <img
                            src={widget1}
                            alt="Wolke_3@3x 5"
                            style={{ position: "absolute", top: "0", zIndex: "-11" }}
                        />
                    </Grid>
                    <CssBaseline enableColorScheme />
                    <Box sx={{ display: 'flex' }}>
                        <SideMenu />
                        {/* <AppNavbar /> */}
                        {/* Main content */}
                        <Box
                            sx={(theme) => ({
                                flexGrow: 1,
                                overflow: 'auto',
                            })}
                        >
                            <Stack
                                spacing={2}
                                sx={{
                                    alignItems: 'center',
                                    mx: 3,
                                    pb: 5,
                                    mt: 10,
                                    // position: 'absolute',
                                    // top: '80px'

                                }}
                            >
                                {/* <Header /> */}
                                {renderPageContent()}
                            </Stack>
                        </Box>
                    </Box>
                </Grid>
            </main>
            {children}
        </div>
    );
};
export default AdminLayout;
