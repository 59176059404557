import { useState, useRef, useEffect } from 'react';
import Cookies from 'js-cookie';

// material-ui
import Avatar from '@mui/material/Avatar';
import { getAuthUser } from 'api';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';
import { IconLogout } from '@tabler/icons';

import { useAuth0 } from '@auth0/auth0-react';


const MobileProfileSection = () => {
  const theme = useTheme();

  const { logout } = useAuth0();
  const { user, isLoading } = useAuth0();
  const [useremail, setUserEmail] = useState('');
  const [username, setUserName] = useState('');
  const token = localStorage.getItem('access_token');
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = '') => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== '') {
      console.log("sample")
    }
  };
 

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    Cookies.remove('access_token');
   /*  logout({
      returnTo: process.env.REACT_APP_ORIGIN,
    }); */
    logout({ logoutParams: { returnTo: window.location.origin } })


  };

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: '#164A1A',
        color: '#B7F97E',
        fontWeight: 300,
      },
      children: name && `${name.split(' ')[0][0]} ${name.split(' ')[1][0]}`,
    };
  }

  const fetchDataByView = async () => {
    try {
      
      try {
        const response = await getAuthUser(token);
      
        const { data } = response;
        setUserEmail(data.user.email);

        fetchData(data.auth.aud[1]);
       
      } catch (error) {
        console.error('Error:', error);
        if (error.response && error.response.status === 401) {
          handleLogout();
        }
      
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  async function fetchData(url) {
    try {
      const response = await fetch(url,{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json' // Assuming JSON response
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      setUserEmail(data.email);
      setUserName(data.name)
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }

  useEffect(() => {
    fetchDataByView();
  }, []);



  if (isLoading) {
    return <div>Loading ...</div>;
  }
  return (
    <>
      <Box>  
        <Stack mb={3}>
          <Stack direction="row" spacing={4} alignItems="center">
              <Avatar {...stringAvatar(user?.name || username)} sx={{ width: 56, height: 56, color: '#D2FFAB', fontSize: '18px', fontWeight: 400 }}/>
              <div>
              <Typography variant="h4"  sx={{ marginBottom: '2px', fontWeight: 400, fontSize: "18px", color: "#2B2B2B" }}>
                {user?.name || username}
              </Typography>
              <Typography variant="p" sx={{ fontSize: '14px', color: '#626364', fontWeight: 400}}>{user?.email || useremail}</Typography>
              </div>
          </Stack>
        </Stack>
        <Stack>

        <List
          component="nav"
          sx={{
            width: '100%',
            //background: '#F2EEE3',
            borderRadius: '5px',
            padding: '0 !important',
            [theme.breakpoints.down('md')]: {
              minWidth: '100%'
            },
            '& .MuiListItemButton-root': {
              '&:hover': {
                // Your hover styles go here
                //backgroundColor: '#F2EEE3',  // Replace with the desired hover color
              },
            }
          }}
        >

          <ListItemButton
            sx={{ borderRadius: `5px`, paddingRight: '0 !important', paddingLeft: '0 !important', display: 'none' }}
            p={0}
            selected={selectedIndex === 0}
            onClick={(event) => handleListItemClick(event, 0, '#')}
          >
            <ListItemText primary={<Typography variant="body2" sx={{ textTransform: 'uppercase', fontWeight: 400, fontSize: '1rem', color: '#164A1A' }}>Account</Typography>} />
          </ListItemButton>
          
          
          <ListItemButton
            sx={{ borderRadius: `5px`, paddingRight: '0 !important', paddingLeft: '0 !important', display: 'none' }}
            p={0}
            selected={selectedIndex === 0}
            onClick={(event) => handleListItemClick(event, 0, '#')}
          >
            <ListItemText primary={<Typography variant="body2" sx={{ textTransform: 'uppercase',fontWeight: 400,  fontSize: '1rem', color: '#164A1A' }}>Settings</Typography>} />
          </ListItemButton>

          <ListItemButton
            sx={{ borderRadius: `5px`, paddingBottom: '0 !important', paddingRight: '0 !important', paddingLeft: '0 !important' }}
            p={0}
            style={{background: 'transparent !important', }}
            selected={selectedIndex === 4}
            onClick={handleLogout}
          >
            <ListItemIcon>
              <IconLogout stroke={1.5} size="1.5rem" fontWeight={500} color="#164A1A" />
            </ListItemIcon>
            <ListItemText primary={<Typography variant="body2" sx={{ textTransform: 'uppercase', fontWeight: 400, fontSize: '14px', color: '#164A1A' }}>Logout</Typography>} />
          </ListItemButton>
        </List>
        </Stack>
      </Box>
    </>
  );
};

export default MobileProfileSection;
